import { ThunkAction, ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/resetCredentials.constants';
import AlertType from '../../abstractions/AlertType';
import { Error } from '../../abstractions/Error';
import baseUrl from '../../Config';

export interface SendResetRequest {
  type: constants.SendResetRequest;
}

function sendResetRequest(): SendResetRequest {
  return {
    type: constants.SEND_RESET_REQUEST,
  };
}
export interface SendResetSuccess {
  type: constants.SendResetSuccess;
}

function sendResetSuccess(): SendResetSuccess {
  return {
    type: constants.SEND_RESET_SUCCESS,
  };
}

export interface ResetPasswordRequest {
  type: constants.PasswordResetRequest;
}

function resetPasswordRequest(): ResetPasswordRequest {
  return {
    type: constants.PASSWORD_RESET_REQUEST,
  };
}

export interface ResetPasswordSuccess {
  type: constants.PasswordResetSuccess;
}

function resetPasswordSuccess(): ResetPasswordSuccess {
  return {
    type: constants.PASSWORD_RESET_SUCCESS,
  };
}

export interface ResetError {
  type: constants.ResetError;
  error: Error;
}

function requestResetError(error: Error): ResetError {
  return {
    type: constants.RESET_ERROR,
    error: { ...error, alertType: AlertType.Error },
  };
}

export interface ClearState {
  type: constants.ClearState;
}

function clearState(): ClearState {
  return {
    type: constants.CLEAR_STATE,
  };
}

type SendResetReturnTypes = SendResetRequest | SendResetSuccess | ResetError;
export function sendReqestReset(
  email: string,
): ThunkAction<void, unknown, unknown, SendResetReturnTypes> {
  return async (dispatch: Dispatch<void, unknown, SendResetReturnTypes>) => {
    dispatch(sendResetRequest());
    try {
      window.localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      await axios.post(`${baseUrl}/auth/forgot-password`, {
        email,
      });
      dispatch(sendResetSuccess());
    } catch (error) {
      dispatch(requestResetError(error.response));
    }
  };
}

type SendPasswordResetReturnTypes = ResetPasswordRequest | ResetPasswordSuccess | ResetError;
export function sendPasswordReset(
  code: string,
  password: string,
  repeatPassword: string,
): ThunkAction<void, unknown, unknown, SendPasswordResetReturnTypes> {
  return async (dispatch: Dispatch<void, unknown, SendPasswordResetReturnTypes>) => {
    dispatch(resetPasswordRequest());
    try {
      window.localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      await axios.post(`${baseUrl}/auth/reset-password`, {
        code,
        password,
        passwordConfirmation: repeatPassword,
      });
      dispatch(resetPasswordSuccess());
    } catch (error) {
      dispatch(requestResetError(error.response));
    }
  };
}

export function resetState(): ThunkAction<void, unknown, unknown, ClearState> {
  return async (dispatch: Dispatch<void, unknown, ClearState>) => {
    dispatch(clearState());
  };
}
