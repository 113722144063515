import { Button, Layout } from 'antd';
import React from 'react';
import colors from '../../../Theme/Colors';
import { formatToCurrency } from '../helpers/formatToCurrency';
import Text from '../../../common/Text/Text';
import { Flex } from 'rebass';

const { Footer } = Layout;

interface Props {
  cartAmount: number;
  onCartClick: () => void;
}

const CartFooter = ({ cartAmount, onCartClick }: Props) => {
  return (
    <Footer
      style={{ position: 'sticky', bottom: '0', backgroundColor: colors.white, padding: '10px' }}
    >
      <Button
        onClick={onCartClick}
        type="primary"
        htmlType="submit"
        size="large"
        style={{
          marginBottom: '40px',
          backgroundColor: colors.mintGreen,
          borderColor: colors.mintGreen,
          borderRadius: '10px',
          color: colors.black14,
          fontWeight: 'bold',
          margin: '10px',
          boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px`,
        }}
      >
        <Flex>
          <Text weight="bold">
            <>Užsakyti:&#160;</>
          </Text>
          {formatToCurrency(cartAmount)}
        </Flex>
      </Button>
    </Footer>
  );
};

export default CartFooter;
