import {
  Authenticate,
  Unauthenticate,
  ClearAuth,
  AuthFailure,
  GetUser,
} from '../actions/auth.actions';
import {
  AUTHENTICATE,
  UNAUTHENTICATE,
  CLEAR_ERRORS,
  AUTH_FAILURE,
  ME,
} from '../constants/auth.constants';
import { User } from '../../abstractions/User';
import alertError from '../../util/alertError';

export interface Auth {
  authChecked: boolean;
  isAuthenticated: boolean;
  user?: User;
  error?: Error;
  loading?: boolean;
  child?: User;
}

export default function authReducer(
  state: Auth = {
    authChecked: false,
    loading: false,
    isAuthenticated: false,
  },
  action: Authenticate | Unauthenticate | AuthFailure | ClearAuth | GetUser,
): Auth {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        authChecked: true,
        user: action.user,
        error: undefined,
      };
    case UNAUTHENTICATE:
      return { authChecked: true, error: undefined, user: undefined, isAuthenticated: false };
    case AUTH_FAILURE: {
      alertError({
        message: 'Klaida!',
        description: 'Neteisingai įvestas vartotojo vardas arba slaptažodis',
        duration: 3,
      });

      return {
        ...state,
        authChecked: true,
        user: undefined,
        error: action.error,
      };
    }
    case CLEAR_ERRORS:
      return { ...state, error: undefined, authChecked: true };
    case ME:
      return { ...state, error: undefined, child: action?.child, authChecked: true };
    default:
      return { ...state };
  }
}
