import { Divider } from 'antd';
import React from 'react';
import { Box } from 'rebass';

import { Meal } from '../../../abstractions/Meal';
import { Order } from '../../../abstractions/Order';
import { shouldDisplayOrderItem } from '../../BusinessLunchPage/helpers/shouldDisplayOrderItem';
import { isCartItemEmptyOrChanged } from '../helpers/isCartItemEmptyOrChanged';
import Cart from '../../../abstractions/Cart';
import DayNewOrderRow from './DayOrderRow/DayNewOrderRow/DayNewOrderRow';
import DayOldOrderRow from './DayOrderRow/DayOldOrderRow/DayOldOrderRow';
import DayOrderRow from './DayOrderRow/DayOrderRow';
import SubTotalRow from './SubTotalRow/SubTotalRow';

interface Props {
  cart: Cart | null;
}

const DayOrderRows = ({ cart }: Props) => {
  const dayOrderRow = (item: Order) => (
    <DayOrderRow item={item}>
      {Object.values(Meal)?.map((key) => {
        if (shouldDisplayOrderItem(item, key as Meal)) {
          return (
            <>
              <DayOldOrderRow item={item} meal={key} />
              <DayNewOrderRow item={item} meal={key} canRemoveMeal />
            </>
          );
        }
      })}
    </DayOrderRow>
  );

  return (
    <>
      {cart?.items
        ?.sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)))
        ?.map((item) => {
          if (isCartItemEmptyOrChanged(item)) {
            return null;
          }
          return (
            <Box>
              <>
                {dayOrderRow(item)}
                <SubTotalRow item={item} />
                <Divider />
              </>
            </Box>
          );
        })}
    </>
  );
};

export default DayOrderRows;
