import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/settings.constants';
import { MealCategory } from '../../abstractions/UserSettings';
import Config from '../../Config';

const baseUrl = Config;

export interface Settings {
  language: string;
  breakfast: MealCategory;
  lunch: MealCategory;
  afternoon_meal: MealCategory;
  snack: MealCategory;
}

export interface ChangeLanguageSuccess {
  type: constants.ChangeLanguageSuccess;
  settings: Settings;
}

function changeLanguageSuccess(settings: Settings): ChangeLanguageSuccess {
  return {
    type: constants.CHANGE_LANGUAGE_SUCCESS,
    settings,
  };
}

export interface ChangeSettingsSuccess {
  type: constants.ChangeSettingsSuccess;
  settings: Settings;
}

function changeSettingsSuccess(settings: Settings): ChangeSettingsSuccess {
  return {
    type: constants.CHANGE_SETTINGS_SUCCESS,
    settings,
  };
}

export interface SettingsError {
  type: constants.SettingsError;
  error: Error;
}

function settingsError(error: Error): SettingsError {
  return {
    type: constants.SETTINGS_ERROR,
    error,
  };
}

export interface SettingsDefault {
  type: constants.SettingsDefault;
}

function settingsDefault(): SettingsDefault {
  return {
    type: constants.SETTINGS_DEFAULT,
  };
}

export function setSettingsToDefault() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(settingsDefault());
  };
}

export function changeLanguage(settings: Settings) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.put(`${baseUrl}/user-settings`, settings);

      dispatch(changeLanguageSuccess(response.data));
    } catch (error) {
      dispatch(settingsError(error.response));
    }
  };
}

export function changeUserSettings(settings: Settings) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.put(`${baseUrl}/user-settings`, settings);

      dispatch(changeSettingsSuccess(response.data));
    } catch (error) {
      dispatch(settingsError(error.response));
    }
  };
}
