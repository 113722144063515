import { Meal } from '../../abstractions/Meal';
import { Order } from '../../abstractions/Order';
import { MealCategory } from '../../abstractions/UserSettings';

export const normalizeOrders = (orders: Order[]) => {
  return orders.map((order) => {
    const normalizedOrder = { ...order };
    for (const meal of Object.values(Meal)) {
      const mealKey = meal;
      const amountKey = `${meal}_amount`;
      if (normalizedOrder[mealKey] === null) {
        normalizedOrder[mealKey] = MealCategory.None;
      }
      // @ts-ignore
      if (normalizedOrder[amountKey] === null) {
        // @ts-ignore
        normalizedOrder[amountKey] = 1;
      }
    }
    return normalizedOrder;
  });
};
