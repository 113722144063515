import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import {
  BusinessDeal,
  BusinessLunchItem,
  BusinessLunchMeal,
  categoryOrder,
} from './BusinessLunch.types';
import SideMenu from './components/SideMenu';
import MainMenu from './components/MainMenu/MainMenu';
import CartFooter from './components/CartFooter';
import axios from 'axios';
import Config from '../../Config';
import OptionsModal from './components/OptionsModal';
import CheckoutModal from './components/CheckoutModal/CheckoutModal';
import { getCartAmount } from './helpers/getCartAmount';
import moment from 'moment';
import { Box, Flex, Image } from 'rebass';
import ErrorIcon from '../../images/svg/red-error-icon.svg';
import './styles.css';

const baseUrl = Config;

const BusinessLunchPage = () => {
  // @ts-ignore
  const params = new URLSearchParams(window.location.search);
  const [cart, setCart] = React.useState<BusinessLunchItem[]>([]);
  const [isCheckoutVisible, setIsCheckoutVisible] = React.useState(false);
  const [menu, setMenu] = useState<BusinessLunchMeal>();
  const [businessDeal, setBusinessDeal] = useState<BusinessDeal>();
  const [isBusinessOrderFetched, setIsBusinessOrderFetched] = useState<Boolean>();
  const [isError, setIsError] = useState(false);
  const [selectedMainCategoryItem, setSelectedMainCategoryItem] =
    React.useState<BusinessLunchItem | null>(null);

  useEffect(() => {
    const fetchMenu = async () => {
      const response = await axios.get(
        `${baseUrl}/business-menu-view?token=${params.get('token')}`,
      );
      if (moment(response.data?.order_until) < moment()) {
        setIsError(true);
        return;
      }
      setMenu(response.data);
    };
    if (!menu) {
      fetchMenu().catch((error) => {
        setIsError(true);
        console.error(error);
      });
    }
  }, [params, menu]);

  useEffect(() => {
    const fetchBusinessDeals = async () => {
      const response = await axios.get(`${baseUrl}/business-deals`);
      setBusinessDeal(response.data.at(-1));
    };
    if (!businessDeal) {
      fetchBusinessDeals().catch((error) => {
        setIsError(true);
        console.error(error);
      });
    }
  }, [businessDeal]);

  useEffect(() => {
    const fetchBusinessOrder = async () => {
      const response = await axios.get(`${baseUrl}/business-orders?token=${params.get('token')}`);
      const order: BusinessLunchItem[] = response.data?.items?.map(
        (order: { id: number; options: { id: number }[] }) => {
          const item = menu?.items.find((item) => item.id === order.id);
          return {
            ...item,
            options: order?.options?.map((option) => ({
              ...item?.options?.find((opt) => opt.id === option.id),
            })),
          };
        },
      );
      setCart(order ?? []);
      setIsBusinessOrderFetched(true);
    };
    if (!isBusinessOrderFetched && cart?.length === 0 && menu) {
      fetchBusinessOrder().catch((error) => {
        setIsError(true);
        console.error(error);
      });
    }
  }, [params, cart, isBusinessOrderFetched, menu]);

  const getSortedCategories = () => {
    const categories = [...new Map(menu?.items?.map((item) => [item['category'], item])).values()];
    const sortedCategories: BusinessLunchItem[] = [];
    categoryOrder?.forEach((order) => {
      const foundCategory = categories.find((category) => category?.category === order);
      if (foundCategory) {
        sortedCategories.push(foundCategory);
      }
    });
    return sortedCategories as BusinessLunchItem[];
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        {isError ? (
          <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Image src={ErrorIcon} />
              <h1>Ši užsakymo pateikimo nuoroda nebegalioja.</h1>
              <h1>
                Naują užsakymo pateikimo nuorodą rasite savo el. pašto dėžutėje. Atnaujinta nuoroda
                periodiškai siunčiame kiekvieną dieną 18:00.
              </h1>
            </Box>
          </Flex>
        ) : (
          <>
            <Layout style={{ backgroundColor: 'rgb(127 211 184 / 12%)' }}>
              <Box display={['none', 'none', 'block']}>
                <SideMenu
                  categories={getSortedCategories()}
                  date={moment(menu?.date).format('YYYY-MM-DD')}
                />
              </Box>
              {menu && (
                <MainMenu
                  categories={getSortedCategories()}
                  menuItems={menu.items}
                  cart={cart}
                  onItemClick={(item) => {
                    setSelectedMainCategoryItem(item);
                  }}
                />
              )}
            </Layout>
            <CartFooter
              cartAmount={getCartAmount(cart, businessDeal)}
              onCartClick={() => setIsCheckoutVisible(true)}
            />
          </>
        )}
      </Layout>
      {menu && (
        <OptionsModal
          item={menu.items.find((item) => item.id === selectedMainCategoryItem?.id)}
          soups={menu.items.filter((item) => item?.category === 'soup')}
          cart={cart}
          businessDeal={businessDeal}
          addItem={(item) => {
            setCart((prev) => [...prev, item]);
          }}
          clearItem={(lunchItem) => {
            if (lunchItem) {
              const cartIndex = cart.findIndex((cartItem) => cartItem?.id === lunchItem?.id);
              const filteredCart = cart.filter((entry, index) => index !== cartIndex);
              setCart(filteredCart);
            }
            setSelectedMainCategoryItem(null);
          }}
        />
      )}
      {isCheckoutVisible && menu && menu.id && (
        <CheckoutModal
          isVisible={isCheckoutVisible}
          cart={cart}
          businessDeal={businessDeal}
          cartAmount={getCartAmount(cart, businessDeal)}
          menuId={menu.id}
          onRemoveItem={(item) => {
            if (item) {
              const cartIndex = cart.findIndex((cartItem) => cartItem?.id === item?.id);
              const filteredCart = cart.filter((entry, index) => index !== cartIndex);
              setCart(filteredCart);
            }
            setSelectedMainCategoryItem(null);
          }}
          onCancel={() => setIsCheckoutVisible(false)}
        />
      )}
    </>
  );
};

export default BusinessLunchPage;
