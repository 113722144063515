import React from 'react';
import { Form, Input, Button } from 'antd';
import Text from '../../../common/Text/Text';
import { Store } from 'antd/lib/form/interface';
import { useDispatch, useSelector } from 'react-redux';
import { resetState, sendReqestReset } from '../../../redux/actions/resetCredentials.actions';
import { NavLink, useHistory } from 'react-router-dom';
import { AppState } from '../../../redux/reducers/index.reducer';
import CardWithLogo from '../../../common/CardWithLogo/CardWithLogo';
import colors from '../../../Theme/Colors';
import { validateEmail } from '../../../util/inputValidation';

const ForgotPasswordPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useSelector((state: AppState) => {
    if (state.resetCredentialsReducer.requestSent) {
      history.push('/recovery-sent');
      dispatch(resetState());
    }
    return state.resetCredentialsReducer.requestSent;
  });

  const onSubmitForgotPassword = (values: Store) => {
    dispatch(sendReqestReset(values.email));
  };

  return (
    <CardWithLogo title="Pakeisti slaptažodį">
      <Form name="basic" validateMessages={validateEmail} onFinish={onSubmitForgotPassword}>
        <Text size="16px" width="300px" margin="0 0 20px 0">
          Įveskite savo el. pašto adresą su kuriuo esate priregistruoti prie sistemos
        </Text>
        <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
          <Input placeholder="El. pašto adresas" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            marginBottom: '40px',
            backgroundColor: colors.mintGreen,
            borderRadius: '5px',
            color: colors.black14,
          }}
        >
          Tęsti
        </Button>
        <NavLink to="/login">
          <Text size="16px" color={colors.mintGreen}>
            Grįžti į prisijungimo langą
          </Text>
        </NavLink>
      </Form>
    </CardWithLogo>
  );
};

export default ForgotPasswordPage;
