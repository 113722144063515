import React from 'react';
import { Form } from 'antd';
import Text from '../../../common/Text/Text';
import { NavLink } from 'react-router-dom';
import CardWithLogo from '../../../common/CardWithLogo/CardWithLogo';

const RecoveryEmailSentPage = () => {
  return (
    <CardWithLogo title="Pakeisti slaptažodį">
      <Form name="basic" initialValues={{ remember: true }}>
        <Text size="14px" width="300px" margin="0 0 40px 0">
          Jeigu egzistuoja vartotojas, kuris yra susietas su šiuo el. pašto adresu, gausite el.
          laišką su instrukciją kaip pakeisti slaptažodį.
        </Text>
        <NavLink to="/login">
          <Text size="14px">Grįžti į prisijungimo langą</Text>
        </NavLink>
      </Form>
    </CardWithLogo>
  );
};

export default RecoveryEmailSentPage;
