import { QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Divider } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Text from '../../../common/Text/Text';
import { Box } from 'rebass';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import { MealCategory } from '../../../abstractions/UserSettings';
import { Meal } from '../../../abstractions/Meal';
import { shouldDisplayOrderItem } from '../../BusinessLunchPage/helpers/shouldDisplayOrderItem';
import { formatPriceToElement } from '../helpers/formatPrice';
import { getGrandTotal } from '../../../helpers/cart/getGrandTotal';
import { getSubTotal } from '../../../helpers/cart/getSubTotal';

const PricesInformation = () => {
  const intl = useIntl();
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const credits = useSelector((state: AppState) => state.authReducer?.child?.credits);

  const getDiscountPrice = (): number => {
    let discountedPrice = 0;
    cart?.items?.forEach((item) => {
      if (
        shouldDisplayOrderItem(item, Meal.Lunch) &&
        item.lunch !== MealCategory.None &&
        item?.lunch_pricing?.gov_discounted_price &&
        item?.lunch_pricing?.price
      ) {
        const { price, gov_discounted_price, gov_discount_applies } = item.lunch_pricing;
        if (gov_discount_applies)
          discountedPrice += (price - gov_discounted_price) * (item?.lunch_amount || 1);
      }
    });
    return discountedPrice * -1;
  };

  const priceInformationRow = (
    labelId: string,
    price: number,
    fontWeight?: string,
    tooltipText?: string,
  ) => (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={1} md={3} />
      <Col className="gutter-row" xs={10} md={8}>
        <Text size="18px" weight={fontWeight}>
          <FormattedMessage id={`checkout.${labelId}`} />
        </Text>
      </Col>
      <Col xs={4} md={2} />
      <Col className="gutter-row" xs={6} md={4}>
        <Text size="18px" weight={fontWeight}>
          {formatPriceToElement(price)}
        </Text>
      </Col>
      {tooltipText && (
        <Col span={3} style={{ alignSelf: 'center' }}>
          <Tooltip title={tooltipText} placement="left">
            <Box
              margin="0 0 0 auto"
              sx={{
                cursor: 'pointer',
                span: {
                  display: 'flex',
                  alignItems: 'center',
                  svg: { width: '20px', height: '20px' },
                },
              }}
            >
              <QuestionCircleOutlined
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </Box>
          </Tooltip>
        </Col>
      )}
    </Row>
  );

  return (
    <>
      {getDiscountPrice() !== 0 ? (
        priceInformationRow(
          'discount',
          getDiscountPrice(),
          'auto',
          intl.formatMessage({ id: 'checkout.govermentCompensation' }),
        )
      ) : (
        <></>
      )}
      {priceInformationRow('partialTotal', getSubTotal(cart), '800')}
      {priceInformationRow('credits', credits || 0)}
      {priceInformationRow('operationFee', cart?.fees?.transaction_fee || 0)}
      <Divider />
      {priceInformationRow('grandTotal', getGrandTotal(cart, credits), '800')}
    </>
  );
};

export default PricesInformation;
