const resolveGroupPastelColor = (id: number) => {
  const colorId = id % 10;

  switch (colorId) {
    case 0:
      return 'rgb(222, 238, 238)';
    case 1:
      return 'rgb(211, 250, 248)';
    case 2:
      return 'rgb(242, 214, 192)';
    case 3:
      return 'rgb(211, 250, 204)';
    case 4:
      return 'rgb(216, 211, 238)';
    case 5:
      return 'rgb(246, 200, 212)';
    case 6:
      return 'rgb(244, 240, 236)';
    case 7:
      return 'rgb(227, 238, 223)';
    case 8:
      return 'rgb(200, 204, 248)';
    case 9:
      return 'rgb(242, 242, 206)';
  }
};

export default resolveGroupPastelColor;
