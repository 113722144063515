import React from 'react';
import { Box, Image } from 'rebass';
import colors from '../../../Theme/Colors';
import { FormattedMessage } from 'react-intl';
import Text from '../../../common/Text/Text';

import fox from '../../../images/svg/fox.svg';
import tree from '../../../images/svg/tree.svg';
import conversationBubbleStart from '../../../images/svg/conversationBubbleStart.svg';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';

const OrderRules = () => {
  const group = useSelector((state: AppState) => state.authReducer.child)?.group;
  const isBreakfast = group?.is_breakfast_enabled === null || group?.is_breakfast_enabled;
  const isSnack = group?.is_snack_enabled === null || group?.is_snack_enabled;
  const isLunch = group?.is_lunch_enabled === null || group?.is_lunch_enabled;
  const isAfternoonMeal =
    group?.is_afternoon_meal_enabled === null || group?.is_afternoon_meal_enabled;
  const isEarlyOrder = isBreakfast || isSnack;
  const isLaterOrder = isLunch || isAfternoonMeal;

  return (
    <Box
      height="130px"
      margin="25px 0"
      backgroundColor={colors.lightGrey}
      sx={{ borderRadius: '24px' }}
      display={['none', 'none', 'flex']} // Weird solution but Rebass <Flex/> overwrites display: none
    >
      <Image
        src={fox}
        height="150px"
        width="157px"
        sx={{ position: 'relative', bottom: '15px', left: '10px' }}
      />
      <Image src={conversationBubbleStart} width="41px" marginLeft={'20px'} />
      <Box
        backgroundColor={colors.white}
        width="600px"
        alignSelf={'center'}
        padding={'10px 30px'}
        sx={{ borderRadius: '10px' }}
      >
        <Text size="15px">
          <>
            <FormattedMessage id="order.description.start" />
            {isEarlyOrder && (
              <>
                {isBreakfast && <FormattedMessage id="order.description.breakfast" />}
                {isBreakfast && isSnack && ', '}
                {isSnack && <FormattedMessage id="order.description.snack" />}
                <FormattedMessage id="order.description.endEarlier" />
                <FormattedMessage id="order.description.middle" />
              </>
            )}
            {isLaterOrder && (
              <>
                {isLunch && <FormattedMessage id="order.description.lunch" />}
                {isLunch && isAfternoonMeal && ', '}
                {isAfternoonMeal && <FormattedMessage id="order.description.afternoon_meal" />}
                <FormattedMessage id="order.description.endLater" />
              </>
            )}
          </>
        </Text>
      </Box>
      <Image
        src={tree}
        height="139px"
        width="89px"
        margin="0 35px 0 auto"
        sx={{ position: 'relative', bottom: '15px' }}
      />
    </Box>
  );
};

export default OrderRules;
