import {
  FETCH_PRICING_SUCCESS,
  PRICING_ERROR,
  CLEAR_PRICING_SUCCESS,
} from '../constants/pricing.constants';
import { ClearPricingSuccess, FetchPricingSuccess, PricingError } from '../actions/pricing.actions';
import alertError from '../../util/alertError';
import { Pricing } from '../../abstractions/Pricing';
import { appIntl } from '../../helpers/Intl/IntlGlobalProvider';
export interface PricingReducer {
  pricing: Pricing | null;
  alertMessage: string | null;
}

export default function pricingReducer(
  state: PricingReducer = {
    pricing: null,
    alertMessage: null,
  },
  action: FetchPricingSuccess | ClearPricingSuccess | PricingError,
): PricingReducer {
  switch (action.type) {
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        pricing: action.pricing,
      };
    case CLEAR_PRICING_SUCCESS: {
      return { ...state, pricing: null };
    }
    case PRICING_ERROR: {
      alertError({
        message: appIntl().formatMessage({ id: 'error.title' }),
        description: appIntl().formatMessage({ id: 'cart.pricingError' }),
        duration: 4,
      });

      return { ...state };
    }
    default:
      return { ...state };
  }
}
