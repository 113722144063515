export enum Meal {
  Breakfast = 'breakfast',
  Snack = 'snack',
  Lunch = 'lunch',
  Afternoon_meal = 'afternoon_meal',
}

export enum ShortMeal {
  Breakfast = 'breakfast',
  Lunch = 'lunch',
  Afternoon_meal = 'afternoon_meal',
}