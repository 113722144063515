import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import { Flex } from 'rebass';
import Text from '../../../../common/Text/Text';
import colors from '../../../../Theme/Colors';
import moment, { Moment } from 'moment';
import momentBusinessDay from 'moment-business-days';
import { Button } from 'antd';

interface Props {
  selectedDate: moment.Moment;
  onClickPreviousDay: (date: Moment) => void;
  onClickNextDay: (date: Moment) => void;
}

const DateSelector = (props: Props) => {
  const { onClickPreviousDay, onClickNextDay, selectedDate } = props;

  return (
    <Flex
      width={['70%', '70%', '50%']}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        span: { alignSelf: 'center', margin: '0 auto !important' },
      }}
      margin="20px auto"
    >
      <Button
        onClick={() =>
          onClickPreviousDay(momentBusinessDay(selectedDate).businessSubtract(1, 'day'))
        }
        shape="round"
        icon={<LeftOutlined />}
      >
        Ankstesnė
      </Button>
      <Flex
        // minWidth={['auto', 'auto', '50%']}
        margin="auto"
        backgroundColor={colors.lightGrey}
        padding="14px 120px"
        sx={{ borderRadius: '16px' }}
      >
        <Text alginSelft="center">{moment(selectedDate).format('LL')}</Text>
      </Flex>
      <Button
        onClick={() => onClickNextDay(momentBusinessDay(selectedDate).businessAdd(1, 'day'))}
        shape="round"
      >
        Kita
        <RightOutlined />
      </Button>
    </Flex>
  );
};

export default DateSelector;
