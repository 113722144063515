export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export type FetchCartSuccess = typeof FETCH_CART_SUCCESS;

export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';
export type FetchInvoiceSuccess = typeof FETCH_INVOICE_SUCCESS;

export const POST_CART_SUCCESS = 'POST_CART_SUCCESS';
export type PostCartSuccess = typeof POST_CART_SUCCESS;

export const POST_CART_REQUEST = 'POST_CART_REQUEST';
export type PostCartRequest = typeof POST_CART_REQUEST;

export const POST_CART_PAY_LATER_SUCCESS = 'POST_CART_PAY_LATER_SUCCESS';
export type PostCartPayLaterSuccess = typeof POST_CART_PAY_LATER_SUCCESS;

export const POST_CHECKOUT_SUCCESS = 'POST_CHECKOUT_SUCCESS';
export type PostCheckoutSuccess = typeof POST_CHECKOUT_SUCCESS;

export const POST_CHCEKOUT_REQUEST = 'POST_CHCEKOUT_REQUEST';
export type PostCheckoutRequest = typeof POST_CHCEKOUT_REQUEST;

export const POST_CHECKOUT_CREDITS_SUCCESS = 'POST_CHECKOUT_CREDITS_SUCCESS';
export type PostCheckoutCreditsSuccess = typeof POST_CHECKOUT_CREDITS_SUCCESS;

export const POST_CART_UNLOCK_SUCCESS = 'POST_CART_UNLOCK_SUCCESS';
export type PostCartUnlockSuccess = typeof POST_CART_UNLOCK_SUCCESS;

export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export type ClearCartSuccess = typeof CLEAR_CART_SUCCESS;

export const CART_ERROR = 'CART_ERROR';
export type CartError = typeof CART_ERROR;