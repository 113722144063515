import { Modal } from 'antd';
import React, { useState } from 'react';
import Button from '../../../../common/Button/Button';
import Text from '../../../../common/Text/Text';
import colors from '../../../../Theme/Colors';
import Faq from 'react-faq-component';
import { useIntl } from 'react-intl';
import { Box } from 'rebass';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/index.reducer';

const FaqModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const intl = useIntl();

  const getVideoAndTitleObject = (title: string, src: string) => ({
    title: intl.formatMessage({ id: `faq.${title}` }),
    content: (
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
        <iframe
          title="video"
          src={`https://www.loom.com/embed/${src}`}
          frameBorder="0"
          allowFullScreen
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        ></iframe>
      </div>
    ),
  });

  let data = {
    rows: [
      getVideoAndTitleObject(
        'paymentsDisabled.question1.title',
        '8fd944f208614e3c81b4dbc1042c8877',
      ),
      getVideoAndTitleObject(
        'paymentsDisabled.question2.title',
        '309ebb7ca52f4ac88fb2a23a92774b09',
      ),
      getVideoAndTitleObject(
        'paymentsDisabled.question3.title',
        '2d21d5d5fa6c403aac8f649e864d4fdf',
      ),
      {
        title: intl.formatMessage({ id: 'faq.paymentsDisabled.question4.title' }),
        content: intl.formatMessage({ id: 'faq.paymentsDisabled.question4.answer' }),
      },
      getVideoAndTitleObject(
        'paymentsDisabled.question5.title',
        '855268171d0e4cd48211d9fab1b9dba7',
      ),
      getVideoAndTitleObject(
        'paymentsDisabled.question6.title',
        '6b3a71fe67774e308bcdd75220f21beb',
      ),
    ],
  };

  if (isPaymentEnabled) {
    data = {
      rows: [
        getVideoAndTitleObject(
          'paymentsEnabled.question1.title',
          'ba37f3b8d7fb4c478dd9e77b8184f1f3',
        ),
        getVideoAndTitleObject(
          'paymentsEnabled.question2.title',
          '9fc3185305fd43aba350a7635d93c78c',
        ),
        {
          title: intl.formatMessage({ id: 'faq.paymentsEnabled.question3.title' }),
          content: intl.formatMessage({ id: 'faq.paymentsEnabled.question3.answer' }),
        },
        {
          title: intl.formatMessage({ id: 'faq.paymentsEnabled.question4.title' }),
          content: intl.formatMessage({ id: 'faq.paymentsEnabled.question4.answer' }),
        },
        // {
        //   title: intl.formatMessage({ id: 'faq.paymentsEnabled.question5.title' }),
        //   content: intl.formatMessage({ id: 'faq.paymentsEnabled.question5.answer' }),
        // }, TODO: Enable when order history is enabled
        {
          title: intl.formatMessage({ id: 'faq.paymentsEnabled.question6.title' }),
          content: intl.formatMessage({ id: 'faq.paymentsEnabled.question6.answer' }),
        },
      ],
    };
  }

  const styles = {
    titleTextColor: 'black',
    rowTitleColor: 'black',
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const desktopButton = (
    <Box marginLeft="auto" display={['none', 'block', 'block']}>
      <Button padding="10px 12px" margin="0 17px 0 0" onClick={showModal}>
        <Text color={colors.darkGreen}>{intl.formatMessage({ id: 'faq.title' })}</Text>
      </Button>
    </Box>
  );

  const mobileButton = (
    <Box marginLeft="auto" display={['block', 'none', 'none']}>
      <Box
        backgroundColor={colors.lightGrey}
        width="100%"
        sx={{
          borderRadius: '10px',
          marginTop: '10px',
          div: {
            padding: '9px 0 9px 15px',
          },
        }}
        onClick={showModal}
      >
        <Text>{intl.formatMessage({ id: 'faq.title' })}</Text>
      </Box>
    </Box>
  );

  return (
    <>
      {desktopButton}
      {mobileButton}
      <Modal
        title={intl.formatMessage({ id: 'faq.title' })}
        open={isModalVisible}
        width="100%"
        onCancel={handleCancel}
        footer={[]}
      >
        <Faq data={data} styles={styles} />
      </Modal>
    </>
  );
};

export default FaqModal;
