import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/pricing.constants';
import Config from '../../Config';
import { Pricing } from '../../abstractions/Pricing';

const baseUrl = Config;

export interface FetchPricingSuccess {
  type: constants.FetchPricingSuccess;
  pricing: Pricing;
}

function fetchPricingSuccess(pricing: Pricing): FetchPricingSuccess {
  return {
    type: constants.FETCH_PRICING_SUCCESS,
    pricing,
  };
}

export interface PricingError {
  type: constants.PricingError;
  error: Error;
}

function pricingError(error: Error): PricingError {
  return {
    type: constants.PRICING_ERROR,
    error,
  };
}

export interface ClearPricingSuccess {
  type: constants.ClearPricingSuccess;
}

function clearPricingsSuccess(): ClearPricingSuccess {
  return {
    type: constants.CLEAR_PRICING_SUCCESS,
  };
}

export function getPricing() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}/pricing`);

      dispatch(fetchPricingSuccess(response.data));
    } catch (error) {
      dispatch(pricingError(error.response.data));
    }
  };
}

export function resetPricing() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(clearPricingsSuccess());
  };
}
