import React from 'react';
import { Box, Heading } from 'rebass';
import theme from '../../../Theme/Theme';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import Text from '../../../common/Text/Text';
import { getMealState, mealStateType } from '../../../util/mealState';

const SelectMeal = () => {
  const group = useSelector((state: AppState) => state.authReducer.child)?.group;
  const mealState: mealStateType[] = getMealState(group);

  return (
    <Box width={['90%', '90%', '100%']} margin={'15px auto 0 auto'}>
      <Heading fontFamily={theme.fonts.headingBold}>
        <FormattedMessage id="order.selectMeal.title" />
      </Heading>
      <Text>
        <>
          <FormattedMessage id="order.selectMeal.description1.start" />
          {mealState?.map((meal, index) => (
            <>
              <FormattedMessage id={`order.selectMeal.description1.${meal.name}`} />
              {mealState?.length !== index + 1 ? ', ' : ''}
            </>
          ))}
          <FormattedMessage id="order.selectMeal.description1.end" />
        </>
      </Text>
      <Text>
        <FormattedMessage id="order.selectMeal.description2" />
      </Text>
    </Box>
  );
};

export default SelectMeal;
