import React, { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoggedOutRoute from './LoggedOutRoute';
import LoggedInRoute from './LoggedInRoute';
import LoginPage from '../views/Login/LoginPage';
import ForgotPasswordPage from '../views/ResetCredentials/ForgotPasswordPage/ForgotPasswordPage';
import RecoveryEmailSentPage from '../views/ResetCredentials/RecoveryEmailSentPage/RecoveryEmailSentPage';
import NewPasswordPage from '../views/ResetCredentials/NewPasswordPage/NewPasswordPage';
import Checkout from '../views/Checkout/Checkout';
import BusinessLunchPage from '../views/BusinessLunchPage/BusinessLunchPage';
import CompletedOrderMessage from '../views/BusinessLunchPage/Pages/CompletedOrderMessage';
import OrderPageProvider from '../views/Order/OrderPageProvider/OrderPageProvider';

const Pages = (): ReactElement => (
  <Switch>
    <Route path="/business-lunch/completed-order" component={CompletedOrderMessage} />
    <Route path="/business-lunch" component={BusinessLunchPage} />
    <LoggedOutRoute path="/login" exact component={LoginPage} />
    <LoggedOutRoute path="/forgot-password" exact component={ForgotPasswordPage} />
    <LoggedOutRoute path="/recovery-sent" exact component={RecoveryEmailSentPage} />
    <LoggedOutRoute path="/reset-password" exact component={NewPasswordPage} />
    <LoggedInRoute path="/checkout" component={Checkout} />
    <LoggedInRoute path="/" component={OrderPageProvider} />
    <Route render={() => <h1>404: page not found</h1>} />
  </Switch>
);

export default Pages;
