export const CHANGE_SETTINGS_SUCCESS = 'CHANGE_SETTINGS_SUCCESS';
export type ChangeSettingsSuccess = typeof CHANGE_SETTINGS_SUCCESS;

export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export type ChangeLanguageSuccess = typeof CHANGE_LANGUAGE_SUCCESS;

export const SETTINGS_ERROR = 'SETTINGS_ERROR';
export type SettingsError = typeof SETTINGS_ERROR;

export const SETTINGS_DEFAULT = 'SETTINGS_DEFAULT';
export type SettingsDefault = typeof SETTINGS_DEFAULT;