export const AUTHENTICATE = 'AUTHENTICATE';
export type Authenticate = typeof AUTHENTICATE;

export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export type Unauthenticate = typeof UNAUTHENTICATE;

export const AUTH_FAILURE = 'AUTH_FAILURE';
export type AuthFailure = typeof AUTH_FAILURE;

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export type ClearErrors = typeof CLEAR_ERRORS;

export const ME = 'ME';
export type Me = typeof ME;