import { notification } from 'antd';

interface Props {
  message: string;
  description: string | JSX.Element;
  duration?: number;
}

const alertError = (props: Props): void => {
  const { message, description, duration } = props;
  notification['error']({
    message,
    description,
    duration,
    className: 'error',
  });
};

export default alertError;
