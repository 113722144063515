export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export type FetchOrdersSuccess = typeof FETCH_ORDERS_SUCCESS;

export const GET_ORDER_MENU_SUCCESS = 'GET_ORDER_MENU_SUCCESS';
export type GetOrderMenuSuccess = typeof GET_ORDER_MENU_SUCCESS;

export const POST_ORDERS_REQUEST = 'POST_ORDERS_REQUEST';
export type PostOrdersRequest = typeof POST_ORDERS_REQUEST;

export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export type PostOrderSuccess = typeof POST_ORDER_SUCCESS;

export const CLEAR_ORDER_SUCCESS = 'CLEAR_ORDER_SUCCESS';
export type ClearOrderSuccess = typeof CLEAR_ORDER_SUCCESS;

export const ORDER_ERROR = 'ORDER_ERROR';
export type OrderError = typeof ORDER_ERROR;