import React from 'react';
import { Box } from 'rebass';
import colors from '../../Theme/Colors';
import Text from '../../common/Text/Text';

import { MealCategory } from '../../abstractions/UserSettings';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/index.reducer';
import { Order } from '../../abstractions/Order';
import './Card.css';
import { FormattedMessage } from 'react-intl';
import { DayOrderMenuItem } from '../../abstractions/Menu';
import PaymentStatusLabel from '../PaymentStatusLabel/PaymentStatusLabel';
import DoublePortionButton from './DoublePortionButton/DoublePortionButton';
import CardHeader from './CardHeader/CardHeader';

interface Props {
  selectedMeal: any;
  isChecked: boolean;
  order?: Order;
  selectedMealType: any;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
  onClick: () => void;
}

const Card = (props: Props) => {
  const {
    selectedMeal: { type },
    selectedMealType,
    isChecked,
    onDoubleClick,
    order,
    onClick,
  } = props;
  //@ts-ignore
  const selectedMealAmount = order?.[selectedMealType?.name + '_amount'] ?? 1;
  const isDoubleChecked = !!selectedMealAmount && selectedMealAmount > 1;
  const child = useSelector((state: AppState) => state.authReducer.child);
  const isPaymentEnabled = child?.group?.school?.isPaymentEnabled;
  const defaultMenu = useSelector((state: AppState) => state.orderReducer.orderDefaultMenu);
  const vegetarianMenu = useSelector((state: AppState) => state.orderReducer.orderVegetarianMenu);
  const allergyMenu = useSelector((state: AppState) => state.orderReducer.orderAllergyMenu);

  const shouldDisplayPaymentStatusTag = isChecked && isPaymentEnabled;

  const getBulletList = (menu: DayOrderMenuItem[] | null) => {
    let bulletList: any = [];
    if (menu && menu?.find((item: any) => item?.type === selectedMealType?.name)) {
      menu?.forEach((item: any) => {
        if (selectedMealType?.name === item?.type) {
          bulletList = [...bulletList, item];
        }
      });
      return bulletList;
    }
  };

  const formatBulletList = (bulletList: any) => (
    <ul>
      {bulletList?.map((meal: any) => (
        <li key={meal?.name}>
          <Text color={colors.darkGreen} size="13px">
            {meal?.name?.richText?.[0]?.text || meal?.name}
          </Text>
        </li>
      ))}
    </ul>
  );

  const textOnCard = (type: MealCategory) => {
    switch (type) {
      case MealCategory.Default:
        return formatBulletList(getBulletList(defaultMenu));
      case MealCategory.Allergy: {
        if (allergyMenu) {
          return formatBulletList(getBulletList(allergyMenu));
        }
        return <FormattedMessage id="order.mealType.allergy.description" />;
      }
      case MealCategory.Vegetarian: {
        if (vegetarianMenu) {
          return formatBulletList(getBulletList(vegetarianMenu));
        }
        return <FormattedMessage id={`order.mealType.vegetarian.description`} />;
      }
      case MealCategory.None:
        return <FormattedMessage id={`order.mealType.none.description`} />;
    }
  };

  return (
    <Box>
      <Box
        minHeight={380}
        height="auto"
        width={254}
        sx={{
          backgroundColor: colors.white,
          marginBottom: '23px',
          position: 'relative',
          borderRadius: '16px',
          opacity: type === MealCategory.Allergy && !allergyMenu ? 0.6 : 1,
          '.card-checkbox': {
            height: '24px',
            width: '24px',
            backgroundColor: colors.grey,
          },
          '.card-checkbox input:checked ~ .checkmark': {
            border: 'solid white',
          },
        }}
      >
        <CardHeader
          isChecked={isChecked}
          type={type}
          selectedMealType={selectedMealType}
          onClick={onClick}
        />
        {shouldDisplayPaymentStatusTag && (
          <PaymentStatusLabel
            status={order?.status}
            date={order?.date}
            meal={selectedMealType.name}
            order={order ?? ({} as Order)}
          />
        )}
        <Box
          padding={
            type === MealCategory.Default ||
            (type === MealCategory.Vegetarian && vegetarianMenu) ||
            (type === MealCategory.Allergy && allergyMenu)
              ? '10px 20px 20px 0'
              : '20px'
          }
        >
          {textOnCard(type)}
        </Box>
        <DoublePortionButton
          isDoubleChecked={isDoubleChecked}
          isChecked={isChecked}
          type={type}
          onDoubleClick={onDoubleClick}
        />
      </Box>
    </Box>
  );
};

export default Card;
