import { Row, Col } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatPriceToElement } from '../../helpers/formatPrice';
import Text from '../../../../common/Text/Text';
import { Order } from '../../../../abstractions/Order';

interface Props {
  item: Order;
}

const SubTotalRow = ({ item }: Props) => (
  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
    <Col className="gutter-row" xs={1} md={3} />
    <Col className="gutter-row" xs={10} md={8}>
      <Text alginSelft="center" size="18px" weight="800">
        <FormattedMessage id={`checkout.partialTotal`} />
      </Text>
    </Col>
    <Col className="gutter-row" xs={4} md={2}></Col>
    <Col xs={6} md={4}>
      <Text size="18px" weight="800">
        {formatPriceToElement(item?.total)}
      </Text>
    </Col>
    <Col span={3} />
  </Row>
);

export default SubTotalRow;
