import React from 'react';
import { Modal } from 'antd';
import { Box } from 'rebass';
import Text from '../../../common/Text/Text';
import { FormattedMessage } from 'react-intl';

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
}

const InvoiceInfoModal = ({ isOpen, handleCloseModal }: Props) => (
  <Modal open={isOpen} onCancel={handleCloseModal} footer={[]}>
    <Box>
      <Text>
        <FormattedMessage id="checkout.invoice.title" />
      </Text>
      <br />
      <Text>
        <FormattedMessage id="checkout.invoice.description" />
      </Text>
      <br />
      <Text>
        <FormattedMessage id="checkout.invoice.important" />
      </Text>
    </Box>
  </Modal>
);

export default InvoiceInfoModal;
