/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { Spin } from 'antd';
import './OrderPage.css';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/reducers/index.reducer';
import { getOrders } from '../../redux/actions/order.actions';
import { weekDays } from '../../abstractions/WeekDays';
import { Language } from '../../abstractions/User';
import NavBar from './NavBar/NavBar';
import OrderRules from './OrderRules/OrderRules';
import SelectMeal from './SelectMeal/SelectMeal';
import MealComponent from './Meal/MealComponent';
import StickyFooter from './StickyFooter/StickyFooter';
import momentBusinessDay from 'moment-business-days';
import { getPricing } from '../../redux/actions/pricing.actions';
import { getCart } from '../../redux/actions/cart.actions';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import { mergeCartAndOrdersOverwriteCart } from '../../helpers/cart/mergeOrdersAndCart';

const OrderPage = () => {
  moment.weekdays(true);
  const dispatch = useDispatch();
  const child = useSelector((state: AppState) => state.authReducer.child);
  const orders = useSelector((state: AppState) => state.orderReducer.orders);
  const pricing = useSelector((state: AppState) => state.pricingReducer.pricing);
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const nearestBusinessDate = momentBusinessDay().isBusinessDay()
    ? momentBusinessDay()
    : momentBusinessDay().nextBusinessDay();
  const [selectedDay, setSelecetedDay] = useState(nearestBusinessDate);
  const [selectedEndDay, setSelectedEndDay] = useState(nearestBusinessDate);

  useEffect(() => {
    if (child) {
      if (!cart && isPaymentEnabled) {
        dispatch(getCart());
      } else if (!orders) {
        dispatch(getOrders());
      } else if (!pricing && isPaymentEnabled) {
        dispatch(getPricing());
      }
    }
  }, [dispatch, child, orders, pricing, cart, isPaymentEnabled]);

  if (!child || !orders) {
    return (
      <Flex alignItems="center" justifyContent="center" width="100%" height="100vh">
        <Spin />
      </Flex>
    );
  }
  const mergedOrders = mergeCartAndOrdersOverwriteCart(orders, cart?.items);
  const selectedDayOrder = mergedOrders?.find(
    (order) => order.date === selectedDay.format('YYYY-MM-DD'),
  );

  const orderComponent = (
    <>
      <Box width={['100%', '100%', '80%']} margin="0 auto">
        <SelectMeal />
      </Box>
      <MealComponent
        order={selectedDayOrder}
        selectedDay={selectedDay}
        selectedEndDay={selectedEndDay}
        weekDay={
          child.user_settings.language === Language.LT
            ? weekDays[selectedDay.weekday()]
            : selectedDay.format('dddd')
        }
        onSelectStartDate={(date) => {
          setSelecetedDay(date as Moment);
          if (date?.isAfter(selectedEndDay)) setSelectedEndDay(date as Moment);
        }}
        onSelectEndDate={(date) => {
          setSelectedEndDay(date as Moment);
          if (date?.isBefore(selectedEndDay)) setSelecetedDay(date as Moment);
        }}
      />
      <Box width={['90%', '90%', '80%']} margin="0 auto">
        {selectedDayOrder && <OrderRules />}
      </Box>
    </>
  );

  return (
    <>
      <Box className="card-container" margin="0 auto">
        <NavBar child={child} />
        {orderComponent}
      </Box>
      {isPaymentEnabled && <StickyFooter />}
      {isPaymentEnabled && <PaymentStatus />}
    </>
  );
};

export default OrderPage;
