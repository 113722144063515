import { BusinessDeal, BusinessLunchItem } from '../BusinessLunch.types';

export const getCartAmount = (
  cart: BusinessLunchItem[],
  businessDeal: BusinessDeal | undefined,
) => {
  const tempCart = [...cart];
  let cartAmount = 0;
  tempCart.forEach((item) => {
    if (item.category === 'main' && businessDeal) {
      const soup = tempCart.find((cartItem) => cartItem.category === 'soup');
      if (soup) {
        cartAmount += businessDeal.rule.price;
        cartAmount += soup?.packaging_fee || 0;
        tempCart.splice(tempCart.indexOf(soup), 1);
      } else {
        cartAmount += item.price;
      }
      item?.options?.forEach((option) => {
        cartAmount += option?.packaging_fee || 0;
      });
      cartAmount += item?.packaging_fee || 0;
    } else if (item.category === 'special_main') {
      item?.options?.forEach((option) => {
        cartAmount += option?.packaging_fee || 0;
      });
      cartAmount += item.price;
      cartAmount += item.packaging_fee || 0;
    } else {
      cartAmount += item.price;
      cartAmount += item.packaging_fee || 0;
    }
  });

  return cartAmount;
};
