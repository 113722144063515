import React from 'react';
import { SxStyleProp, Text as TextComponent } from 'rebass';

interface Props {
  children: string | JSX.Element | Element;
  color?: string;
  weight?: string;
  size?: string;
  textTransform?: string;
  margin?: string | string[];
  alginSelft?: string;
  width?: string;
  sx?: SxStyleProp | undefined;
  onClick?: () => void;
}

const Text = (props: Props) => {
  const {
    children,
    color,
    weight = '400',
    size = '16px',
    textTransform,
    margin,
    alginSelft = 'auto',
    width,
    sx,
    onClick,
  } = props;

  return (
    <TextComponent
      color={color}
      fontWeight={weight}
      fontSize={size}
      width={width}
      // @ts-ignore
      sx={{ ...sx, textTransform: textTransform ? textTransform : 'none' }}
      alignSelf={alginSelft}
      margin={margin}
      onClick={onClick}
    >
      {children}
    </TextComponent>
  );
};

export default Text;
