import { Select, Table } from 'antd';
import React from 'react';
import { MealCategory } from '../../../../abstractions/UserSettings';
import { appIntl } from '../../../../helpers/Intl/IntlGlobalProvider';
import { AdminOrder, Order } from '../../../../abstractions/Order';
import { Meal, ShortMeal } from '../../../../abstractions/Meal';
import { Box, Flex, Image } from 'rebass';
import CheckboxComponent from '../../common/CheckboxComponent/CheckboxComponen';
import resolveCellBackgroundColor from '../../helpers/resolveCellBackgroundColor';
import ICON_MAP from '../../helpers/resolveMealIcon';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/index.reducer';
import { AdminManagedGroup } from '../../../../abstractions/User';
import resolveGroupPastelColor from '../../helpers/resolveGroupPastelColor';

interface Props {
  orders: AdminOrder[];
  onItemClick: (item: AdminOrder, userId: number) => void;
  onItemsClick: (items: AdminOrder[]) => void;
}

const resolveIfAllColumnSelected = (orders: AdminOrder[], meal: Meal) =>
  orders.every((order) => order[meal] !== MealCategory.None && order[meal] !== null);

const AdminTable = ({ orders, onItemClick, onItemsClick }: Props) => {
  const isLoading = useSelector((state: AppState) => state.adminOrderReducer.isLoading);
  const groups = useSelector((state: AppState) => state.authReducer.child?.groups);
  const translate = (id: string) => appIntl().formatMessage({ id });
  const handleSelectChange = (record: any, mealType: any, value: any) => {
    onItemClick({ ...record, [mealType]: value }, record.userId);
  };

  const resolveMealSelectOptions = (meal: Meal) => {
    const mealOptions = Object.values(MealCategory).map((category) => {
      return {
        value: category,
        label: translate(`order.mealType.${category}.title`),
      };
    });

    if (meal === Meal.Breakfast || meal === Meal.Snack) {
      return mealOptions.filter((option) => option.value !== MealCategory.Vegetarian);
    }
    return mealOptions;
  };

  const select = (record: any, meal: Meal) => {
    const currMeal = record[meal] || MealCategory.None;

    return {
      props: {
        style: {
          background: resolveCellBackgroundColor(currMeal),
        },
      },
      children: (
        <Box
          sx={{
            '.ant-select-selector': {
              borderRadius: '8px !important',
            },
          }}
        >
          <CheckboxComponent
            isChecked={currMeal !== MealCategory.None}
            onChange={(checked) => {
              handleSelectChange(record, meal, checked ? MealCategory.Default : MealCategory.None);
            }}
          />
          <Select
            defaultValue={currMeal}
            style={{ width: 140, marginLeft: '14px' }}
            onChange={(value) => handleSelectChange(record, meal, value)}
            options={resolveMealSelectOptions(meal)}
            value={currMeal}
          ></Select>
        </Box>
      ),
    };
  };

  const onSelectedAllChange = (meal: Meal, checked: boolean) => {
    if (checked) {
      const updtedOrders = orders.map((order) => ({
        ...order,
        [meal]: MealCategory.Default,
      }));
      onItemsClick(updtedOrders);
    } else {
      const updtedOrders = orders.map((order) => ({
        ...order,
        [meal]: MealCategory.None,
      }));
      onItemsClick(updtedOrders);
    }
  };

  const resolveMealColumns = () => {
    return Object.keys(Meal)
      .filter((key) => Meal[key as keyof typeof Meal] !== Meal.Snack)
      ?.map((key) => {
        return {
          title: (
            <Flex>
              <CheckboxComponent
                isChecked={resolveIfAllColumnSelected(orders, Meal[key as keyof typeof Meal])}
                onChange={(checked) => onSelectedAllChange(Meal[key as keyof typeof Meal], checked)}
              />
              <Box marginLeft="14px">
                {translate(`order.meal.${Meal[key as keyof typeof Meal]}`)}
                <Image
                  src={ICON_MAP[Meal[key as keyof typeof Meal]]}
                  marginLeft="10px"
                  size="30px"
                />
              </Box>
            </Flex>
          ),
          dataIndex: Meal[key as keyof typeof Meal],
          key: Meal[key as keyof typeof Meal],
          render: (_: any, record: any) => select(record, Meal[key as keyof typeof Meal]),
        };
      });
  };

  const resolveIsRowSelected = (order: Order) =>
    Object.keys(ShortMeal)?.some(
      (key) =>
        order[Meal[key as keyof typeof Meal]] !== MealCategory.None &&
        order[Meal[key as keyof typeof Meal]] !== null,
    );

  const groupCell = (group: AdminManagedGroup) => ({
    props: {
      style: {
        background: resolveGroupPastelColor(group.id),
      },
    },
    children: <>{group.name}</>,
  });

  const columns = [
    {
      title: 'Grupė',
      dataIndex: 'group',
      key: 'group',
      render: (_: any, record: any) => {
        const group = groups?.find((group: AdminManagedGroup) => group.id === record?.user?.group);
        if (group) {
          return groupCell(group);
        }
        return <></>;
      },
    },
    { title: 'Vardas', dataIndex: 'name', key: 'name' },
    { title: 'Pavardė', dataIndex: 'surname', key: 'surname' },
    {
      title: '',
      dataIndex: 'indexing',
      key: 'indexing',
      width: '50px',
      render: (_: any, record: any) => {
        return (
          <CheckboxComponent
            isChecked={resolveIsRowSelected(record)}
            onChange={(checked) => {
              if (checked) {
                onItemClick(
                  {
                    ...record,
                    breakfast: MealCategory.Default,
                    snack: MealCategory.None,
                    lunch: MealCategory.Default,
                    afternoon_meal: MealCategory.Default,
                  },
                  record.userId,
                );
              } else {
                onItemClick(
                  {
                    ...record,
                    breakfast: MealCategory.None,
                    snack: MealCategory.None,
                    lunch: MealCategory.None,
                    afternoon_meal: MealCategory.None,
                  },
                  record.userId,
                );
              }
            }}
          />
        );
      },
    },
    ...resolveMealColumns(),
  ];

  return (
    <Box
      sx={{
        '.ant-table-sticky-holder, .ant-table-sticky-scroll': {
          top: '174px !important',
        },
        paddingTop: '174px',
      }}
    >
      <Table
        columns={columns}
        dataSource={orders}
        scroll={{ x: 1000 }}
        pagination={false}
        rowKey={(record) => record.userId}
        loading={isLoading}
        sticky
      />
    </Box>
  );
};

export default AdminTable;
