import React from 'react';
import { Box, Flex, Image } from 'rebass';
import successIcon from '../../../images/svg/SuccessIcon.jpeg';

const CompletedOrderMessage = () => {
  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlignLast: 'center' }}>
        <Image src={successIcon} width={60} margin="0 0 30px 0" />
        <h2>Užsakymas atliktas sėkmingai!</h2>
        <p>Galite uždaryti šį langą</p>
        <p>
          Jeigu norite pakeisti savo užsakymą, tai galite padaryti dar kartą paspaudę elekroniniame
          laiške gautą nuorodą šios dienos užsakymui
        </p>
      </Box>
    </Flex>
  );
};

export default CompletedOrderMessage;
