import colors from '../../../Theme/Colors';
import { MealCategory } from '../../../abstractions/UserSettings';

const resolveCellBackgroundColor = (mealCategory: MealCategory) => {
  switch (mealCategory) {
    case MealCategory.Default:
      return colors.lightGreen;
    case MealCategory.Allergy:
      return colors.lightYellow;
    case MealCategory.Vegetarian:
      return colors.veryLightYellow;
    case MealCategory.None:
      return colors.lightRed;
  }
};

export default resolveCellBackgroundColor;
