export interface BusinessLunchItemOption {
  id: number;
  name: string;
  category: string;
  business_menu?: number;
  display_category?: string;
  packaging_fee?: number;
}

export interface BusinessLunchItem {
  id: number;
  name: string;
  category: string;
  category_display_name?: string;
  price: number;
  packaging_fee?: number;
  options?: BusinessLunchItemOption[];
  business_menu?: number;
  display_category?: string;
}

export interface BusinessLunchMeal {
  id?: number;
  date: string;
  items: BusinessLunchItem[];
  order_until: Date;
}

export interface BusinessDeal {
  id: number;
  kind: string;
  rule: {
    category_1: string;
    category_2: string;
    price: number;
  };
}

export const categoryOrder = [
  'special_main',
  'main',
  'soup',
  'salad',
  'dessert',
  'drink',
  'cutlery_set',
];
