import breakfastIcon from '../../../images/svg/breakfast.svg';
import snackIcon from '../../../images/svg/snack.svg';
import lunchIcon from '../../../images/svg/lunch.svg';
import dinnerIcon from '../../../images/svg/dinner.svg';
import { Meal } from '../../../abstractions/Meal';

const ICON_MAP = {
  [Meal.Breakfast]: breakfastIcon,
  [Meal.Snack]: snackIcon,
  [Meal.Lunch]: lunchIcon,
  [Meal.Afternoon_meal]: dinnerIcon,
};

export default ICON_MAP;
