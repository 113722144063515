import colors from './Colors';

export default {
  breakpoints: ['768px', '992px', '1200px'],
  colors,
  fonts: {
    body: 'Nunito-Medium',
    bodyMedium: 'Nunito-Medium',
    headingBold: 'Nunito-Bold',
    headingMedium: 'Nunito-Medium',
    monospace: 'Nunito-Medium',
    subtitle: 'Nunito-Medium',
    subtitleMedium: 'Nunito-Medium',
    heading: 'Nunito-Medium',
  },
  lineHeights: {
    max: 1.9,
    mid: 1.5,
    min: 1.25,
  },
  shadows: {
    default: '0px 4px 34px rgba(24, 24, 24, 0.16)',
  },
  transitions: {
    default: 'all 0.3s ease-out',
  },
  zIndex: {
    top: '200',
    mid: '100',
  },
  text: {},
};
