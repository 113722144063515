import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import colors from '../../../Theme/Colors';
import Text from '../../../common/Text/Text';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  upperCaseValidation,
  lowerCaseValidation,
  atLeastOneNumberValidation,
} from '../../../util/inputValidation';
import CardWithLogo from '../../../common/CardWithLogo/CardWithLogo';
import { Store } from 'antd/lib/form/interface';
import { sendPasswordReset } from '../../../redux/actions/resetCredentials.actions';
import alertError from '../../../util/alertError';
import { AppState } from '../../../redux/reducers/index.reducer';
import alertSuccess from '../../../util/alertSuccess';

const NewPasswordPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const getDataFromUrlParam = (name: string): string => {
    return new URLSearchParams(location.search).get(name) ?? '';
  };

  const onFinish = (values: Store) => {
    dispatch(sendPasswordReset(code, values.password, values.repeatPassword));
  };

  const code = getDataFromUrlParam('code');

  useEffect(() => {
    // We cannot accept a link without code parameter,
    // must mean that something is broken
    if (!code) {
      alertError({
        message: '',
        description:
          'Atrodo, kad nuoroda neveikia. Jei tai kartojasi, susisiekite su support@ordero.lt',
        duration: 4,
      });
      history.push('/login');
    }
  }, [dispatch, history, code]);

  useSelector((state: AppState) => {
    if (state.resetCredentialsReducer.passwordChanged) {
      alertSuccess({
        message: 'Slaptažodis pakeistas sėkmigai!',
        description: 'Galite prisijungti su naujuoju slaptažodžiu',
        duration: 7,
      });
      history.push('/login');
    }
    return state.resetCredentialsReducer.passwordChanged;
  });

  return (
    <CardWithLogo title="Pakeiskite slaptažodį">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        validateTrigger={['onSubmit']}
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Įrašykite Jūsų naujajį slaptažodį',
            },
            { min: 8, message: 'Slaptažodis turi susidaryti bent iš 8 simbolių' },
            upperCaseValidation,
            lowerCaseValidation,
            atLeastOneNumberValidation,
          ]}
        >
          <Input.Password placeholder="Naujas slaptažodis" style={{ borderRadius: '5px' }} />
        </Form.Item>
        <Form.Item
          name="repeatPassword"
          rules={[
            {
              required: true,
              message: 'Pakartokite slaptažodį',
            },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Slaptažodžiai nesutampa!');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Pakartokitę naują slaptažodį"
            style={{ borderRadius: '5px' }}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            marginBottom: '40px',
            backgroundColor: colors.mintGreen,
            borderRadius: '5px',
            color: colors.black14,
          }}
        >
          Išsaugoti
        </Button>
        <NavLink to="/login">
          <Text size="16px" color={colors.mintGreen}>
            Grįžti į prisijungimo langą
          </Text>
        </NavLink>
      </Form>
    </CardWithLogo>
  );
};

export default NewPasswordPage;
