import React from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logIn } from '../../redux/actions/auth.actions';
import { Store } from 'antd/lib/form/interface';
import { FormattedMessage } from 'react-intl';
import colors from '../../Theme/Colors';
import Text from '../../common/Text/Text';
import { NavLink } from 'react-router-dom';
import CardWithLogo from '../../common/CardWithLogo/CardWithLogo';

const LoginPage = () => {
  const dispatch = useDispatch();

  const onFinish = (values: Store) => {
    dispatch(logIn(values.username.trim(), values.password.trim()));
  };

  return (
    <CardWithLogo title={<FormattedMessage id="login.title" />}>
      <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Įrašykite savo prisijungimo vardą' }]}
        >
          <Input placeholder="Vartotojo vardas" style={{ borderRadius: '5px' }} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Įrašykite savo slaptaždoį' }]}
        >
          <Input.Password placeholder="Slaptažodis" style={{ borderRadius: '5px' }} />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          style={{
            marginBottom: '40px',
            backgroundColor: colors.mintGreen,
            borderRadius: '5px',
            color: colors.black14,
          }}
        >
          <FormattedMessage id="login.title" />
        </Button>
        <NavLink to="/forgot-password">
          <Text size="16px" color={colors.mintGreen}>
            <Text size="16px">Pamiršau slaptažodį</Text>
          </Text>
        </NavLink>
      </Form>
    </CardWithLogo>
  );
};

export default LoginPage;
