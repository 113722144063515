import main from '../../../images/svg/main.svg';
import salad from '../../../images/svg/salad.svg';
import soup from '../../../images/svg/soup.svg';
import dessert from '../../../images/svg/dessert.svg';
import drink from '../../../images/svg/drink.svg';
import cutlery_set from '../../../images/svg/cutlery.svg';

export const getImageByCategory = (category: string) => {
  switch (category) {
    case 'main':
      return main;
    case 'soup':
      return soup;
    case 'salad':
      return salad;
    case 'dessert':
      return dessert;
    case 'drink':
      return drink;
    case 'cutlery_set':
      return cutlery_set;
    default:
      return main;
  }
};
