import * as React from 'react';
import { Box, Flex, Image } from 'rebass';
import colors from '../../../Theme/Colors';
import { FormattedMessage } from 'react-intl';
import Text from '../../Text/Text';
import plus from '../../../images/svg/plus.svg';
import remove from '../../../images/svg/remove.svg';
import { MealCategory } from '../../../abstractions/UserSettings';

interface Props {
  isChecked: boolean;
  isDoubleChecked: boolean;
  type: MealCategory;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
}

const DoublePortionButton = ({ isChecked, isDoubleChecked, type, onDoubleClick }: Props) => (
  <>
    {isChecked && type !== MealCategory.None && (
      <Flex
        onClick={onDoubleClick}
        backgroundColor={colors.grey}
        margin="6px 9px"
        sx={{
          borderRadius: '8px',
          alignItems: 'center',
          position: 'absolute',
          bottom: '0',
          width: '93%',
          height: '46px',
          cursor: 'pointer',
        }}
      >
        <Box
          height={40}
          width={40}
          backgroundColor={isDoubleChecked ? colors.red : colors.white}
          margin="4px"
          sx={{ borderRadius: '8px', width: '18%', display: 'flex', justifyContent: 'center' }}
        >
          <Image src={isDoubleChecked ? remove : plus} width={17} />
        </Box>
        <Text margin={'0 0 0 24px'}>
          <FormattedMessage id={`order.mealType.doublePortion`} />
        </Text>
      </Flex>
    )}
  </>
);

export default DoublePortionButton;
