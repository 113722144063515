import React from 'react';
import { Layout } from 'antd';
import colors from '../../../Theme/Colors';
import { Box, Flex } from 'rebass';
import Text from '../../../common/Text/Text';
import Button from '../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { postCartUnlock } from '../../../redux/actions/cart.actions';
import { getChildInfo } from '../../../redux/actions/auth.actions';
import { getOrders } from '../../../redux/actions/order.actions';

const { Footer } = Layout;

const StickyFooter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const credits = useSelector((state: AppState) => state.authReducer?.child?.credits);

  const calculateTotal = () => {
    let total = 0;
    total = cart?.grand_total || 0 || cart?.fees?.transaction_fee || 0;
    total -= cart?.fees?.transaction_fee || 0;
    return total / 100;
  };

  const getGrandTotal = () => {
    const grandTotal = calculateTotal();
    if (grandTotal < 0) {
      return 'Patvirtinti';
    }
    return (
      <>
        <ShoppingCartOutlined style={{ fontSize: '20px' }} />
        : €{grandTotal}
      </>
    );
  };

  return (
    <Footer
      style={{
        position: 'sticky',
        bottom: '0',
        backgroundColor: colors.white,
        borderTop: '1px solid',
        borderColor: colors.grey92,
        padding: '10px 0',
      }}
    >
      <Flex width={'80%'} margin="0 auto" justifyContent="center">
        <Flex marginLeft={['none', 'auto', 'auto']}>
          {!cart?.payable && !!cart?.items?.length ? (
            <Box display={['block', 'block', 'flex']}>
              <Text alginSelft="center">{intl.formatMessage({ id: 'cart.locked.title' })}</Text>
              <Flex margin={['10px 0 0 0', '0 auto', '0 auto']} justifyContent="center">
                <Button
                  padding="10px 24px"
                  margin="0 10px"
                  backgroundColor={colors.lightYellow}
                  border={`1px solid ${colors.yellow}`}
                  onClick={() => {
                    dispatch(postCartUnlock());
                    setTimeout(() => {
                      dispatch(getChildInfo());
                      dispatch(getOrders());
                    }, 800);
                  }}
                >
                  <Text color={colors.darkGreen}>
                    {intl.formatMessage({ id: 'cart.locked.unlock' })}
                  </Text>
                </Button>
                <Button
                  padding="10px 24px"
                  backgroundColor={colors.lightGreen}
                  border={`1px solid ${colors.brightGreen}`}
                  opacity="0.6"
                  style={{ cursor: 'not-allowed' }}
                >
                  <Text color={colors.darkGreen}>
                      {getGrandTotal()}
                  </Text>
                </Button>
              </Flex>
            </Box>
          ) : (
            <>
              <Button
                padding="10px 24px"
                margin="0 10px"
                backgroundColor={colors.lightRed}
                border={`1px solid ${colors.red}`}
              >
                <Text color={colors.darkGreen}>
                  <>
                    {intl.formatMessage({ id: 'cart.credit' })}: €{credits ? credits / 100 : 0}
                  </>
                </Text>
              </Button>
              <NavLink to="/checkout">
                <Button
                  padding="10px 24px"
                  backgroundColor={colors.lightGreen}
                  border={`1px solid ${colors.brightGreen}`}
                >
                  <Text color={colors.darkGreen}>{getGrandTotal()}</Text>
                </Button>
              </NavLink>
            </>
          )}
        </Flex>
      </Flex>
    </Footer>
  );
};

export default StickyFooter;
