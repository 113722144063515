export interface UserSettings {
  breakfast: MealCategory;
  snack: MealCategory;
  lunch: MealCategory;
  afternoon_meal: MealCategory;
  language: string
}

export enum MealCategory {
  Default = 'default',
  Allergy = 'allergy',
  Vegetarian = 'vegetarian',
  None = 'none',
}
