import { Meal } from '../../../abstractions/Meal';
import breakfastIcon from '../../../images/svg/breakfast.svg';
import lunchIcon from '../../../images/svg/lunch.svg';
import dinnerIcon from '../../../images/svg/dinner.svg';
import snackIcon from '../../../images/svg/snack.svg';

export const getMealIcon = (mealType: Meal) => {
  switch (mealType) {
    case Meal.Breakfast:
      return breakfastIcon;
    case Meal.Snack:
      return snackIcon;
    case Meal.Lunch:
      return lunchIcon;
    case Meal.Afternoon_meal:
      return dinnerIcon;
  }
};
