import { Row, Col, Layout, Badge, Avatar } from 'antd';
import React from 'react';
import { Box, Heading, Flex, Text, Image } from 'rebass';
import colors from '../../../../Theme/Colors';
import { BusinessLunchItem } from '../../BusinessLunch.types';
import { formatToCurrency } from '../../helpers/formatToCurrency';
import { getImageByCategory } from '../../helpers/getImageByCategory';
import Header from '../../../../common/Header/Text';

const { Content } = Layout;

interface Props {
  categories: BusinessLunchItem[];
  menuItems: BusinessLunchItem[];
  cart: BusinessLunchItem[];
  onItemClick: (item: BusinessLunchItem) => void;
}

const MainMenu = ({ categories, menuItems, cart, onItemClick }: Props) => {
  return (
    <Box sx={{ padding: '20px', width: '80%', marginLeft: ['0', '20%', '20%'] }}>
      <Content>
        {categories?.map((category) => {
          return (
            <Box key={category?.id}>
              <Heading margin="10px 0 12px 10px" id={category?.display_category}>
                {category?.display_category}
              </Heading>
              <Row>
                {menuItems
                  .filter((item) => item?.category === category?.category)
                  .map((item) => {
                    const itemCount = cart?.filter((cartItem) => cartItem?.id === item?.id).length;
                    return (
                      <Col xs={24} sm={12} md={12} lg={8} xl={6} key={item.id}>
                        <Box
                          height={200}
                          width={200}
                          backgroundColor={colors.white}
                          sx={{
                            boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;`,
                            borderRadius: '10px',
                            backgroundSize: 'contain',
                            margin: '10px',
                            backgroundColor: colors.white,
                          }}
                        >
                          <Image
                            src={getImageByCategory(item?.category)}
                            padding="3px"
                            maxHeight="47%"
                            maxWidth="50%"
                            marginLeft="3px"
                          />
                          <Flex
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              margin: '14px',
                            }}
                          >
                            <Box width={154}>
                              <Text fontWeight="bold">{item?.name}</Text>
                              <Text>{formatToCurrency(item?.price)}</Text>
                            </Box>
                            <Box
                              onClick={() => onItemClick(item)}
                              alignSelf="flex-end"
                              sx={{ cursor: 'pointer' }}
                            >
                              <Badge count={itemCount} status="success">
                                <Avatar
                                  style={{
                                    backgroundColor: colors.mintGreen,
                                    verticalAlign: 'middle',
                                    marginBottom: '5px',
                                  }}
                                  size="small"
                                >
                                  {<Header>+</Header>}
                                </Avatar>
                              </Badge>
                            </Box>
                          </Flex>
                        </Box>
                      </Col>
                    );
                  })}
              </Row>
            </Box>
          );
        })}
      </Content>
    </Box>
  );
};

export default MainMenu;
