import {
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_SETTINGS_SUCCESS,
  SETTINGS_ERROR,
  SETTINGS_DEFAULT,
} from '../constants/settings.constants';
import {
  ChangeSettingsSuccess,
  SettingsError,
  Settings,
  ChangeLanguageSuccess,
  SettingsDefault,
} from '../actions/settings.actions';
import alertSuccess from '../../util/alertSuccess';
import { appIntl } from '../../helpers/Intl/IntlGlobalProvider';
import alertError from '../../util/alertError';
import { messages } from '../../translations/locales/default';

export interface SettingsReducer {
  settings: Settings;
  settingsUpdated: boolean;
  defaultSelectionsUpdated: boolean;
}

export default function settingsReducer(
  state: SettingsReducer = {
    settings: {} as Settings,
    settingsUpdated: false,
    defaultSelectionsUpdated: false,
  },
  action: ChangeSettingsSuccess | SettingsError | ChangeLanguageSuccess | SettingsDefault,
): SettingsReducer {
  switch (action.type) {
    case CHANGE_SETTINGS_SUCCESS: {
      alertSuccess({
        message: appIntl().formatMessage({ id: 'order.alert.settingsSuccess.message' }),
        description: appIntl().formatMessage({ id: 'order.alert.settingsSuccess.description' }),
        duration: 7,
      });

      return {
        ...state,
        settings: { ...action.settings },
        settingsUpdated: true,
        defaultSelectionsUpdated: true,
      };
    }
    case SETTINGS_ERROR: {
      alertError({
        message: appIntl().formatMessage({ id: 'error.title' }),
        description: appIntl().formatMessage({ id: 'error.description' }),
        duration: 4,
      });

      return {
        ...state,
        settingsUpdated: false,
        defaultSelectionsUpdated: false,
      };
    }
    case CHANGE_LANGUAGE_SUCCESS: {
      alertSuccess({
        // @ts-ignore
        message: messages?.[action.settings.language].order.alert.languageSuccess.message,
        // @ts-ignore
        description: messages?.[action.settings.language].order.alert.languageSuccess.description,
        duration: 4,
      });

      return {
        ...state,
        settings: { ...action.settings },
        settingsUpdated: true,
        defaultSelectionsUpdated: false,
      };
    }
    case SETTINGS_DEFAULT: {
      return {
        ...state,
        settingsUpdated: false,
        defaultSelectionsUpdated: false,
      };
    }
    default:
      return {
        ...state,
        settingsUpdated: false,
        defaultSelectionsUpdated: false,
      };
  }
}
