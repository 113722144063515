import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import { Button, Spin } from 'antd';
import moment from 'moment';
import Navigation from '../../../../src/common/Navigation/Navigation';
import OrderRules from '../../../views/Order/OrderRules/OrderRules';
import momentBusinessDay from 'moment-business-days';
import DateSelector from '../components/DateSelector/DateSelector';
import {
  getAdminOrders,
  postAdminOrder,
  postAdminOrders,
} from '../../../redux/actions/adminOrder.actions';
import AdminTable from '../components/AdminTable/AdminTable';
import AdminStats from '../components/AdminStats/AdminStats';

const AdminOrder: React.FC = () => {
  moment.weekdays(true);
  const dispatch = useDispatch();
  const child = useSelector((state: AppState) => state.authReducer.child);
  const orders = useSelector((state: AppState) => state.adminOrderReducer.orders);
  const isOrdersLoading = useSelector((state: AppState) => state.adminOrderReducer.isLoading);
  const nearestBusinessDate = momentBusinessDay().isBusinessDay()
    ? momentBusinessDay()
    : momentBusinessDay().nextBusinessDay();
  const [selectedDate, setSelectedDate] = useState(nearestBusinessDate);

  useEffect(() => {
    if (!orders && child && child?.groups?.length > 0 && !isOrdersLoading) {
      dispatch(getAdminOrders(child?.groups));
    }
  }, [child, dispatch, isOrdersLoading, orders]);

  if (!child || !orders) {
    return (
      <Flex alignItems="center" justifyContent="center" width="100%" height="100vh">
        <Spin />
      </Flex>
    );
  }

  // @ts-ignore
  const selectedDateOrders: AdminOrder[] = Object.values(orders).find((order) => {
    // @ts-ignore
    return order[0].date === selectedDate.format('YYYY-MM-DD');
  });

  const copyPrevDayOrders = () => {
    const prevDay = momentBusinessDay(selectedDate.clone())
      .businessSubtract(1, 'day')
      .format('YYYY-MM-DD');
    // @ts-ignore
    const prevDayOrders: AdminOrder[] = Object.values(orders).find((order) => {
      // @ts-ignore
      return order[0].date === prevDay;
    });

    const newOrders = prevDayOrders.map((order) => ({
      ...order,
      date: selectedDate.format('YYYY-MM-DD'),
    }));

    dispatch(postAdminOrders(newOrders, selectedDate));
  };

  return (
    <Box>
      <Box sx={{ position: 'fixed', zIndex: 9999, backgroundColor: 'white', width: '100%' }}>
        <Navigation child={child} />
        <Flex alignItems="center">
          <Button
            onClick={copyPrevDayOrders}
            shape="round"
            style={{ maxWidth: '20%', width: 'auto', marginLeft: '30px' }}
          >
            Kopijuoti vakarykštės dienos užsakymus
          </Button>
          <DateSelector
            onClickNextDay={(date) => {
              setSelectedDate(date);
            }}
            onClickPreviousDay={(date) => {
              setSelectedDate(date);
            }}
            selectedDate={selectedDate}
          />
        </Flex>
      </Box>
      <AdminTable
        orders={selectedDateOrders}
        onItemClick={(order, userId) => {
          dispatch(postAdminOrder(order, selectedDate, userId));
        }}
        onItemsClick={(orders) => {
          dispatch(postAdminOrders(orders, selectedDate));
        }}
      />
      <AdminStats orders={selectedDateOrders} />
      <Box width={['90%', '90%', '80%']} margin="0 auto">
        <OrderRules />
      </Box>
    </Box>
  );
};

export default AdminOrder;
