import { Order } from '../../abstractions/Order';
import {
  FETCH_ORDERS_SUCCESS,
  POST_ORDER_SUCCESS,
  POST_ORDERS_REQUEST,
  ORDER_ERROR,
  CLEAR_ORDER_SUCCESS,
  GET_ORDER_MENU_SUCCESS,
} from '../constants/order.constants';
import {
  FetchOrdersSuccess,
  PostOrderSuccess,
  OrderError,
  ClearOrderSuccess,
  GetOrderMenuSuccess,
  PostOrdersRequest,
} from '../actions/order.actions';
import alertError from '../../util/alertError';
import alertSuccess from '../../util/alertSuccess';
import { DayOrderMenuItem } from '../../abstractions/Menu';
import { MealCategory } from '../../abstractions/UserSettings';
import { appIntl } from '../../helpers/Intl/IntlGlobalProvider';

export interface OrderReducer {
  orders: Order[] | null;
  alertMessage: string | null;
  orderDefaultMenu: DayOrderMenuItem[] | null;
  orderVegetarianMenu: DayOrderMenuItem[] | null;
  orderAllergyMenu: DayOrderMenuItem[] | null;
  isLoading: boolean;
}

export default function orderReducer(
  state: OrderReducer = {
    orders: null,
    alertMessage: null,
    orderDefaultMenu: null,
    orderVegetarianMenu: null,
    orderAllergyMenu: null,
    isLoading: false,
  },
  action:
    | FetchOrdersSuccess
    | PostOrderSuccess
    | PostOrdersRequest
    | GetOrderMenuSuccess
    | ClearOrderSuccess
    | OrderError,
): OrderReducer {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        isLoading: false,
      };
    case POST_ORDERS_REQUEST:
      return { ...state, isLoading: true };
    case POST_ORDER_SUCCESS: {
      if (state.orders) {
        const updatedoOrdersList: Order[] = [...state.orders];

        const orderIndexes =
          action.orders.map((actionOrder) => {
            return state?.orders?.findIndex((order) => order.date === actionOrder.date);
          }) || [];

        orderIndexes?.forEach((index, i) => {
          if (index !== undefined && updatedoOrdersList[index]) {
            updatedoOrdersList[index] = {
              ...action.orders[i],
              menu: updatedoOrdersList[index].menu,
            };
          }
        });

        const orderMessage = `${appIntl().formatMessage({ id: 'order.postSuccess.title' })} ${
          action.orders[0].date
        } ${action.orders.length > 1 ? ` - ${action.orders[action.orders.length - 1].date}` : ''}`;

        alertSuccess({
          message: orderMessage,
          description: appIntl().formatMessage({ id: 'order.postSuccess.description' }),
          duration: 5,
        });

        return {
          ...state,
          orders: updatedoOrdersList,
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_ORDER_MENU_SUCCESS: {
      if (action?.orderMenu?.menu_meta?.category === MealCategory.Allergy) {
        return { ...state, orderAllergyMenu: action.orderMenu?.items, isLoading: false };
      }
      if (action?.orderMenu?.menu_meta?.category === MealCategory.Vegetarian) {
        return { ...state, orderVegetarianMenu: action.orderMenu?.items, isLoading: false };
      }
      return { ...state, orderDefaultMenu: action.orderMenu?.items, isLoading: false };
    }
    case CLEAR_ORDER_SUCCESS: {
      return { ...state, orders: null, isLoading: false };
    }
    case ORDER_ERROR: {
      if (
        action.error.message === 'Selection can be edited until 9 AM' ||
        action.error.message === 'Selection can be made until 9 AM'
      ) {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.selectionEdit.title' }),
          description: appIntl().formatMessage({ id: 'order.error.selectionEdit.description' }),
          duration: 5,
        });
      } else if (action.error.message === 'Breakfast / snack selection can be edited until 9 AM') {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.breakfastEdit.title' }),
          description: appIntl().formatMessage({ id: 'order.error.breakfastEdit.description' }),
          duration: 5,
        });
      } else if (action.error.message === 'Breakfast / snack selection can be edited until 4 AM') {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.todayBreakfastEdit.title' }),
          description: appIntl().formatMessage({
            id: 'order.error.todayBreakfastEdit.description',
          }),
          duration: 5,
        });
      } else {
        alertError({
          message: appIntl().formatMessage({ id: 'error.title' }),
          description: appIntl().formatMessage({ id: 'error.description' }),
          duration: 5,
        });
      }

      return { ...state, isLoading: false };
    }
    default:
      return { ...state, isLoading: false };
  }
}
