import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/cart.constants';
import Cart from '../../abstractions/Cart';

import Config from '../../Config';
import { Order } from '../../abstractions/Order';
import { mergeOrdersAndCartOverwriteOrders } from '../../helpers/cart/mergeOrdersAndCart';
import { normalizeOrders } from '../../helpers/order/normalizeOrders';

const baseUrl = `${Config}/cart`;

export interface FetchCartSuccess {
  type: constants.FetchCartSuccess;
  cart: Cart;
}

function fetchCartSuccess(cart: Cart): FetchCartSuccess {
  return {
    type: constants.FETCH_CART_SUCCESS,
    cart,
  };
}

export interface ClearCartSuccess {
  type: constants.ClearCartSuccess;
}

function clearCartSuccess(): ClearCartSuccess {
  return {
    type: constants.CLEAR_CART_SUCCESS,
  };
}

export interface PostCartSuccess {
  type: constants.PostCartSuccess;
  cart: Cart;
}

function postCartSuccess(cart: Cart): PostCartSuccess {
  return {
    type: constants.POST_CART_SUCCESS,
    cart,
  };
}

export interface PostCartUnlockSuccess {
  type: constants.PostCartUnlockSuccess;
  cart: Cart;
}

function postCartUnlockSuccess(cart: Cart): PostCartUnlockSuccess {
  return {
    type: constants.POST_CART_UNLOCK_SUCCESS,
    cart,
  };
}

export interface PostCartRequest {
  type: constants.PostCartRequest;
}

function postCartRequest(): PostCartRequest {
  return {
    type: constants.POST_CART_REQUEST,
  };
}

export interface PostCheckoutRequest {
  type: constants.PostCheckoutRequest;
}

function postCheckoutRequest(): PostCheckoutRequest {
  return {
    type: constants.POST_CHCEKOUT_REQUEST,
  };
}

export interface PostCheckoutSuccess {
  type: constants.PostCheckoutSuccess;
  url?: string;
  is_payment: boolean;
}

function postCheckoutSuccess(is_payment: boolean, url?: string): PostCheckoutSuccess {
  return {
    type: constants.POST_CHECKOUT_SUCCESS,
    url,
    is_payment,
  };
}

export interface PostCartPayLaterSuccess {
  type: constants.PostCartPayLaterSuccess;
}

function postCartPayLaterSuccess(): PostCartPayLaterSuccess {
  return {
    type: constants.POST_CART_PAY_LATER_SUCCESS,
  };
}

export interface FetchInvoiceSuccess {
  type: constants.FetchInvoiceSuccess;
  invoices: Cart[];
}

function fetchInvoiceSuccess(invoices: Cart[]): FetchInvoiceSuccess {
  return {
    type: constants.FETCH_INVOICE_SUCCESS,
    invoices,
  };
}

export interface CartError {
  type: constants.CartError;
  error: Error;
}

function cartError(error: Error): CartError {
  return {
    type: constants.CART_ERROR,
    error,
  };
}

export function getCart() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}`);

      dispatch(fetchCartSuccess(response.data));
    } catch (error) {
      dispatch(cartError(error.response.data));
    }
  };
}

export function postCart(orders: Order[], cart: Cart | null) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(postCartRequest());
    try {
      const mergedOrders = [...(cart?.items || []), ...orders].filter(
        (thing, index, self) => self.findIndex((t) => t.date === thing.date) === index,
      );
      const mergedOrdersWithCart = mergeOrdersAndCartOverwriteOrders(
        normalizeOrders(orders),
        mergedOrders,
      );
      const response = await axios.put(`${baseUrl}/orders`, { orders: mergedOrdersWithCart });

      dispatch(postCartSuccess(response.data as Cart));
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function postCartPayLater() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}/orders`);
      await axios.post(`${response.data}/orders`);

      dispatch(postCartPayLaterSuccess());
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function postCartOutdated() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}`);
      await axios.put(`${baseUrl}/orders`, { orders: response.data.items });
      dispatch(postCheckout());
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function postCheckout() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      dispatch(postCheckoutRequest());
      const response = await axios.post(`${baseUrl}/checkout`);
      dispatch(postCheckoutSuccess(response.data?.is_payment, response?.data?.payment_url));
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function postCartUnlock() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.post(`${baseUrl}/unlock`);
      dispatch(postCartUnlockSuccess(response?.data));
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function getInvoices() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}/invoices`);
      dispatch(fetchInvoiceSuccess(response?.data));
    } catch (error) {
      dispatch(cartError(error.response?.data || error?.response));
    }
  };
}

export function resetCart() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(clearCartSuccess());
  };
}
