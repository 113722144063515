import { Spin } from 'antd';
import React from 'react';
import { Box } from 'rebass';

const LoadingSpinner = () => (
  <Box width={35}>
    <Spin />
  </Box>
);

export default LoadingSpinner;
