import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import { getChildInfo } from '../../../redux/actions/auth.actions';
import GroupAdmin from '../../../apps/admin-dashboard/GroupAdmin';
import OrderPage from '../OrderPage';

const OrderPageProvider = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.authReducer.child);

  useEffect(() => {
    if (!user) {
      dispatch(getChildInfo());
    }
  }, [dispatch, user]);

  if (user?.role?.type === 'groupadmin') {
    return <GroupAdmin />;
  }

  return <OrderPage />;
};

export default OrderPageProvider;
