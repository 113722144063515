export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export type PasswordResetRequest = typeof PASSWORD_RESET_REQUEST;

export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export type PasswordResetSuccess = typeof PASSWORD_RESET_SUCCESS;

export const SEND_RESET_SUCCESS = 'SEND_RESET_SUCCESS';
export type SendResetSuccess = typeof SEND_RESET_SUCCESS;

export const SEND_RESET_REQUEST = 'SEND_RESET_REQUEST';
export type SendResetRequest = typeof SEND_RESET_REQUEST;

export const RESET_ERROR = 'RESET_ERROR';
export type ResetError = typeof RESET_ERROR;

export const CLEAR_STATE = 'CLEAR_STATE';
export type ClearState = typeof CLEAR_STATE;
