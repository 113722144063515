const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const letterRule = {
  pattern: new RegExp('.*[A-ZĄČĘĖĮŠŲŪŽ ]+$', 'i'),
  message: 'Gali būti naudojama tik lietuvių arba anglų abecelė',
};

export const slugRule = {
  pattern: new RegExp('^[a-zA-Z0-9-]*$', 'i'),
  message: 'Turi būti naudojama tik abecelė, skaičiai ar -',
};

export const validateEmail = {
  required: 'Prašome įrašyti savo el. pašto adresą',
  types: {
    email: 'El. pašto adresas nėra validus',
  },
};

export const nameValidationRules = (title: string): any => [
  { required: true, message: `Please enter ${title}` },
  {
    max: 25,
    message: `${capitalizeFirstLetter(title)} cannot be longer than 25 characters`,
  },
  letterRule,
];

export const upperCaseValidation = {
  pattern: new RegExp('.*[A-ZĄČĘĖĮŠŲŪŽ].*'),
  message: 'Turi turėti bent vieną didžiają raidę',
};

export const lowerCaseValidation = {
  pattern: new RegExp('.*[a-ząčęėįšųūž].*'),
  message: 'Turi turėti bent vieną mažają raidę',
};

export const atLeastOneNumberValidation = {
  pattern: new RegExp('.*\\d.*'),
  message: 'Turi turėti bent vieną skaičių',
};
