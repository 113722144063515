import { CheckCircleOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Meal } from '../../abstractions/Meal';
import { Order, OrderStatus } from '../../abstractions/Order';
import { MealCategory } from '../../abstractions/UserSettings';
import { AppState } from '../../redux/reducers/index.reducer';
import { shouldDisplayOrderItem } from '../../views/BusinessLunchPage/helpers/shouldDisplayOrderItem';

interface Props {
  meal: Meal;
  order: Order;
  status?: OrderStatus;
  date?: string;
}

const PaymentStatusTag = ({ meal, order, status, date }: Props) => {
  const intl = useIntl();
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const foundCartItem = cart?.items?.find((item) => item.date === date);
  if (shouldDisplayOrderItem(foundCartItem as Order, meal)) {
    status = OrderStatus.InCart;
  } else if (order?.[meal] === MealCategory.None && order?.status === OrderStatus.Paid) {
    status = undefined;
  }

  switch (status) {
    case OrderStatus.Paid: {
      return (
        <Tag color="green" icon={<CheckCircleOutlined />}>
          {intl.formatMessage({ id: 'order.status.paid' })}
        </Tag>
      );
    }
    case OrderStatus.Pending: {
      return <Tag color="orange">{intl.formatMessage({ id: 'order.status.pending' })}</Tag>;
    }
    case OrderStatus.InCart: {
      return <Tag color="gold">{intl.formatMessage({ id: 'order.status.inCart' })}</Tag>;
    }
  }
  return <></>;
};

export default PaymentStatusTag;
