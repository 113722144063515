import React, { ReactElement, useEffect } from 'react';
import './App.css';
import { ThemeProvider } from 'emotion-theming';
import Theme from './Theme/Theme';
import { BrowserRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './redux/reducers/index.reducer';
import { checkAuthentication } from './redux/actions/auth.actions';
import Pages from './routes/Pages';
import ScrollToTop from './util/scrollToTop';
import flatten from 'flat';
import { IntlProvider } from 'react-intl';
import { IntlGlobalProvider } from './helpers/Intl/IntlGlobalProvider';
import { messages } from './translations/locales/default';

const App = (): ReactElement => {
  const authChecked = useSelector((state: AppState) => state.authReducer.authChecked);
  const locale = useSelector((state: AppState) => state.authReducer.child?.user_settings?.language) || 'lt';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthentication());
  }, [dispatch]);

  const app = authChecked ? (
    <IntlProvider locale={locale} messages={flatten(messages[locale])}>
      <IntlGlobalProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Route component={Pages} />
        </BrowserRouter>
      </IntlGlobalProvider>
    </IntlProvider>
  ) : null;

  return <ThemeProvider theme={Theme}>{app}</ThemeProvider>;
};

export default App;
