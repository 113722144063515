import { Meal } from '../../../abstractions/Meal';
import { Order } from '../../../abstractions/Order';
import { MealCategory } from '../../../abstractions/UserSettings';

export const shouldDisplayOrderItem = (item: Order, key: Meal) => {
  const isItemNotNone = item?.[key] !== MealCategory.None;
  const isOldItemNotNone = item?.[`${key}_old`] && item?.[`${key}_old`] !== MealCategory.None;
  const areOldAndNewItemsDifferent = item?.[key] !== item?.[`${key}_old`];
  const areOldAndNewAmountsDifferent = item?.[`${key}_amount`] !== item?.[`${key}_amount_old`];

  return (
    (isItemNotNone || isOldItemNotNone) &&
    (areOldAndNewItemsDifferent || areOldAndNewAmountsDifferent)
  );
};
