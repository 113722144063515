import React from 'react';
import { Flex } from 'rebass';
import { DatePicker } from 'antd';
import theme from '../../../Theme/Theme';
import moment, { Moment } from 'moment';
import 'moment/locale/lt';
import Text from '../../../common/Text/Text';
import { FormattedMessage } from 'react-intl';

const DatePickerComponent = DatePicker as any; // Bug solution inside ant design library

interface Props {
  startDate: Moment;
  endDate: Moment;
  weekDay: string;
  onStartDateChange: (value: Moment | null) => void;
  onChangeEndDate: (value: Moment | null) => void;
}

const SelectedDate = (props: Props) => {
  const { startDate, endDate, onStartDateChange, onChangeEndDate } = props;

  const disabledDate = (current: any) => {
    // Can not select days for weekends
    return (
      moment(current).day() === 0 ||
      moment(current).day() === 6 ||
      moment(current).isBefore(new Date()) ||
      moment(current).isAfter(moment().add(2, 'months'))
    );
  };

  const checkForNullDate = (date: Moment | null) => {
    if (date === null) {
      return moment();
    }
    return date;
  };

  return (
    <>
      <Flex
        width={['100%', '100%', '50%']}
        sx={{ display: 'inline-flex', span: { alignSelf: 'center', margin: '0 auto !important' } }}
        marginTop={[30, 0, 0]}
        marginBottom={[24, 0, 0]}
      >
        <Flex
          minWidth={['auto', 'auto', '50%']}
          margin="auto"
          backgroundColor={theme.colors.lightGrey}
          padding={['13px 10px', '13px 30px', '13px 40px']}
          sx={{ borderRadius: '16px' }}
        >
          <Text alginSelft="center">
            <FormattedMessage id="datePicker.from" />
          </Text>
          <DatePickerComponent
            bordered={false}
            onChange={(date: Moment) => onStartDateChange(checkForNullDate(date))}
            value={startDate}
            disabledDate={disabledDate}
            inputReadOnly={true}
            size="large"
          />
          <Text alginSelft="center">
            <FormattedMessage id="datePicker.until" />
          </Text>
          <DatePickerComponent
            bordered={false}
            onChange={(date: Moment) => onChangeEndDate(checkForNullDate(date))}
            value={endDate}
            disabledDate={disabledDate}
            inputReadOnly={true}
            size="large"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SelectedDate;
