import * as React from 'react';

import { Checkbox } from 'antd';
import { Box } from 'rebass';

interface Props {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckboxComponent = ({ isChecked, onChange }: Props) => {
  return (
    <Box
      sx={{
        display: 'contents',
        '.ant-checkbox-inner, .ant-checkbox-input': {
          transform: 'scale(1.5)',
        },
      }}
    >
      <Checkbox checked={isChecked} onChange={(e) => onChange(e.target.checked)} />
    </Box>
  );
};

export default CheckboxComponent;
