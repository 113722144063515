import { ArrowDownOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Image } from 'rebass';

import Text from '../../../../../common/Text/Text';
import { Meal } from '../../../../../abstractions/Meal';
import { Order } from '../../../../../abstractions/Order';
import { formatPriceToElement } from '../../../helpers/formatPrice';
import { getMealIcon } from '../../../helpers/getMealIcon';

interface Props {
  item: Order;
  meal: Meal;
}

const DayOldOrderRow = ({ item, meal }: Props) => {
  if (
    item?.[`${meal}_old`] &&
    (item?.[meal] !== item?.[`${meal}_old`] ||
      item?.[`${meal}_amount`] !== item?.[`${meal}_amount_old`])
  ) {
    // @ts-ignore
    const { price, gov_discounted_price } = item[`${meal}_pricing_old`];
    return (
      <>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          key={item.id}
          style={{ textDecoration: 'line-through' }}
        >
          <Col className="gutter-row" xs={1} md={3} />
          <Col className="gutter-row" xs={10} md={8}>
            <Flex alignItems="center">
              <Image
                src={getMealIcon(meal as Meal)}
                style={{ maxWidth: '30px' }}
                marginRight={20}
              />
              <Text alginSelft="center" size="18px">
                <>
                  <FormattedMessage id={`order.meal.${meal}`} />
                  {': '}
                  <strong>
                    <FormattedMessage id={`order.mealType.${item?.[`${meal}_old`]}.title`} />
                  </strong>
                </>
              </Text>
              <ArrowDownOutlined style={{ margin: '0 10px' }} />
            </Flex>
          </Col>
          <Col className="gutter-row" xs={4} md={2}>
            {/* @ts-ignore */}
            <Text size="18px">{item?.[`${meal}_amount_old`]}</Text>
          </Col>
          <Col xs={4} md={4}>
            <Text size="18px">
              {formatPriceToElement(gov_discounted_price || price, item?.[`${meal}_amount_old`])}
            </Text>
          </Col>
          <Col span={3} />
        </Row>
      </>
    );
  }

  return <></>;
};

export default DayOldOrderRow;
