import { Order } from '../../abstractions/Order';

export const mergeOrdersAndCartOverwriteOrders = (orders: Order[], cartItems: Order[] | undefined) => {
  if (!cartItems) return orders;
  const mergedOrders = cartItems?.map((cartOrder) => {
    const foundOrder = orders?.find((order) => order.date === cartOrder.date);
    if (foundOrder) {
      return { ...cartOrder, ...foundOrder };
    }
    return cartOrder;
  });
  return mergedOrders;
};

export const mergeCartAndOrdersOverwriteCart = (orders: Order[], cartItems: Order[] | undefined) => {
  if (!orders) return cartItems;
  const mergedOrders = orders?.map((order) => {
    const foundOrder = cartItems?.find((cartOrder) => cartOrder.date === order.date);
    if (foundOrder) {
      return { ...order, ...foundOrder };
    }
    return order;
  });
  return mergedOrders;
};
