import { Meal } from '../../../abstractions/Meal';
import { Order } from '../../../abstractions/Order';
import { shouldDisplayOrderItem } from '../../BusinessLunchPage/helpers/shouldDisplayOrderItem';

export const isCartItemEmptyOrChanged = (item: Order) =>
  Object.values(Meal)?.every((key) => {
    if (shouldDisplayOrderItem(item, key as Meal)) {
      return false;
    }
    return true;
  });
