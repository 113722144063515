import Cart from '../../abstractions/Cart';
import {
  FETCH_CART_SUCCESS,
  POST_CART_SUCCESS,
  CART_ERROR,
  CLEAR_CART_SUCCESS,
  POST_CHECKOUT_SUCCESS,
  POST_CART_REQUEST,
  POST_CART_UNLOCK_SUCCESS,
  FETCH_INVOICE_SUCCESS,
  POST_CART_PAY_LATER_SUCCESS,
  POST_CHCEKOUT_REQUEST,
} from '../constants/cart.constants';
import {
  FetchCartSuccess,
  PostCartSuccess,
  CartError,
  ClearCartSuccess,
  PostCheckoutRequest,
  PostCheckoutSuccess,
  PostCartRequest,
  PostCartUnlockSuccess,
  FetchInvoiceSuccess,
  PostCartPayLaterSuccess,
} from '../actions/cart.actions';
import alertSuccess from '../../util/alertSuccess';
import { appIntl } from '../../helpers/Intl/IntlGlobalProvider';
import errorAlert from '../../helpers/cart/errorAlert';

export interface CartReducer {
  cart: Cart | null;
  is_payment: boolean;
  url: string | undefined | null;
  coveredByCredits: boolean;
  alertMessage: string | null;
  isLoading: boolean;
  invoices: Cart[];
  cartOutdated?: boolean;
}

export default function cartReducer(
  state: CartReducer = {
    cart: null,
    is_payment: true,
    url: null,
    coveredByCredits: false,
    alertMessage: null,
    isLoading: true,
    invoices: [],
    cartOutdated: false,
  },
  action:
    | FetchCartSuccess
    | PostCartRequest
    | PostCartSuccess
    | ClearCartSuccess
    | CartError
    | PostCheckoutRequest
    | PostCheckoutSuccess
    | PostCartUnlockSuccess
    | FetchInvoiceSuccess
    | PostCartPayLaterSuccess,
): CartReducer {
  switch (action.type) {
    case FETCH_CART_SUCCESS:
      return {
        ...state,
        cart: action.cart,
        coveredByCredits: false,
        isLoading: false,
        cartOutdated: false,
      };
    case POST_CART_REQUEST:
      return { ...state, coveredByCredits: false, isLoading: true, cartOutdated: false };
    case POST_CART_SUCCESS: {
      if (state.cart) {
        alertSuccess({
          message: appIntl().formatMessage({ id: 'cart.addedToCart.title' }),
          description: appIntl().formatMessage({ id: 'cart.addedToCart.description' }),
          duration: 7,
        });

        return {
          ...state,
          cart: action.cart,
          coveredByCredits: false,
          isLoading: false,
          cartOutdated: false,
        };
      }
      return {
        ...state,
        coveredByCredits: false,
        isLoading: false,
        cartOutdated: false,
      };
    }
    case POST_CART_UNLOCK_SUCCESS: {
      alertSuccess({
        message: appIntl().formatMessage({ id: 'cart.unlocked.title' }),
        description: appIntl().formatMessage({ id: 'cart.unlocked.description' }),
        duration: 5,
      });
      return {
        ...state,
        cart: action.cart,
        cartOutdated: false,
      };
    }
    case POST_CHCEKOUT_REQUEST: {
      return {
        ...state,
        is_payment: false,
        coveredByCredits: false,
        isLoading: true,
        cartOutdated: false,
      };
    }
    case POST_CHECKOUT_SUCCESS: {
      if (action.is_payment) {
        return {
          ...state,
          is_payment: true,
          url: action?.url,
          coveredByCredits: false,
          isLoading: false,
        };
      }
      return {
        ...state,
        is_payment: false,
        coveredByCredits: true,
        isLoading: false,
        cartOutdated: false,
      };
    }
    case FETCH_INVOICE_SUCCESS: {
      return { ...state, invoices: action.invoices, cartOutdated: false };
    }
    case POST_CART_PAY_LATER_SUCCESS: {
      return {
        ...state,
        coveredByCredits: true,
        is_payment: false,
        isLoading: false,
        cartOutdated: false,
      };
    }
    case CLEAR_CART_SUCCESS: {
      return {
        ...state,
        cart: null,
        coveredByCredits: false,
        isLoading: false,
        cartOutdated: false,
      };
    }
    case CART_ERROR: {
      console.error('ERROR', action.error);

      if (action.error.message === 'cart_outdated') {
        return { ...state, coveredByCredits: false, isLoading: false, cartOutdated: true };
      }
      errorAlert(action.error.message);
      return { ...state, coveredByCredits: false, isLoading: false, cartOutdated: false };
    }
    default:
      return { ...state, coveredByCredits: false, isLoading: false, cartOutdated: false };
  }
}
