import React from 'react';
import { Box, Flex, Image } from 'rebass';
import colors from '../../Theme/Colors';
import { User } from '../../abstractions/User';
import Text from '../../common/Text/Text';
import { useDispatch } from 'react-redux';

import logo from '../../../src/images/svg/logo.svg';
import logout from '../../../src/images/svg/logout.svg';
import { useHistory } from 'react-router-dom';
import { logOut } from '../../redux/actions/auth.actions';
import { resetCart } from '../../redux/actions/cart.actions';
import { resetOrders } from '../../redux/actions/order.actions';
import { resetPricing } from '../../redux/actions/pricing.actions';

interface Props {
  child: User;
}

const NavBar = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogoutClick = () => {
    dispatch(resetOrders());
    dispatch(resetPricing());
    dispatch(resetCart());
    dispatch(logOut());
  };

  const {
    child: { name, surname, groups },
  } = props;

  const managedGroup = groups[0];

  const desktopMenu = (
    <Box marginLeft="auto" display={['none', 'block', 'block']}>
      <Flex alignItems="center">
        <Text margin="0 20px 0">{managedGroup?.school?.name}</Text>
        <Text color={colors.brightGreen} weight="400" size="16px">
          {managedGroup?.name}
        </Text>
        <Image src={logout} onClick={onLogoutClick} marginLeft="30px" sx={{ cursor: 'pointer' }} />
      </Flex>
    </Box>
  );

  return (
    <>
      <Flex width={'100%'} sx={{ borderBottom: '1px solid', borderColor: colors.grey92 }}>
        <Flex width={'80%'} margin="0 auto">
          <Flex width="100%" height="80px" alignItems="center">
            <Image
              src={logo}
              width={'120px'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/');
              }}
            />
            <Box marginLeft="40px" display={['none', 'block', 'block']}>
              <Text
                color={colors.brightGreen}
                weight="400"
                size="16px"
              >{`${name} ${surname}`}</Text>
            </Box>
            {desktopMenu}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default NavBar;
