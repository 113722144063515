import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Badge } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Box, Image } from 'rebass';
import { useDispatch, useSelector } from 'react-redux';

import { Meal } from '../../../../../abstractions/Meal';
import { MealCategory } from '../../../../../abstractions/UserSettings';
import { formatPriceToElement, formatPrice } from '../../../helpers/formatPrice';
import Text from '../../../../../common/Text/Text';
import { Order } from '../../../../../abstractions/Order';
import { AppState } from '../../../../../redux/reducers/index.reducer';
import { getMealIcon } from '../../../helpers/getMealIcon';
import { postCart } from '../../../../../redux/actions/cart.actions';

interface Props {
  item: Order;
  meal: Meal;
  canRemoveMeal?: boolean;
}

const DayNewOrderRow = ({ item, meal, canRemoveMeal }: Props) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: AppState) => state.cartReducer.cart);

  const handleRemoveMeal = (order: Order, meal: Meal) => {
    let updatedOrder = { ...order };
    if (
      order?.[`${meal}_old`] &&
      (order?.[meal] !== order?.[`${meal}_old`] ||
        order?.[`${meal}_amount`] !== order?.[`${meal}_amount_old`])
    ) {
      updatedOrder = {
        ...order,
        [meal]: order?.[`${meal}_old`],
        [`${meal}_amount`]: order?.[`${meal}_amount_old`],
      };
    } else {
      updatedOrder = { ...order, [meal]: MealCategory.None, [`${meal}_amount`]: 1 };
    }
    dispatch(postCart([updatedOrder], cart));
  };

  const gov_discount_applies = useSelector(
    (state: AppState) => state.authReducer.child?.group?.gov_discount_applies,
  );

  // @ts-ignore
  const gov_discounted_price = item?.[`${meal}_pricing`]?.gov_discounted_price;
  const price = item?.[`${meal}_pricing`]?.price;
  const amount = item?.[`${meal}_amount`];

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} key={item.id}>
      <Col className="gutter-row" xs={1} md={3} />
      <Col className="gutter-row" xs={10} md={8}>
        <Flex>
          <Image src={getMealIcon(meal as Meal)} style={{ maxWidth: '30px' }} marginRight={20} />
          <Text alginSelft="center" size="18px">
            <>
              <FormattedMessage id={`order.meal.${meal}`} />
              {': '}
              <strong>
                <FormattedMessage id={`order.mealType.${item?.[meal]}.title`} />
              </strong>
            </>
          </Text>
        </Flex>
      </Col>
      <Col className="gutter-row" xs={4} md={2}>
        {/* @ts-ignore */}
        <Text size="18px">{item?.[`${meal}_amount`]}</Text>
      </Col>
      <Col xs={6} md={4}>
        <Flex sx={{ alignItems: 'center' }}>
          {meal === Meal.Lunch &&
          item?.[`${meal}`] !== MealCategory.None &&
          gov_discount_applies ? (
            <>
              <s>
                <Text size="18px">{formatPriceToElement(price, amount)}</Text>
              </s>
              <Badge
                style={{ marginLeft: '10px' }}
                count={`€${formatPrice(gov_discounted_price, amount)}`}
              />
            </>
          ) : (
            <Text size="18px">{formatPriceToElement(price, amount)}</Text>
          )}
        </Flex>
      </Col>
      {canRemoveMeal && (
        <Col span={3}>
          <Box
            margin="0 0 0 auto"
            sx={{
              cursor: 'pointer',
              span: {
                display: 'flex',
                alignItems: 'center',
                svg: { width: '30px', height: '30px' },
              },
            }}
            onClick={() => handleRemoveMeal(item, meal)}
          >
            <CloseCircleOutlined
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          </Box>
        </Col>
      )}
    </Row>
  );
};

export default DayNewOrderRow;
