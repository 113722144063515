import React from 'react';
import { Button as ButtonComponent, SxStyleProp } from 'rebass';
import colors from '../../Theme/Colors';
import { Spin } from 'antd';

interface Props {
  children: JSX.Element;
  loading?: boolean;
  padding?: string;
  margin?: string;
  border?: string;
  backgroundColor?: string;
  width?: string;
  maxWidth?: string;
  opacity?: string;
  style?: SxStyleProp;
  onClick?: () => void;
}

const Button = (props: Props) => {
  const {
    children,
    loading,
    padding,
    margin,
    border,
    backgroundColor = colors.grey,
    width,
    maxWidth,
    opacity,
    style,
    onClick,
  } = props;
  return (
    <ButtonComponent
      padding={padding}
      color={colors.grey}
      margin={margin}
      backgroundColor={backgroundColor}
      onClick={onClick}
      width={width}
      maxWidth={maxWidth}
      opacity={opacity}
      sx={{ borderRadius: '16px', border: border, cursor: 'pointer', ...style }}
    >
      {loading ? <Spin /> : children}
    </ButtonComponent>
  );
};

export default Button;
