import React from 'react';
import { Modal, Button, Divider, notification } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import Config from '../../../../Config';
import { BusinessDeal, BusinessLunchItem } from '../../BusinessLunch.types';
import { formatToCurrency } from '../../helpers/formatToCurrency';
import { MinusCircleFilled } from '@ant-design/icons';
import colors from '../../../../Theme/Colors';

const baseUrl = Config;

interface Props {
  isVisible: boolean;
  cart: BusinessLunchItem[];
  businessDeal: BusinessDeal | undefined;
  cartAmount: number;
  menuId: number;
  onRemoveItem: (item: BusinessLunchItem) => void;
  onCancel: () => void;
}

const CheckoutModal = ({
  isVisible,
  cart,
  businessDeal,
  cartAmount,
  menuId,
  onRemoveItem,
  onCancel,
}: Props) => {
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const tempCart = [...cart]?.sort((a, b) => {
    if (a?.category === 'main' && b?.category !== 'main') return -1;
    if (a?.category !== 'main' && b?.category === 'main') return 1;
    return 0;
  });

  const postOrder = async () => {
    try {
      await axios.post(`${baseUrl}/business-orders`, {
        token: params.get('token'),
        business_menu: menuId,
        items: cart,
      });
      history.push('/business-lunch/completed-order');
    } catch (error) {
      console.error(error);
      if ((error.message = 'Ordering is closed')) {
        notification['error']({
          message: 'Klaida!',
          description: 'Baigėsi galioti nuoroda. Prašome naudoti kitos dienos nuorodą.',
          duration: 4,
          className: 'error',
        });
      }
    }
  };

  const getFullItemPrice = (item: BusinessLunchItem) =>
    item?.price +
    (item?.packaging_fee || 0) +
    (item?.options?.reduce((acc, option) => acc + (option?.packaging_fee || 0), 0) || 0);

  const getFullItemPriceWithSoup = (item: BusinessLunchItem, soup: BusinessLunchItem) =>
    (item?.packaging_fee || 0) +
    (soup?.packaging_fee || 0) +
    (item?.options?.reduce((acc, option) => acc + (option?.packaging_fee || 0), 0) || 0) +
    (businessDeal?.rule?.price || 0);

  const packagingFeeSection = (item: BusinessLunchItem, withSoup: boolean) => (
      !!item?.packaging_fee && (
        <Text color="darkGrey">
          {withSoup ? 'Sriubos pakuotės' : 'Pakuotės'} mokestis: ({' '}
          {formatToCurrency(item.packaging_fee)} )
        </Text>
      )
    );

  const removeItemButton = (item: BusinessLunchItem) => (
    <Flex>
      <h3>{item.name}</h3>
      <MinusCircleFilled
        style={{
          fontSize: '23px',
          color: colors.secondaryRed,
          marginLeft: 'auto',
          alignSelf: 'center',
        }}
        onClick={() => onRemoveItem(item)}
      />
    </Flex>
  );

  const mealOptionsSection = (item: BusinessLunchItem) => (
    <Flex>
      <Box>
        {item?.options?.map((option) => (
          <Flex key={option.id}>
            <Text color="darkGrey">{option?.name}</Text>
            {!!option?.packaging_fee && (
              <Text color="darkGrey" marginLeft={10}>
                ( pakuotė: {formatToCurrency(option?.packaging_fee)} )
              </Text>
            )}
          </Flex>
        ))}
      </Box>
    </Flex>
  );

  const genericMealSection = (item: BusinessLunchItem) => (
    <Box key={item.id}>
      <Box>
        {removeItemButton(item)}
        {packagingFeeSection(item, false)}
      </Box>
      {mealOptionsSection(item)}
      {formatToCurrency(getFullItemPrice(item))}
      <Divider />
    </Box>
  );

  return (
    <Modal
      title="Užsakymo suvestinė"
      open={isVisible}
      onCancel={onCancel}
      style={{ top: 20, borderRadius: '10px', overflow: 'auto' }}
      footer={[
        <Button
          key="submit"
          onClick={() => {
            postOrder();
          }}
        >
          <strong>Patvirtinti užsakymą</strong>
        </Button>,
      ]}
    >
      <Box>
        {tempCart?.map((item) => {
          if (item?.category === 'main' && tempCart) {
            const soup = tempCart?.find((cartItem) => cartItem?.category === 'soup');
            if (soup) {
              tempCart.splice(tempCart.indexOf(soup), 1);
              return (
                <Box key={item.id}>
                  <Box>
                    <h3>
                      Pagrindinis + sriuba = {formatToCurrency(businessDeal?.rule?.price || 0)}
                    </h3>
                    {removeItemButton(item)}
                    {removeItemButton(soup)}
                    {packagingFeeSection(item, false)}
                    {packagingFeeSection(soup, true)}
                  </Box>
                  {mealOptionsSection(item)}
                  {formatToCurrency(getFullItemPriceWithSoup(item, soup))}
                  <Divider />
                </Box>
              );
            }

            return genericMealSection(item);
          }
          return genericMealSection(item);
        })}
        <Flex>
          <Text>Iš viso:&nbsp;</Text>
          <Text className="notranslate">{formatToCurrency(cartAmount)}</Text>
        </Flex>
      </Box>
    </Modal>
  );
};

export default CheckoutModal;
