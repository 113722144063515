import { notification } from 'antd';

interface Props {
  message: string;
  description: string;
  duration?: number;
}

const alertSuccess = (props: Props): void => {
  const { message, description, duration } = props;
  notification['success']({
    message,
    description,
    duration,
    className: 'success',
  });
};

export default alertSuccess;
