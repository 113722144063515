import breakfastIcon from '../images/svg/breakfast.svg';
import snackIcon from '../images/svg/snack.svg';
import lunchIcon from '../images/svg/lunch.svg';
import dinnerIcon from '../images/svg/dinner.svg';
import { Meal } from '../abstractions/Meal';
import { Group } from '../abstractions/User';

interface nameTypePair {
  name: string;
  type: string;
}

export type mealStateType = {
  name: Meal;
  image: string;
  types: nameTypePair[];
};

const withVegetarianTypes = [
  {
    name: 'Default',
    type: 'default',
  },
  {
    name: 'Vegetarian',
    type: 'vegetarian',
  },
  {
    name: 'Allergies',
    type: 'allergy',
  },
  {
    name: 'No food',
    type: 'none',
  },
];

const withoutVegetarianTypes = [
  {
    name: 'Default',
    type: 'default',
  },
  {
    name: 'Allergies',
    type: 'allergy',
  },
  {
    name: 'No food',
    type: 'none',
  },
];

export const getMealState = (group?: Group): mealStateType[] => {
  let mealState: mealStateType[] = [];

  if (group?.is_breakfast_enabled === null || group?.is_breakfast_enabled) {
    mealState.push({
      name: Meal.Breakfast,
      image: breakfastIcon,
      types: withoutVegetarianTypes,
    });
  }

  if (group?.is_snack_enabled === null || group?.is_snack_enabled) {
    mealState.push({
      name: Meal.Snack,
      image: snackIcon,
      types: withoutVegetarianTypes,
    });
  }

  if (group?.is_lunch_enabled === null || group?.is_lunch_enabled) {
    mealState.push({
      name: Meal.Lunch,
      image: lunchIcon,
      types: withVegetarianTypes,
    });
  }

  if (group?.is_afternoon_meal_enabled === null || group?.is_afternoon_meal_enabled) {
    mealState.push({
      name: Meal.Afternoon_meal,
      image: dinnerIcon,
      types: withVegetarianTypes,
    });
  }

  return mealState;
};
