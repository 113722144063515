import { AnyAction, combineReducers } from 'redux';
import authReducer from './auth.reducer';
import orderReducer from './order.reducer';
import resetCredentialsReducer from './resetCredentials.reducer';
import settingsReducer from './settings.reducer';
import pricingReducer from './pricing.reducer';
import cartReducer from './cart.reducer';
import { UNAUTHENTICATE } from '../constants/auth.constants';
import adminOrderReducer from './adminOrder.reducer';

const reducer = combineReducers({
  authReducer,
  orderReducer,
  adminOrderReducer,
  settingsReducer,
  resetCredentialsReducer,
  pricingReducer,
  cartReducer,
});

export const rootReducer = (state: any, action: any) =>
  reducer(
    action.type === UNAUTHENTICATE
      ? {
          ...reducer({} as ReturnType<typeof reducer>, {} as AnyAction),
          router: (state && state.router) || {},
        }
      : state,
    action
  );

export type AppState = ReturnType<typeof rootReducer>;
