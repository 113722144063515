import { Row, Col } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import Text from '../../../../common/Text/Text';
import { Order } from '../../../../abstractions/Order';

interface Props {
  item: Order;
  children: React.ReactNode;
}

const DayOrderRow = ({ item, children }: Props) => {
  // @ts-ignore
  if (!item) {
    return <LoadingSpinner />;
  }
  return (
    <Fragment key={item.id}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={1} md={3} />
        <Col xs={10} md={8}>
          <Text size="20px">{item?.date}</Text>
        </Col>
        <Col xs={4} md={2}>
          <Text size="18px">
            <FormattedMessage id={`checkout.amount`} />
          </Text>
        </Col>
        <Col xs={4} md={2}>
          <Text size="18px">
            <FormattedMessage id={`checkout.price`} />
          </Text>
        </Col>
      </Row>
      {children}
    </Fragment>
  );
};

export default DayOrderRow;
