import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { Meal } from '../../abstractions/Meal';
import { Order, OrderStatus } from '../../abstractions/Order';
import { MealCategory } from '../../abstractions/UserSettings';
import { AppState } from '../../redux/reducers/index.reducer';
import colors from '../../Theme/Colors';
import { shouldDisplayOrderItem } from '../../views/BusinessLunchPage/helpers/shouldDisplayOrderItem';
import Text from '../Text/Text';

interface Props {
  status?: OrderStatus;
  date?: string;
  order?: Order;
  meal: Meal;
}

const PaymentStatusLabel = ({ status, date, meal, order }: Props): JSX.Element => {
  const intl = useIntl();
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const foundCartItem = cart?.items?.find((item) => item.date === date);
  if (shouldDisplayOrderItem(foundCartItem as Order, meal)) {
    status = OrderStatus.InCart;
  } else if (order?.[meal] === MealCategory.None && order?.status === OrderStatus.Paid) {
    status = undefined;
  }

  const cardWrapper = (backgroundColor: string, text: string) => (
    <Flex
      sx={{
        backgroundColor,
        padding: '2px 0 2px 20px',
        width: '254px',
        borderRadius: '0 0 16px 16px',
        alignItems: 'center',
        opacity: '0.7',
      }}
    >
      <CheckCircleOutlined style={{ color: colors.white }} />
      <Text color={colors.white} margin="0 0 0 20px">
        {text}
      </Text>
    </Flex>
  );

  switch (status) {
    case OrderStatus.InCart: {
      return cardWrapper(colors.yellow, intl.formatMessage({ id: 'order.status.inCart' }));
    }
    case OrderStatus.Pending: {
      return cardWrapper(colors.yellow, intl.formatMessage({ id: 'order.status.pending' }));
    }
    case OrderStatus.Paid: {
      return cardWrapper(colors.brightGreen, intl.formatMessage({ id: 'order.status.paid' }));
    }
  }
  return <></>;
};

export default PaymentStatusLabel;
