import React from 'react';
import { Text as TextComponent } from 'rebass';

interface Props {
  children: string | JSX.Element;
  color?: string;
  weight?: string;
  size?: string;
  textTransform?: string;
  margin?: string | string[];
  alginSelft?: string;
  width?: string;
  onClick?: () => void;
}

const Header = (props: Props) => {
  const {
    children,
    color,
    weight = '400',
    size = '1.5em',
    textTransform,
    margin,
    alginSelft = 'auto',
    width,
    onClick,
  } = props;
  return (
    <TextComponent
      color={color}
      fontWeight={weight}
      fontSize={size}
      width={width}
      sx={{ textTransform: textTransform ? textTransform : 'none', alignSelf: alginSelft }}
      margin={margin}
      onClick={onClick}
    >
      {children}
    </TextComponent>
  );
};

export default Header;
