export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export type FetchOrdersSuccess = typeof FETCH_ORDERS_SUCCESS;

export const GET_ORDER_MENU_SUCCESS = 'GET_ORDER_MENU_SUCCESS';
export type GetOrderMenuSuccess = typeof GET_ORDER_MENU_SUCCESS;

export const POST_ADMIN_ORDERS_REQUEST = 'POST_ADMIN_ORDERS_REQUEST';
export type PostAdminOrdersRequest = typeof POST_ADMIN_ORDERS_REQUEST;

export const POST_ADMIN_ORDER_SUCCESS = 'POST_ADMIN_ORDER_SUCCESS';
export type PostAdminOrderSuccess = typeof POST_ADMIN_ORDER_SUCCESS;

export const POST_ADMIN_ORDERS_SUCCESS = 'POST_ADMIN_ORDERS_SUCCESS';
export type PostAdminOrdersSuccess = typeof POST_ADMIN_ORDERS_SUCCESS;

export const CLEAR_ORDER_SUCCESS = 'CLEAR_ORDER_SUCCESS';
export type ClearOrderSuccess = typeof CLEAR_ORDER_SUCCESS;

export const ADMIN_ORDER_ERROR = 'ADMIN_ORDER_ERROR';
export type AdminOrderError = typeof ADMIN_ORDER_ERROR;
