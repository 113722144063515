import { Table } from 'antd';
import React from 'react';
import { AdminOrder } from '../../../../abstractions/Order';
import { Meal } from '../../../../abstractions/Meal';
import { MealCategory } from '../../../../abstractions/UserSettings';
import { Box, Flex, Image } from 'rebass';
import ICON_MAP from '../../helpers/resolveMealIcon';
import { appIntl } from '../../../../helpers/Intl/IntlGlobalProvider';

interface Props {
  orders: AdminOrder[];
}

const getMealCountsPerMeal = (orders: AdminOrder[], mealCategory: MealCategory) => {
  const initialMealCounts = {
    breakfast: 0,
    snack: 0,
    lunch: 0,
    afternoon_meal: 0,
  };

  return orders.reduce((acc, order) => {
    if (order.breakfast === mealCategory) {
      acc.breakfast += 1;
    }
    if (order.snack === mealCategory) {
      acc.snack += 1;
    }
    if (order.lunch === mealCategory) {
      acc.lunch += 1;
    }
    if (order.afternoon_meal === mealCategory) {
      acc.afternoon_meal += 1;
    }
    return acc;
  }, initialMealCounts);
};

const AdminStats = ({ orders }: Props) => {
  const translate = (id: string) => appIntl().formatMessage({ id });

  const resolveMealColumns = () =>
    Object.keys(Meal).filter((key) => Meal[key as keyof typeof Meal] !== Meal.Snack)?.map((key) => ({
      title: (
        <Flex>
          <Box marginLeft="14px">
            {translate(`order.meal.${Meal[key as keyof typeof Meal]}`)}
            <Image src={ICON_MAP[Meal[key as keyof typeof Meal]]} marginLeft="10px" size="30px" />
          </Box>
        </Flex>
      ),
      dataIndex: Meal[key as keyof typeof Meal],
      key: Meal[key as keyof typeof Meal],
    }));

  const columns = [
    { title: 'Patiekalo tipas', dataIndex: 'sum', key: 'sum' },
    ...resolveMealColumns(),
  ];

  const mealCounts: any = [
    {
      sum: 'Įprasti ',
      ...getMealCountsPerMeal(orders, MealCategory.Default),
    },
    {
      sum: 'Vegetariški ',
      ...getMealCountsPerMeal(orders, MealCategory.Vegetarian),
    },
    {
      sum: 'Alergiški ',
      ...getMealCountsPerMeal(orders, MealCategory.Allergy),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={mealCounts}
      pagination={false}
      scroll={{ x: 800 }}
      rowKey={(record) => record?.sum}
    />
  );
};

export default AdminStats;
