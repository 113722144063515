import alertError from '../../util/alertError';
import { appIntl } from '../Intl/IntlGlobalProvider';

const errorAlert = (error: string) => {
  switch (error) {
    case 'Selection can be edited until 9 AM':
      return alertError({
        message: appIntl().formatMessage({ id: 'order.error.selectionEdit.title' }),
        description: appIntl().formatMessage({ id: 'order.error.selectionEdit.description' }),
        duration: 5,
      });
    case 'Breakfast / snack selection can be edited until 9 AM':
      return alertError({
        message: appIntl().formatMessage({ id: 'order.error.breakfastEdit.title' }),
        description: appIntl().formatMessage({ id: 'order.error.breakfastEdit.description' }),
        duration: 4,
      });
    case 'Breakfast / snack selection can be edited until 4 AM':
      return alertError({
        message: appIntl().formatMessage({ id: 'order.error.breakfastEdit.title' }),
        description: appIntl().formatMessage({ id: 'order.error.breakfastEdit.description' }),
        duration: 4,
      });
    case 'Pending orders cannot be added':
      return alertError({
        message: appIntl().formatMessage({ id: 'cart.error.locked.title' }),
        description: appIntl().formatMessage({ id: 'cart.error.locked.description' }),
        duration: 5,
      });
    case 'Insufficient credits':
      return alertError({
        message: appIntl().formatMessage({ id: 'cart.error.notEnoughCredits.title' }),
        description: appIntl().formatMessage({ id: 'cart.error.notEnoughCredits.description' }),
        duration: 7,
      });
    default:
      alertError({
        message: appIntl().formatMessage({ id: 'error.title' }),
        description: appIntl().formatMessage({ id: 'error.description' }),
        duration: 4,
      });
  }
};

export default errorAlert;
