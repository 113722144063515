import React from 'react';
import { Box, Image } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { Moment } from 'moment';

import colors from '../../../Theme/Colors';
import Text from '../../../common/Text/Text';
import sheep from '../../../images/svg/sheep.svg';
import conversationBubbleStart from '../../../images/svg/conversationBubbleStart.svg';
import Header from '../../../common/Header/Text';

interface Props {
  selectedDate: Moment;
}

const HolidaySection = ({ selectedDate }: Props) => {
  const sheepImage = (
    <Image
      src={sheep}
      height="300px"
      width="400px"
      sx={{ position: 'relative', bottom: '15px', left: '10px' }}
    />
  );

  const holidayInformation = (
    <Box
      backgroundColor={colors.white}
      width="600px"
      alignSelf={'center'}
      padding={'10px 30px'}
      sx={{ borderRadius: '10px' }}
    >
      <Header weight="600">
        <FormattedMessage id="holiday.title" />
      </Header>
      <Text size="15px">
        <>
          {selectedDate.format('YYYY-MM-DD')}
          <FormattedMessage id="holiday.description" />
        </>
      </Text>
    </Box>
  );

  const desktopView = (
    <Box sx={{ borderRadius: '24px' }} display={['none', 'none', 'flex']}>
      {sheepImage}
      <Image src={conversationBubbleStart} width="41px" marginLeft={'20px'} />
      {holidayInformation}
    </Box>
  );

  const mobileView = (
    <Box sx={{ borderRadius: '24px' }} display={['block', 'block', 'none']}>
      {holidayInformation}
      <Image
        src={conversationBubbleStart}
        width="41px"
        marginLeft="120px"
        sx={{ rotate: '270deg' }}
      />
      {sheepImage}
    </Box>
  );

  return (
    <>
      {desktopView}
      {mobileView}
    </>
  );
};

export default HolidaySection;
