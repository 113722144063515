import {
  PASSWORD_RESET_SUCCESS,
  CLEAR_STATE,
  SEND_RESET_SUCCESS,
  SEND_RESET_REQUEST,
  PASSWORD_RESET_REQUEST,
  RESET_ERROR,
} from '../constants/resetCredentials.constants';
import {
  ResetPasswordRequest,
  ClearState,
  SendResetRequest,
  SendResetSuccess,
  ResetPasswordSuccess,
  ResetError,
} from '../actions/resetCredentials.actions';
import alertSuccess from '../../util/alertSuccess';
import alertError from '../../util/alertError';

interface ResetCrecentialsReducer {
  requestSent: boolean;
  passwordChanged: boolean;
  isSendResetLoading: boolean;
  isPasswordResetLoading: boolean;
}

const initialState: ResetCrecentialsReducer = {
  requestSent: false,
  passwordChanged: false,
  isSendResetLoading: false,
  isPasswordResetLoading: false,
};

type ResetCredentialsActionType =
  | ResetError
  | ResetPasswordRequest
  | ResetPasswordSuccess
  | SendResetRequest
  | SendResetSuccess
  | ClearState;

export default function resetCredentialsReducer(
  state: ResetCrecentialsReducer = initialState,
  action: ResetCredentialsActionType,
): ResetCrecentialsReducer {
  switch (action.type) {
    case SEND_RESET_REQUEST:
      return { ...state, isSendResetLoading: true };
    case SEND_RESET_SUCCESS:
      return {
        ...state,
        requestSent: true,
        isSendResetLoading: false,
      };
    case PASSWORD_RESET_REQUEST:
      return { ...state, isPasswordResetLoading: true };
    case PASSWORD_RESET_SUCCESS:
      alertSuccess({
        message: 'Slaptažodis sėkmingai pakeistas!',
        description: 'Password has been changed successfully',
        duration: 4,
      });
      return { ...state, passwordChanged: true, isPasswordResetLoading: false };
    case RESET_ERROR:
      alertError({
        message: 'Įvyko klaida',
        description: 'Jei tai kartojasi, susisiekite su support@ordero.lt',
        duration: 4,
      });
      return { ...state, isSendResetLoading: false, isPasswordResetLoading: false };
    case CLEAR_STATE:
      return { ...state, ...initialState };
    default:
      return { ...state };
  }
}
