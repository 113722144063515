import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/adminOrder.constants';
import { AdminDateOrder, AdminOrder } from '../../abstractions/Order';

import Config from '../../Config';
import { Moment } from 'moment';
import { AdminManagedGroup, User } from '../../abstractions/User';
import { MealCategory } from '../../abstractions/UserSettings';
import { DayOrderMenu } from '../../abstractions/Menu';
import { normalizeOrders } from '../../helpers/order/normalizeOrders';

const baseUrl = Config;

export interface FetchOrdersSuccess {
  type: constants.FetchOrdersSuccess;
  orders: AdminDateOrder[];
}

function fetchOrdersSuccess(orders: AdminDateOrder[]): FetchOrdersSuccess {
  return {
    type: constants.FETCH_ORDERS_SUCCESS,
    orders,
  };
}

export interface GetOrderMenuSuccess {
  type: constants.GetOrderMenuSuccess;
  orderMenu: DayOrderMenu;
}

function getOrderMenuSuccess(orderMenu: DayOrderMenu): GetOrderMenuSuccess {
  return {
    type: constants.GET_ORDER_MENU_SUCCESS,
    orderMenu,
  };
}

export interface ClearOrderSuccess {
  type: constants.ClearOrderSuccess;
}

function clearOrdersSuccess(): ClearOrderSuccess {
  return {
    type: constants.CLEAR_ORDER_SUCCESS,
  };
}

export interface PostOrdersRequest {
  type: constants.PostAdminOrdersRequest;
}

function postOrdersRequest(): PostOrdersRequest {
  return {
    type: constants.POST_ADMIN_ORDERS_REQUEST,
  };
}
export interface PostOrderSuccess {
  type: constants.PostAdminOrderSuccess;
  order: AdminOrder;
  date: Moment;
}

function postOrderSuccess(order: AdminOrder, date: Moment): PostOrderSuccess {
  return {
    type: constants.POST_ADMIN_ORDER_SUCCESS,
    order,
    date,
  };
}

export interface PostOrdersSuccess {
  type: constants.PostAdminOrdersSuccess;
  orders: AdminOrder[];
  date: Moment;
}

function postOrdersSuccess(orders: AdminOrder[], date: Moment): PostOrdersSuccess {
  return {
    type: constants.POST_ADMIN_ORDERS_SUCCESS,
    orders,
    date,
  };
}

export interface AdminOrderError {
  type: constants.AdminOrderError;
  error: Error;
}

function orderError(error: Error): AdminOrderError {
  return {
    type: constants.ADMIN_ORDER_ERROR,
    error,
  };
}

export function getAdminOrders(groups: AdminManagedGroup[]) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(postOrdersRequest());
    try {
      const groupOrdersPromises = groups.map((group) => {
        return axios.get(`${baseUrl}/orders/by/group?groupId=${group.id}`);
      });
      const response = await axios.all(groupOrdersPromises);

      const mergeMultipleArrays = (...arrays: any[]) => {
        const merged = {};

        // Helper function to merge a single array into the merged object
        const mergeObjects = (source: any, target: any) => {
          for (let key in source) {
            if (source.hasOwnProperty(key)) {
              if (!target[key]) {
                target[key] = [];
              }
              target[key] = target[key].concat(source[key]);
            }
          }
        };

        // Iterate through each array and merge it into the merged object
        arrays.forEach((array) => {
          mergeObjects(array, merged);
        });

        return merged;
      };

      const allOrders = mergeMultipleArrays(...response.map((resp) => resp.data));

      dispatch(fetchOrdersSuccess(allOrders as AdminDateOrder[]));
    } catch (error) {
      dispatch(orderError(error.response.data));
    }
  };
}

export function getMenuByDate(date: Moment, user: User, category: MealCategory) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.post(`${baseUrl}/menu/resolve`, {
        date: date.format('YYYY-MM-DD'),
        userId: user?.id,
        schoolId: user?.group?.school?.id,
        category,
      });

      dispatch(getOrderMenuSuccess(response.data));
    } catch (error) {
      dispatch(orderError(error.response.data));
    }
  };
}

export function postAdminOrder(order: AdminOrder, date: Moment, userId?: number) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(postOrdersRequest());

    try {
      const response = await axios.put(`${baseUrl}/orders/by/group`, {
        orders: normalizeOrders([order]),
      });
      dispatch(postOrderSuccess({ ...response.data[0], userId }, date));
    } catch (error) {
      dispatch(orderError(error.response?.data || error?.response));
    }
  };
}

export function postAdminOrders(orders: AdminOrder[], date: Moment, userId?: number) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(postOrdersRequest());

    try {
      const response = await axios.put(`${baseUrl}/orders/by/group`, {
        orders: normalizeOrders(orders),
      });
      dispatch(postOrdersSuccess(response.data, date));
    } catch (error) {
      dispatch(orderError(error.response?.data || error?.response));
    }
  };
}

export function resetOrders() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(clearOrdersSuccess());
  };
}
