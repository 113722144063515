import { Modal, Spin } from 'antd';
import React, { useState } from 'react';
import Button from '../../../../common/Button/Button';
import Text from '../../../../common/Text/Text';
import colors from '../../../../Theme/Colors';
import { useIntl } from 'react-intl';
import { Box, Flex } from 'rebass';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/lt';
import './styles.css';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/index.reducer';
import momentBusinessDay from 'moment-business-days';
import { mergeCartAndOrdersOverwriteCart } from '../../../../helpers/cart/mergeOrdersAndCart';
import PaymentStatusTag from '../../../../common/PaymentStatusTag/PaymentStatusTag';
import { Meal } from '../../../../abstractions/Meal';
import { getMealState } from '../../../../util/mealState';

const CalendarModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const orders = useSelector((state: AppState) => state.orderReducer.orders);
  const cart = useSelector((state: AppState) => state.cartReducer.cart);
  const group = useSelector((state: AppState) => state.authReducer.child)?.group;
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const localizer = momentLocalizer(moment);
  const intl = useIntl();
  const mealsLength = getMealState(group)?.length;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (!orders) {
    return (
      <Flex alignItems="center" justifyContent="center" width="100%" height="100vh">
        <Spin />
      </Flex>
    );
  }

  const events: any = [];
  mergeCartAndOrdersOverwriteCart(orders, cart?.items)?.forEach((order) => {
    const {
      breakfast,
      breakfast_amount,
      date,
      snack,
      snack_amount,
      lunch,
      lunch_amount,
      afternoon_meal,
      afternoon_meal_amount,
      status,
    } = order;
    const none = 'none';
    let currentTime = 7;

    if (group?.is_breakfast_enabled === null || group?.is_breakfast_enabled) {
      events.push({
        title: `${intl.formatMessage({ id: `order.meal.breakfast` })}: ${intl.formatMessage({
          id: `order.mealType.${breakfast ?? none}.title`,
        })} ${breakfast_amount && breakfast_amount > 1 ? 'x2' : ''}`,
        start: moment(date).add(currentTime, 'hours').toDate(),
        end: moment(date)
          .add(currentTime + 2, 'hours')
          .toDate(),
        resource: { mealCategory: breakfast ?? none, status, meal: Meal.Breakfast, date, order },
      });
      currentTime += 2;
    }

    if (group?.is_snack_enabled === null || group?.is_snack_enabled) {
      events.push({
        title: `${intl.formatMessage({ id: `order.meal.snack` })}: ${intl.formatMessage({
          id: `order.mealType.${snack ?? none}.title`,
        })} ${snack_amount && snack_amount > 1 ? 'x2' : ''}`,
        start: moment(date).add(currentTime, 'hours').toDate(),
        end: moment(date)
          .add(currentTime + 2, 'hours')
          .toDate(),
        resource: { mealCategory: snack ?? none, status, meal: Meal.Snack, date, order },
      });
      currentTime += 2;
    }

    if (group?.is_lunch_enabled === null || group?.is_lunch_enabled) {
      events.push({
        title: `${intl.formatMessage({ id: `order.meal.lunch` })}: ${intl.formatMessage({
          id: `order.mealType.${lunch ?? none}.title`,
        })} ${lunch_amount && lunch_amount > 1 ? 'x2' : ''}`,
        start: moment(date).add(currentTime, 'hours').toDate(),
        end: moment(date)
          .add(currentTime + 2, 'hours')
          .toDate(),
        resource: { mealCategory: lunch ?? none, status, meal: Meal.Lunch, date, order },
      });
      currentTime += 2;
    }

    if (group?.is_afternoon_meal_enabled === null || group?.is_afternoon_meal_enabled) {
      events.push({
        title: `${intl.formatMessage({ id: `order.meal.afternoon_meal` })}: ${intl.formatMessage({
          id: `order.mealType.${afternoon_meal ?? none}.title`,
        })} ${afternoon_meal_amount && afternoon_meal_amount > 1 ? 'x2' : ''}`,
        start: moment(date).add(currentTime, 'hours').toDate(),
        end: moment(date)
          .add(currentTime + 2, 'hours')
          .toDate(),
        resource: {
          mealCategory: afternoon_meal ?? none,
          status,
          meal: Meal.Afternoon_meal,
          date,
          order,
        },
      });
      currentTime += 2;
    }
  });

  const desktopButton = (
    <Box marginLeft="auto" display={['none', 'block', 'block']}>
      <Button
        padding="10px 12px"
        margin="0 17px 0 0"
        onClick={showModal}
        border={`1px solid ${colors.yellow}`}
      >
        <Text color={colors.darkGreen}>{intl.formatMessage({ id: 'calendar.title' })}</Text>
      </Button>
    </Box>
  );

  const mobileButton = (
    <Box marginLeft="auto" display={['block', 'none', 'none']}>
      <Box
        backgroundColor={colors.lightGrey}
        width="100%"
        sx={{
          borderRadius: '10px',
          marginTop: '10px',
          div: {
            padding: '9px 0 9px 15px',
          },
        }}
        onClick={showModal}
      >
        <Text>{intl.formatMessage({ id: 'calendar.title' })}</Text>
      </Box>
    </Box>
  );

  return (
    <>
      {desktopButton}
      {mobileButton}
      <Modal
        title={intl.formatMessage({ id: 'calendar.title' })}
        open={isModalVisible}
        width="100%"
        style={{ top: 20 }}
        onCancel={handleCancel}
        footer={[]}
      >
        <Box sx={{ '.rbc-event-label': { display: 'none' } }}>
          <h2>{intl.formatMessage({ id: 'calendar.description' })}</h2>
          <Calendar
            localizer={localizer}
            events={events}
            components={{
              work_week: {
                event: (props) => {
                  // @ts-ignore
                  const { meal, status, date, order } = props.event.resource;
                  return (
                    <div>
                      {props.title}
                      {isPaymentEnabled && (
                        <PaymentStatusTag meal={meal} status={status} date={date} order={order} />
                      )}
                    </div>
                  );
                },
              },
              month: {
                event: (props) => {
                  // @ts-ignore
                  const { meal, status, date, order } = props.event.resource;
                  return (
                    <div>
                      {isPaymentEnabled && (
                        <PaymentStatusTag meal={meal} status={status} date={date} order={order} />
                      )}
                      {props.title}
                    </div>
                  );
                },
              },
            }}
            startAccessor="start"
            endAccessor="end"
            defaultView="work_week"
            views={['month', 'work_week']}
            style={{ height: mealsLength > 2 ? 900 : 700, marginTop: '20px' }}
            className="calendar"
            defaultDate={
              momentBusinessDay().isBusinessDay()
                ? momentBusinessDay().toDate()
                : momentBusinessDay().nextBusinessDay().toDate()
            }
            messages={{
              next: intl.formatMessage({ id: 'calendar.messages.next' }),
              previous: intl.formatMessage({ id: 'calendar.messages.previous' }),
              today: intl.formatMessage({ id: 'calendar.messages.today' }),
              month: intl.formatMessage({ id: 'calendar.messages.month' }),
              work_week: intl.formatMessage({ id: 'calendar.messages.work_week' }),
            }}
            min={new Date(0, 0, 0, 7, 0, 0)}
            max={new Date(0, 0, 0, 15, 0, 0)}
            eventPropGetter={(event) => {
              // @ts-ignore
              if (event.resource.mealCategory === 'default') {
                return { style: { backgroundColor: colors.white, color: colors.brightGreen } };
              }
              if (
                // @ts-ignore
                event.resource.mealCategory === 'allergy' ||
                // @ts-ignore
                event.resource.mealCategory === 'vegetarian'
              ) {
                return {
                  style: { backgroundColor: colors.white, color: colors.yellow },
                };
              }
              // @ts-ignore
              if (event.resource.mealCategory === 'none') {
                return { style: { backgroundColor: colors.white, color: colors.red } };
              }
              return { style: { backgroundColor: colors.white, color: colors.lightGreen } };
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CalendarModal;
