import React from 'react';
import { Text, Image, Flex, Heading } from 'rebass';
import { Layout } from 'antd';
import colors from '../../../Theme/Colors';
import { BusinessLunchItem } from '../BusinessLunch.types';
import { getImageByCategory } from '../helpers/getImageByCategory';

const { Sider } = Layout;

interface Props {
  categories: BusinessLunchItem[];
  date: string;
}

const SideMenu = ({ categories, date }: Props) => {
  return (
    <Sider
      style={{
        backgroundColor: colors.white,
        padding: '10px',
        width: '20%',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <Heading margin="10px 0 12px 10px">Užsakymas {date} datai</Heading>
      {categories?.map((category) => {
        return (
          <a href={`#${category?.display_category}`} key={category?.id}>
            <Flex
              key={category?.id}
              sx={{
                padding: '10px',
                margin: '20px 0',
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
                borderRadius: '10px',
              }}
            >
              <Image src={getImageByCategory(category?.category)} width="20px" marginRight="10px" />
              <Text color="black">{category?.display_category}</Text>
            </Flex>
          </a>
        );
      })}
    </Sider>
  );
};

export default SideMenu;
