import { ThunkDispatch as Dispatch } from 'redux-thunk';
import axios from 'axios';

import * as constants from '../constants/order.constants';
import { Order } from '../../abstractions/Order';

import Config from '../../Config';
import { Moment } from 'moment';
import { User } from '../../abstractions/User';
import { MealCategory } from '../../abstractions/UserSettings';
import { DayOrderMenu } from '../../abstractions/Menu';
import { normalizeOrders } from '../../helpers/order/normalizeOrders';

const baseUrl = Config;

export interface FetchOrdersSuccess {
  type: constants.FetchOrdersSuccess;
  orders: Order[];
}

function fetchOrdersSuccess(orders: Order[]): FetchOrdersSuccess {
  return {
    type: constants.FETCH_ORDERS_SUCCESS,
    orders,
  };
}

export interface GetOrderMenuSuccess {
  type: constants.GetOrderMenuSuccess;
  orderMenu: DayOrderMenu;
}

function getOrderMenuSuccess(orderMenu: DayOrderMenu): GetOrderMenuSuccess {
  return {
    type: constants.GET_ORDER_MENU_SUCCESS,
    orderMenu,
  };
}

export interface ClearOrderSuccess {
  type: constants.ClearOrderSuccess;
}

function clearOrdersSuccess(): ClearOrderSuccess {
  return {
    type: constants.CLEAR_ORDER_SUCCESS,
  };
}

export interface PostOrdersRequest {
  type: constants.PostOrdersRequest;
}

function postOrdersRequest(): PostOrdersRequest {
  return {
    type: constants.POST_ORDERS_REQUEST,
  };
}
export interface PostOrderSuccess {
  type: constants.PostOrderSuccess;
  orders: Order[];
}

function postOrderSuccess(orders: Order[]): PostOrderSuccess {
  return {
    type: constants.POST_ORDER_SUCCESS,
    orders,
  };
}

export interface OrderError {
  type: constants.OrderError;
  error: Error;
}

function orderErrpr(error: Error): OrderError {
  return {
    type: constants.ORDER_ERROR,
    error,
  };
}

export function getOrders() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.get(`${baseUrl}/orders`);

      dispatch(fetchOrdersSuccess(response.data));
    } catch (error) {
      dispatch(orderErrpr(error.response.data));
    }
  };
}

export function getMenuByDate(date: Moment, user: User, category: MealCategory) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    try {
      const response = await axios.post(`${baseUrl}/menu/resolve`, {
        date: date.format('YYYY-MM-DD'),
        userId: user?.id,
        schoolId: user?.group?.school?.id,
        category,
      });

      dispatch(getOrderMenuSuccess(response.data));
    } catch (error) {
      dispatch(orderErrpr(error.response.data));
    }
  };
}

export function postOrder(orders: Order[]) {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(postOrdersRequest());

    try {
      const response = await axios.put(`${baseUrl}/orders`, { orders: normalizeOrders(orders) });
      dispatch(postOrderSuccess(response.data));
    } catch (error) {
      dispatch(orderErrpr(error.response?.data || error?.response));
    }
  };
}

export function resetOrders() {
  return async (dispatch: Dispatch<any, unknown, any>) => {
    dispatch(clearOrdersSuccess());
  };
}
