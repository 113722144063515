import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Heading } from 'rebass';
import { AppState } from '../../../redux/reducers/index.reducer';
import Spin from 'antd/lib/spin';
import { FormattedMessage } from 'react-intl';
import colors from '../../../Theme/Colors';
import { MealCategory } from '../../../abstractions/UserSettings';
import theme from '../../../Theme/Theme';
import { Meal } from '../../../abstractions/Meal';
import Text from '../../../common/Text/Text';
import { Badge } from 'antd';
import { StopOutlined } from '@ant-design/icons/lib/icons';

interface Props {
  isChecked: boolean;
  type: any;
  selectedMealType: any;
  onClick: () => void;
}

const CardHeader = ({ isChecked, type, selectedMealType, onClick }: Props) => {
  const pricing = useSelector((state: AppState) => state.pricingReducer.pricing);
  const child = useSelector((state: AppState) => state.authReducer.child);
  const isPaymentEnabled = child?.group?.school?.isPaymentEnabled;
  const [isCardLoading, setIsCardLoading] = React.useState(false);
  const isOrdersLoading = useSelector((state: AppState) => state.orderReducer.isLoading);
  const isCartLoading = useSelector((state: AppState) => state.cartReducer.isLoading);
  const allergyMenu = useSelector((state: AppState) => state.orderReducer.orderAllergyMenu);

  const allergyDisabled = !allergyMenu && type === MealCategory.Allergy;
  // @ts-ignore
  const pricingObj = pricing?.[selectedMealType?.name]?.[type];
  const price = (pricingObj?.price / 100).toLocaleString('lt-LT', {
    style: 'currency',
    currency: 'EUR',
  });
  const govDiscountedPrice = (pricingObj?.gov_discounted_price / 100).toLocaleString('lt-LT', {
    style: 'currency',
    currency: 'EUR',
  });

  React.useEffect(() => {
    if (!isOrdersLoading) setIsCardLoading(false);
  }, [isOrdersLoading]);

  React.useEffect(() => {
    if (!isCartLoading && isPaymentEnabled) setIsCardLoading(false);
  }, [isCartLoading, isPaymentEnabled]);

  const displayedCardPrice = () => {
    if (isPaymentEnabled && pricing) {
      if (
        selectedMealType.name === Meal.Lunch &&
        type !== MealCategory.None &&
        child?.group?.gov_discount_applies
      ) {
        return (
          <Box margin="20px 12px 0 auto">
            <Box
              sx={{
                '.ant-badge-count': {
                  boxShadow: '0 0 0 0',
                },
              }}
            >
              <Badge count={govDiscountedPrice} />
            </Box>
            <s style={{ color: isChecked ? 'white' : 'black' }}>
              <Text
                color={cardHeaderStyle.headerColor}
                margin="3px 0 0 10px"
                weight="bold"
                size="12px"
                sx={{ opacity: '0.6' }}
              >
                {type !== 'none' ? price : <></>}
              </Text>
            </s>
          </Box>
        );
      }
      return (
        <Text color={cardHeaderStyle.headerColor} margin="0 12px 0 auto" weight="bold" size="16px">
          {/* @ts-ignore */}
          {type !== 'none' ? price : <></>}
        </Text>
      );
    }
    return (
      <Box width={35}>
        <Spin />
      </Box>
    );
  };

  const cardHeaderStyle = {
    backgroundColor: colors.grey,
    headerColor: colors.darkGreen,
  };

  if (type === MealCategory.None) {
    cardHeaderStyle.backgroundColor = colors.red;
    cardHeaderStyle.headerColor = colors.white;
  } else if (isChecked) {
    cardHeaderStyle.backgroundColor = colors.brightGreen;
    cardHeaderStyle.headerColor = colors.white;
  }

  const checkbox = allergyDisabled ? (
    <StopOutlined style={{ fontSize: 24, marginRight: 10 }} />
  ) : (
    <label className="container">
      <input type="checkbox" onChange={() => {}} checked={isChecked} />
      <span className="checkmark"></span>
    </label>
  );

  return (
    <Box
      onClick={() => {
        if (!allergyDisabled) {
          setIsCardLoading(true);
          return onClick();
        }
      }}
      sx={{ cursor: allergyDisabled ? 'not-allowed' : 'pointer' }}
    >
      <Flex
        height={71}
        backgroundColor={cardHeaderStyle.backgroundColor}
        sx={{
          alignItems: 'center',
          borderRadius: '16px 16px 0 0',
          paddingLeft: '19px',
          input: {
            marginRight: '19px',
          },
        }}
      >
        {(isOrdersLoading || isCartLoading) && isCardLoading ? (
          <Box width={35}>
            <Spin />
          </Box>
        ) : (
          checkbox
        )}
        <Heading
          color={cardHeaderStyle.headerColor}
          fontFamily={theme.fonts.headingBold}
          fontSize="16px"
        >
          <FormattedMessage id={`order.mealType.${type}.title`} />
        </Heading>
        {isPaymentEnabled && displayedCardPrice()}
      </Flex>
    </Box>
  );
};

export default CardHeader;
