import { AdminDateOrder } from '../../abstractions/Order';
import {
  FETCH_ORDERS_SUCCESS,
  POST_ADMIN_ORDERS_REQUEST,
  POST_ADMIN_ORDER_SUCCESS,
  POST_ADMIN_ORDERS_SUCCESS,
  ADMIN_ORDER_ERROR,
  CLEAR_ORDER_SUCCESS,
  GET_ORDER_MENU_SUCCESS,
} from '../constants/adminOrder.constants';
import {
  FetchOrdersSuccess,
  PostOrderSuccess,
  PostOrdersSuccess,
  AdminOrderError,
  ClearOrderSuccess,
  GetOrderMenuSuccess,
  PostOrdersRequest,
} from '../actions/adminOrder.actions';
import alertError from '../../util/alertError';
import alertSuccess from '../../util/alertSuccess';
import { DayOrderMenuItem } from '../../abstractions/Menu';
import { MealCategory } from '../../abstractions/UserSettings';
import { appIntl } from '../../helpers/Intl/IntlGlobalProvider';

export interface OrderReducer {
  orders: AdminDateOrder[] | null;
  alertMessage: string | null;
  orderDefaultMenu: DayOrderMenuItem[] | null;
  orderVegetarianMenu: DayOrderMenuItem[] | null;
  isLoading: boolean;
}

export default function orderReducer(
  state: OrderReducer = {
    orders: null,
    alertMessage: null,
    orderDefaultMenu: null,
    orderVegetarianMenu: null,
    isLoading: false,
  },
  action:
    | FetchOrdersSuccess
    | PostOrderSuccess
    | PostOrdersRequest
    | PostOrdersSuccess
    | GetOrderMenuSuccess
    | ClearOrderSuccess
    | AdminOrderError,
): OrderReducer {
  switch (action.type) {
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.orders,
        isLoading: false,
      };
    case POST_ADMIN_ORDERS_REQUEST:
      return { ...state, isLoading: true };
    case POST_ADMIN_ORDER_SUCCESS: {
      if (state.orders) {
        const date = action.date.format('YYYY-MM-DD');
        const actionDateOrders = state.orders[date as any];

        if (actionDateOrders) {
          // @ts-ignore
          const updatedOrders = actionDateOrders.map((order) => {
            if (order.userId === action.order.userId) {
              return { ...action.order, name: order.name, surname: order.surname };
            }
            return order;
          });

          alertSuccess({
            message: 'Užsakymas patvirtintas',
            description: 'Užsakymų suvestinę galite rasti puslapio apačioje.',
            duration: 1.5,
          });

          return {
            ...state,
            orders: {
              ...state.orders,
              [date]: updatedOrders,
            },
            isLoading: false,
          };
        }
      }

      return {
        ...state,
        isLoading: false,
      };
    }
    case POST_ADMIN_ORDERS_SUCCESS: {
      if (state.orders) {
        const date = action.date.format('YYYY-MM-DD');
        const actionDateOrders = state.orders[date as any];
        const updatedOrders = (actionDateOrders[date] = action.orders);

        alertSuccess({
          message: 'Užsakymas patvirtintas',
          description: 'Užsakymų suvestinę galite rasti puslapio apačioje.',
          duration: 1.5,
        });

        return {
          ...state,
          orders: {
            ...state.orders,
            [date]: updatedOrders,
          },
          isLoading: false,
        };
      }

      return { ...state, isLoading: false };
    }
    case GET_ORDER_MENU_SUCCESS: {
      if (action?.orderMenu?.menu_meta?.category === MealCategory.Vegetarian) {
        return { ...state, orderVegetarianMenu: action.orderMenu?.items, isLoading: false };
      }
      return { ...state, orderDefaultMenu: action.orderMenu?.items, isLoading: false };
    }
    case CLEAR_ORDER_SUCCESS: {
      return { ...state, orders: null, isLoading: false };
    }
    case ADMIN_ORDER_ERROR: {
      if (
        action.error.message === 'Selection can be edited until 9 AM' ||
        action.error.message === 'Selection can be made until 9 AM'
      ) {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.selectionEdit.title' }),
          description: appIntl().formatMessage({ id: 'order.error.selectionEdit.description' }),
          duration: 5,
        });
      } else if (action.error.message === 'Breakfast / snack selection can be edited until 9 AM') {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.breakfastEdit.title' }),
          description: appIntl().formatMessage({ id: 'order.error.breakfastEdit.description' }),
          duration: 5,
        });
      } else if (action.error.message === 'Breakfast / snack selection can be edited until 4 AM') {
        alertError({
          message: appIntl().formatMessage({ id: 'order.error.todayBreakfastEdit.title' }),
          description: appIntl().formatMessage({
            id: 'order.error.todayBreakfastEdit.description',
          }),
          duration: 5,
        });
      } else if (action.error.message === 'Can not create orders for past days') {
        alertError({
          message: 'Užsakymo klaida',
          description: 'Negalima kurti užsakymų praeityje',
          duration: 5,
        });
      } else {
        alertError({
          message: appIntl().formatMessage({ id: 'error.title' }),
          description: appIntl().formatMessage({ id: 'error.description' }),
          duration: 5,
        });
      }

      return { ...state, isLoading: false };
    }
    default:
      return { ...state, isLoading: false };
  }
}
