const colors = {
  mintGreen: '#7FD3B8',
  darkGreen: '#1A3B3A',
  red: '#E67C83',
  lightRed: '#F9E7E8',
  brightGreen: '#A0D185',
  lightGreen: '#EFFFE7',
  lightYellow: '#FBC84B7D',
  veryLightYellow: '#FBC84B33',
  yellow: '#FBC84B',
  grey: '#EAEAEA',
  lightGrey: '#F8F8F8',
  darkHaki: '#909F9A',
  purple: '#673AFF',
  purpleo25: 'rgba(103, 58, 255, 0.25)',
  purpleDark: '#6224FF',
  white: '#ffffff',
  black14: '#222326',
  black14o9: 'rgba(34, 35, 38, 0.9)',
  black16: '#27282C',
  black16o3: 'rgba(39, 40, 44, .3)',
  black20: '#303236',
  black20o5: 'rgba(48, 50, 54, .5)',
  black20o4: 'rgba(48, 50, 54, .4)',
  black20Hover: '#2D2F33',
  black23: '#373A3E',
  black26: '#3E4146',
  black26Hover: '#373A3F',
  black30: '#3E4146',
  black30o5: 'rgba(72, 74, 81, 0.5)',
  grey50: '#7C7D83',
  grey75: '#BEBEC1',
  grey92: '#EAEAEA',
  grey92o1: 'rgba(234, 234, 235, 0.1)',
  grey92o5: 'rgba(234, 234, 235, 0.5)',
  grey92o6: 'rgba(234, 234, 235, 0.6)',
  grey92o7: 'rgba(234, 234, 235, 0.7)',
  danger: '#EB0E0E',
  dangerHover: '#E00B0B',
  secondaryRed: '#CA4365',
  secondaryYellow: '#D18A4C',
  alertGreen: '#1BD788',
};

export default colors;
