import { Button, Checkbox, Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { BusinessDeal, BusinessLunchItem } from '../BusinessLunch.types';
import { formatToCurrency } from '../helpers/formatToCurrency';
import { getImageByCategory } from '../helpers/getImageByCategory';

interface Props {
  item: BusinessLunchItem | undefined;
  soups: BusinessLunchItem[];
  cart: BusinessLunchItem[];
  businessDeal: BusinessDeal | undefined;
  addItem: (item: BusinessLunchItem) => void;
  clearItem: (item?: BusinessLunchItem) => void;
}

const OptionsModal = ({ item, soups, cart, businessDeal, addItem, clearItem }: Props) => {
  // const [selectedOptions, setSelectedOptions] = React.useState<BusinessLunchItemOption[]>([]);
  const [selectedSoup, setSelectedSoup] = useState<BusinessLunchItem>();

  const clearState = () => {
    // setSelectedOptions([]);
    setSelectedSoup(undefined);
  };

  const getItemOrComboPrice = (item: BusinessLunchItem | undefined) => {
    if (item?.category === 'main' && selectedSoup) {
      return businessDeal?.rule?.price || 0;
    }
    return item?.price || 0;
  };

  return (
    <Modal
      title={item?.name}
      open={!!item}
      onCancel={() => {
        clearState();
        clearItem();
      }}
      style={{ borderRadius: '10px', overflow: 'auto' }}
      footer={[
        <Fragment key={'key'}>
          <Flex>
            <strong>
              Kiekis:
              {` ${cart?.filter((cartItem) => cartItem?.id === item?.id).length}`}
            </strong>
          </Flex>
          <Button
            key="remove"
            onClick={() => {
              if (item) {
                addItem({ ...item });
                clearItem(item);
                clearState();
              }
            }}
          >
            <strong>Išimti</strong>
          </Button>
          <Button
            key="add"
            onClick={() => {
              if (item) {
                addItem({ ...item });
                if (selectedSoup) addItem(selectedSoup);
                clearItem();
                clearState();
              }
            }}
          >
            <strong>Pridėti {formatToCurrency(getItemOrComboPrice(item))}</strong>
          </Button>
        </Fragment>,
      ]}
    >
      {!!item?.category && (
        <Image src={getImageByCategory(item?.category)} width="40px" marginRight="10px" />
      )}
      {
        <>
          {(item?.category === 'main' || item?.category === 'special_main') &&
            (item?.options?.length || 0) > 0 && (
              <>
                <Box>
                  <strong>Garnyrai</strong>
                </Box>
                <ul style={{ paddingLeft: '20px' }}>
                  {item?.options?.map((option) => {
                    return (
                      <Flex key={option.id}>
                        <li>
                          <Text>{option.name}</Text>
                        </li>
                        {/* <Checkbox
                        onChange={() => {
                          if (
                            selectedOptions?.find(
                              (selectedOption) => selectedOption?.id === option?.id,
                            )
                          ) {
                            return setSelectedOptions((prev) =>
                              prev?.filter((selectedOption) => selectedOption?.id !== option?.id),
                            );
                          }
                          return setSelectedOptions((prev) => [...prev, option]);
                        }}
                        checked={
                          !!selectedOptions?.find(
                            (selectedOption) => selectedOption?.id === option?.id,
                          )
                        }
                        disabled={
                          selectedOptions?.length >= 2 &&
                          !selectedOptions?.find(
                            (selectedOption) => selectedOption?.id === option?.id,
                          )
                        }
                      >
                        {option?.name}
                      </Checkbox> */}
                      </Flex>
                    );
                  })}
                </ul>
              </>
            )}
          {item?.category === 'main' && (
            <>
              <Box marginTop={20}>
                <strong>Sriuba + Pagrindinis patiekalas už mažesnę kainą</strong>
              </Box>
              {soups?.map((soup) => {
                return (
                  <Box key={soup.id}>
                    <Checkbox
                      onChange={() => {
                        if (selectedSoup?.id === soup?.id) {
                          return setSelectedSoup(undefined);
                        }
                        return setSelectedSoup(soup);
                      }}
                      checked={selectedSoup?.id === soup?.id}
                      disabled={selectedSoup && selectedSoup?.id !== soup?.id}
                    >
                      {soup?.name}
                    </Checkbox>
                  </Box>
                );
              })}
            </>
          )}
        </>
      }
    </Modal>
  );
};

export default OptionsModal;
