import React, { useEffect, useState } from 'react';
import { Modal, Button as AntdButton } from 'antd';
import { useIntl } from 'react-intl';
import { Box, Image } from 'rebass';
import { useHistory } from 'react-router-dom';

import decodePaymentToken from '../../../helpers/cart/decodePaymentToken';
import successIcon from '../../../images/svg/SuccessIcon.jpeg';
import colors from '../../../Theme/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/index.reducer';
import { getCart, resetCart } from '../../../redux/actions/cart.actions';
import { getOrders } from '../../../redux/actions/order.actions';
import { getChildInfo } from '../../../redux/actions/auth.actions';

const PaymentStatus = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const params = new URLSearchParams(history.location.search);
  const coveredByCredits = useSelector((state: AppState) => state.cartReducer.coveredByCredits);

  useEffect(() => {
    const paymentToken = params.get('order-token');
    const decoded = decodePaymentToken(paymentToken);
    if (decoded && (decoded as any).paymentStatus === 'PAID') {
      setIsOpen(true);
    } else if (coveredByCredits) {
      setIsOpen(true);
    }
  }, [params, coveredByCredits, dispatch]);

  const handleCloseModal = () => {
    dispatch(resetCart());
    dispatch(getCart());
    dispatch(getOrders());
    dispatch(getChildInfo());
    setIsOpen(false);
    history.push('/');
  };

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'payment.success.title' })}
        open={isOpen}
        width="90%"
        onCancel={handleCloseModal}
        footer={[]}
      >
        {
          <Box sx={{ textAlign: 'center' }}>
            <Image src={successIcon} width={60} margin="0 0 30px 0" />
            <h1 style={{ textAlign: 'center' }}>
              {intl.formatMessage({ id: 'payment.success.title' })}
            </h1>
            <h3 style={{ textAlign: 'center' }}>
              {intl.formatMessage({
                id: `payment.success.${
                  coveredByCredits ? 'descriptionAfterCredits' : 'descriptionAfterPayment'
                }`,
              })}
            </h3>
            <AntdButton
              onClick={handleCloseModal}
              type="primary"
              htmlType="submit"
              style={{
                borderColor: colors.secondaryRed,
                backgroundColor: colors.secondaryRed,
                borderRadius: '5px',
                color: colors.white,
                marginTop: '30px',
              }}
            >
              {intl.formatMessage({ id: 'payment.closeButton' })}
            </AntdButton>
          </Box>
        }
      </Modal>
    </>
  );
};

export default PaymentStatus;
