export const messages = {
  en: {
    login: {
      title: 'Login',
      username: 'Username',
      password: 'Password',
    },
    logout: 'Log out',
    order: {
      name: 'Order',
      title: 'Food ordering system',
      tab1: 'Order',
      tab2: 'Settings',
      status: {
        paid: 'Paid',
        pending: 'Pending',
        inCart: 'In cart',
      },
      error: {
        selectionEdit: {
          title: 'Order can not be performed',
          description: 'Only select food before 9 a.m.',
        },
        breakfastEdit: {
          title: 'Order can not be performed',
          description: 'Only until 9 a.m. you can choose breakfast/pre-lunch for the next day',
        },
        todayBreakfastEdit: {
          title: 'Order can not be performed',
          description: 'Only until 4 a.m. you can choose breakfast/pre-lunch for this day',
        },
      },
      postSuccess: {
        title: 'Order success',
        description: 'In Calendar section you can see order changes',
      },
      previousDayError: 'You can not select days before today',
      logout: 'Logout',
      description: {
        start: 'If you want to change or cancel ',
        breakfast: 'breakfast',
        snack: 'pre-lunch',
        lunch: 'lunch',
        afternoon_meal: 'dinner',
        middle: ' If you want to change or cancel',
        endEarlier: ' order, you should do it for the current day by 4:00 a.m.',
        endLater: ' order, you should do it for the current day by 9:00 a.m.',
      },
      selectMeal: {
        title: 'Change or cancel meal:',
        description1: {
          start: '1. Select the date; 2. Select meal (',
          breakfast: 'breakfast',
          snack: 'pre-lunch',
          lunch: 'lunch',
          afternoon_meal: 'dinner',
          end: ');',
        },
        description2:
          '3. Select meal type (for example "No food"); 4. You can see your changes on the "Calendar";',
      },
      meal: {
        breakfast: 'Breakfast',
        lunch: 'Lunch',
        afternoon_meal: 'Dinner',
        snack: 'Pre-lunch',
      },
      date: {
        nextDate: 'Next day',
        previousDate: 'Previous day',
      },
      settings: {
        title: 'Settings',
        header: 'Default order',
        save: 'Save',
        language: 'Language',
        defaultOrder:
          'Meals are ordered indefinitely. To cancel or change the daily order, go to Order tab. ',
        noMealsSelected: `Once you have selected and saved the default child meal order settings, you will be able to click on the button - Order.`,
      },
      mealType: {
        default: { title: 'Default' },
        vegetarian: {
          title: 'Vegetarian',
          description:
            'Vegetarian meals are made out of the same ingredients as the default meal except meat is replaced by vegetarian alternatives.',
        },
        allergy: {
          title: 'Allergic',
          description: `You can order food for an allergic child only after presenting a doctor's note to the dietitian of UAB "Desertas" and coordinating the child's menu.`,
        },
        none: {
          title: 'No food',
          description:
            'If you know the child will not attend for a few days or weeks, we suggest marking days of absence immediately.',
        },
        doublePortion: 'Double portion',
      },
      setAsDefault: {
        title: 'Quick order',
        tooltip: 'Your selected meal type will be saved indefinitely until you choose otherwise',
        button: 'Default selections',
        description: {
          start: 'Please select a type of meal for ',
          breakfast: 'breakfast',
          snack: 'pre-lunch',
          lunch: 'lunch',
          afternoon_meal: 'dinner',
          end: '*. Orders breakdown can be found on the "Calendar" section',
        },
        asterix: '*Meals are ordered indefinitely',
        success: {
          title: 'Food is ordered!',
          subtitle: 'Food is ordered indefinitely',
          ordersInCalendar: 'You can check all orders information in the "Calendar" section',
          goToOrderScreen: {
            title:
              'To make changes to your order or cancel meals on certain days, click the button below',
            button: 'Order changes',
          },
        },
      },
      alert: {
        languageSuccess: {
          message: 'Success!',
          description: 'Language has been changed successfully!',
        },
        settingsSuccess: {
          message: 'Food is ordererd indefinitely!',
          description: 'You can check on all order info in the "Calendar" section',
        },
        closeButton: 'Close',
      },
    },
    faq: {
      title: 'F.A.Q.',
      paymentsDisabled: {
        question1: {
          title: 'How to change meal order for a specific day?',
        },
        question2: {
          title: 'How to change meal orders for range or dates?',
        },
        question3: {
          title: 'How to see what is ordered and for which days?',
        },
        question4: {
          title: 'Why calendar shows 2 months ahead?',
          answer:
            'The calendar shows 2 months forward for technical reasons. However, your "Quick order" choices are created and saved indefinitely.',
        },
        question5: {
          title: 'How to cancel meals during vacation (eg for a week)?',
        },
        question6: {
          title: 'How to make an order indefinitely?',
        },
        question7: {
          title: 'How to order a double portion?',
        },
      },
      paymentsEnabled: {
        question1: {
          title: 'How to make an order (breakfast, lunch, dinner) and pay for it?',
        },
        question2: {
          title: 'How to cancel paid order',
        },
        question3: {
          title: 'What happens to the money when the order is cancelled?',
          answer:
            'The money is returned to you in credits. You can see the amount of available credits in the section called "Credits". Your available credits cover your next order.',
        },
        question4: {
          title: 'How to see what is ordered and for which days?',
          answer:
            'You can see this by clicking on the "Calendar" button that you can see in the top navigation bar or by opening the settings section of your phone.',
        },
        question5: {
          title: 'How to check payments history?',
          answer:
            'You can check your order history by clicking the "Order History" button. You will see a list of orders with the dates they were made.',
        },
        question6: {
          title: 'Why calendar shows 2 months ahead?',
          answer:
            'The calendar shows 2 months forward for technical reasons. However, your "Quick order" choices are created and saved indefinitely.',
        },
      },
    },
    calendar: {
      title: 'Calendar',
      description: 'Only 2 months of orders are displayed in the calendar',
      messages: {
        next: 'Next',
        previous: 'Previous',
        today: 'Today',
        month: 'Month',
        work_week: 'Week',
      },
    },
    datePicker: {
      from: 'Start',
      until: 'End',
    },
    cart: {
      title: 'Cart',
      credit: 'Credits',
      payNow: 'Pay now',
      confirm: 'Confirm',
      payLater: {
        title: 'Pay later',
        confirmOrder: 'Confirm Order',
        cancel: 'Cancel',
        creditsAfterOrder: 'Your credits amount after this order is confirmed:',
        description:
          'This functionality is intended for users who want to quickly order food and pay for the order later.',
        howItWorks:
          'You can order food with the click of a button, and the amount to be paid will be deducted from the number of credits you have.',
        important:
          'Important: In order to pay later, the amount of your credits after placing the order cannot be less than -10 euros.',
      },
      getInvoice: 'Want to get an invoice?',
      pricingError: 'Could not fetch pricing',
      locked: {
        title: 'Cart is locked due to unpaid order',
        unlock: 'Unlock',
      },
      unlocked: {
        title: 'Cart unlocked',
        description: 'You can now add new meals to the cart',
      },
      addedToCart: {
        title: 'Meal added to the cart!',
        description: 'You can check your changes in the "Calendar" section',
      },
      error: {
        locked: {
          title: 'Cart is locked',
          description: 'You can do any changes to the cart while it is locked',
        },
        notEnoughCredits: {
          title: 'Insufficient credits',
          description: `You can't pay later if you will have less credits than -10 EUR`,
        },
      },
    },
    payment: {
      success: {
        title: 'Operation successful',
        descriptionAfterPayment:
          'Thank you for using Ordero services. Your payment has been accepted.',
        descriptionAfterCredits:
          'Thank you for using Ordero services. Your credits has been accepted.',
      },
      error: {
        title: 'Operation was unsuccessful',
        description:
          'Payment failed. Please try again. If it still fails, write to support@ordero.lt',
      },
      closeButton: 'Close',
    },
    checkout: {
      title: 'Order overview',
      emptyCart: 'Cart is empty',
      discount: 'Discounts:',
      operationFee: 'Transaction fee:',
      partialTotal: 'Subtotal:',
      grandTotal: 'Grand total:',
      credits: 'Credits:',
      govermentCompensation: 'Compensation for preschoolers and first-second-graders',
      price: 'Price: ',
      amount: 'Amount: ',
      invoice: {
        buttonTitle: 'Invoice',
        title: 'Want to get an invoice?',
        description: `Then send a letter to the address: support@ordero.lt. Indicate the name and surname in the letter if
        you are buying as a person, or the name of the company and its code, if you are buying as a legal entity.
        It is also necessary to indicate in the email when was the payment made.`,
        important: 'Important! Invoices are issued only on the same day!',
      },
    },
    holiday: {
      title: 'Holiday',
      description: ' School is not working.',
    },
    invoices: {
      title: 'Payments history',
      empty: 'You have no completed payments',
    },
    error: {
      title: 'Error!',
      description:
        'Something went wrong. Please try again. If it still fails, write to support@ordero.lt',
    },
  },
  lt: {
    login: {
      title: 'Prisijungti',
      username: 'Vartotojo vardas',
      password: 'Password',
    },
    logout: 'Atsijungti',
    order: {
      name: 'Užsakymo informacija',
      title: 'Maitinimo užsakymo sistema',
      tab1: 'Užsakymas',
      tab2: 'Nustatymai',
      status: {
        paid: 'Apmokėta',
        pending: 'Laukiama',
        inCart: 'Krepšelyje',
      },
      error: {
        selectionEdit: {
          title: 'Negalima atlikti užsakymo',
          description: 'Pasirinkti maistą galima tik iki 9h ryto einamajai dienai',
        },
        breakfastEdit: {
          title: 'Negalima atlikti užsakymo',
          description: 'Tik iki 9 val. ryto galima pasirinkti pusryčius/priešpiečius kitai dienai',
        },
        todayBreakfastEdit: {
          title: 'Negalima atlikti užsakymo',
          description: 'Tik iki 4 val. ryto galima pasirinkti šiandienos pusryčius/priešpiečius',
        },
      },
      postSuccess: {
        title: 'Užsakymas užregistruotas',
        description: 'Sekcijoje "Kalendorius", galite matyti užsakymų išklotinę',
      },
      previousDayError: 'Negalima rinktis dienų ankstesnių nei šiandien',
      logout: 'Atsijungti',
      description: {
        start: 'Norėdami keisti ar atšaukti ',
        breakfast: 'pusryčių',
        snack: 'priešpiečių',
        lunch: 'pietų',
        afternoon_meal: 'pavakarių',
        middle: '. Norint keisti ar atšaukti ',
        endEarlier: ' užsakymą, tai galite padaryti einamajai dienai iki 4 val. ryto',
        endLater: ' užsakymą , tai galite padaryti einamajai dienai iki 9 val. ryto.'
      },
      selectMeal: {
        title: 'Pakeiskite arba atšaukite užsakymą:',
        description1: {
          start: '1. Pasirinkite datą; 2. Pasirinkite maitinimą (',
          breakfast: 'pusryčiai',
          snack: 'priešpiečiai',
          lunch: 'pietūs',
          afternoon_meal: 'pavakariai',
          end: ');',
        },
        description2:
          '3. Pasirinkite maitinimo tipą (pvz. "Be maitinimo"); 4. Pakeitimus galite matyti kalendoriuje;',
      },
      meal: {
        breakfast: 'Pusryčiai',
        snack: 'Priešpiečiai',
        lunch: 'Pietūs',
        afternoon_meal: 'Pavakariai',
      },
      date: {
        nextDate: 'Kita diena',
        previousDate: 'Praeita diena',
      },
      settings: {
        title: 'Nustatymai',
        header: 'Numatytasis užsakymas',
        save: 'Išsaugoti',
        language: 'Kalba',
        defaultOrder:
          'Maistas užsakomas neribotam laikui. Norint atšaukti ar pakeisti dienos užsakymą, spauskite ant mygtuko - Užsakymas. ',
        noMealsSelected:
          'Kai pasirinksite ir išsaugosite numatytuosius vaiko maitinimo nustatymus, galėsite paspausti ant atsiradusio mygtuko - Užsakymas. ',
      },
      mealType: {
        default: { title: 'Įprastas' },
        vegetarian: {
          title: 'Vegetariškas',
          description:
            'Vegetariški patiekalai susidaro iš tų pačių ingredientų kaip ir įprasti patiekalai, tačiau vietoj mėsos yra įdedami vegetariški produktai.',
        },
        allergy: {
          title: 'Alergiškas',
          description:
            'Maistą alergiškam vaikui galite užsakyti tik iš anksto pateikę gydytojo pažymą UAB "Desertas" dietistei ir suderinę vaiko valgiaraštį.',
        },
        none: {
          title: 'Be maitinimo',
          description:
            'Jeigu žinote, kad Jūsų vaikas neatvyks į ugdymo įstaigą kelias dienas arba savaites, siūlome pasirinkti `Be maitinimo` į priekį.',
        },
        doublePortion: 'Dviguba porcija',
      },
      setAsDefault: {
        title: 'Greitas užsakymas',
        tooltip:
          'Jūsų pasirinktas maitinimo tipas bus išsaugotas neribotam laikui, kol nepasirinksite kitaip',
        button: 'Greitas užsakymas',
        description: {
          start: 'Maloniai prašome pasirinkti ',
          breakfast: 'pusryčių',
          snack: 'priešpiečių',
          lunch: 'pietų',
          afternoon_meal: 'vakarienės',
          end: ' maitinimo tipą*.',
        },
        asterix: '*Maitinimo tipas užsakomas neribotam laikotarpiui.',
        success: {
          title: 'Maitinimas užsakytas!',
          subtitle: 'Maitinimas užsakytas neribotam laikui',
          ordersInCalendar: 'Užsakymus galite matyti skiltyje "Kalendorius"',
          goToOrderScreen: {
            title:
              'Norėdami atlikti užsakymo pakeitimus arba atšaukti maitinimą tam tikromis dienomis, spauskite žemiau esantį mygtuką',
            button: 'Užsakymo pakeitimai',
          },
        },
      },
      alert: {
        languageSuccess: {
          message: 'Kalba pakeista sėkmingai',
          description: 'Kalba pakeista sėkmingai',
        },
        settingsSuccess: {
          message: 'Maistas užsakytas pagal Jūsų pasirinkimus neribotam laikui!',
          description: 'Visus užsakymus galite matyti skiltyje "Kalendorius".',
        },
        closeButton: 'Close',
      },
    },
    faq: {
      title: 'D.U.K.',
      paymentsEnabled: {
        question1: {
          title: 'Kaip atlikti užsakymą (pusryčiai, pietūs, pavakariai) ir už jį sumokėti?',
        },
        question2: {
          title: 'Kaip atšaukti užsakytą ir apmokėtą užsakymą',
        },
        question3: {
          title: 'Kas atsitinka su pinigais kai užsakymas yra atšaukiamas?',
          answer:
            'Pinigai yra Jums grąžinami kreditais. Turimą kreditų sumą galite matyti sekcijoje pavadinimu "Kreditai". Jūsų turima kreditų suma padengia Jūsų sekantį užsakymą.',
        },
        question4: {
          title: 'Kaip pažiūrėti kas užsakyta ir kuriom dienom?',
          answer:
            'Tai galite pamatyti paspaudus mygtuką "Kalendorius", kurį matote viršutinėje navigacijos juostoje arba telefone atsidarius nustatymų sekciją.',
        },
        question5: {
          title: 'Kaip patikrinti mokėjimų istoriją?',
          answer:
            'Galite patikrinti mokėjimų istoriją spustelėdami "Mokėjimų istorija" mygtuką. Jūs matysite sąrašą mokėjimų su datomis, kada jie buvo atlikti.',
        },
        question6: {
          title: 'Kodėl kalendorius rodo 2 mėn. į priekį?',
          answer:
            'Kalendorius rodo 2 mėn. į priekį dėl techninių priežasčių. Tačiau jūsų numatytieji pasirinkimai yra sukurti ir išsaugoti neribotam laikui.',
        },
      },
      paymentsDisabled: {
        question1: {
          title: 'Kaip pakeisti maitinimo užsakymą tam tikrai dienai?',
        },
        question2: {
          title: 'Kaip pakeisti maitinimo užsakymus tam tikram periodui?',
        },
        question3: {
          title: 'Kaip pažiūrėti kas užsakyta ir kuriom dienom?',
        },
        question4: {
          title: 'Kodėl kalendorius rodo 2 mėn. į priekį?',
          answer:
            'Kalendorius rodo 2 mėn. į priekį dėl techninių priežasčių. Tačiau jūsų numatytieji pasirinkimai yra sukurti ir išsaugoti neribotam laikui.',
        },
        question5: {
          title: 'Kaip atšaukti maitinimą atostogų metu (pvz. savaitei)?',
        },
        question6: {
          title: 'Kaip atlikti užsakymą neribotam laikui?',
        },
        question7: {
          title: 'Kaip užsakyti dvigubą porciją?',
        },
      },
    },
    calendar: {
      title: 'Kalendorius',
      description: 'Kalendoriuje užsakymai rodomi 2 mėnesius į priekį',
      messages: {
        next: 'Kitas',
        previous: 'Ankstesnis',
        today: 'Šiandien',
        month: 'Mėnuo',
        work_week: 'Savaitė',
      },
    },
    datePicker: {
      from: 'Nuo',
      until: 'Iki',
    },
    cart: {
      title: 'Apmokėti krepšelį',
      credit: 'Kreditai',
      payNow: 'Apmokėti dabar',
      confirm: 'Patvirtinti',
      payLater: {
        title: 'Apmokėti vėliau',
        confirmOrder: 'Patvirtinti užsakymą',
        cancel: 'Atšaukti',
        creditsAfterOrder: 'Jūsų kreditų suma atlikus šį užsakymą:',
        description:
          'Šis funkcionalumas yra skirtas vartotojams, kurie nori greitai užsisakyti maistą ir apmokėti užsakymą vėliau.',
        howItWorks:
          'Galite užsisakyti maistą vieno mygtuko paspaudimu, o mokėtina suma išsiminusuos nuo Jūsų turimų kreditų skaičiaus.',
        important:
          'Svarbu: Norint mokėti vėliau, jūsų kreditų suma atlikus užsakymą negali būti mažesnė nei -10 eurų.',
      },
      getInvoice: 'Norite gauti sąskaitą faktūrą?',
      pricingError: 'Nepavyko gauti kainų',
      locked: {
        title: 'Krepšelis užrakintas dėl pradėto mokėjimo internetu',
        unlock: 'Atrakinti',
      },
      unlocked: {
        title: 'Krepšelis atrakintas',
        description: 'Galite keisti pasirinkimus',
      },
      addedToCart: {
        title: 'Pasirinkimas atnaujintas krepšelyje!',
        description: 'Sekcijoje "Kalendorius" galite matyti pasirinkimų išklotinę',
      },
      error: {
        locked: {
          title: 'Krepšelis užrakintas',
          description:
            'Negalima atlikti pakeitimų krepšelyje kol jis yra užrakintas dėl pradėto mokėjimo internetu',
        },
        notEnoughCredits: {
          title: 'Nepakanka kreditų',
          description: `Negalima mokėti vėliau jei kreditų likutis bus mažesnis nei -10 EUR`,
        },
      },
    },
    payment: {
      success: {
        title: 'Operacija sėkminga',
        descriptionAfterPayment:
          'Dėkojame, kad naudojatės Ordero paslaugomis. Jūsų mokėjimas priimtas.',
        descriptionAfterCredits:
          'Dėkojame, kad naudojatės Ordero paslaugomis. Jūsų kreditai priimti.',
      },
      error: {
        title: 'Operacija nesėkminga',
        description:
          'Mokėjimas nepavyko. Bandykite dar kartą. Jeigu vėl nepavyksta, parašykite į support@ordero.lt',
      },
      closeButton: 'Uždaryti',
    },
    checkout: {
      title: 'Užsakymo suvestinė',
      emptyCart: 'Jūsų krepšelis tuščias',
      discount: 'Nuolaidos:',
      operationFee: 'Operacijos mokestis:',
      partialTotal: 'Viso:',
      grandTotal: 'Iš viso:',
      credits: 'Kreditai:',
      govermentCompensation: 'Kompensacija priešmokyklinukams ir pirmokams-antrokams',
      price: 'Kaina:',
      amount: 'Kiekis:',
      invoice: {
        buttonTitle: 'Sąskaita faktūra',
        title: 'Norite išrašyti sąskaitą faktūrą?',
        description: `Tuomet siųskite laišką adresu: support@ordero.lt. Laiške nurodykite vardą ir pavardę jeigu
        perkate kaip fizinis asmuo, arba įmonės pavadinimą ir jos kodą, jeigu perkate kaip juridinis
        asmuo. Taip pat būtina nurodyti, kada buvo atliktas mokėjimas.`,
        important: 'Svarbu! Sąskaitos išrašomos tik tą pačią dieną.',
      },
    },
    holiday: {
      title: 'Atostogos',
      description: ' Ugdymo įstaiga nedirba.',
    },
    invoices: {
      title: 'Mokėjimų istorija',
      empty: 'Neturite atliktų mokėjimų',
    },
    error: {
      title: 'Klaida!',
      description:
        'Sistemos klaida! prašome atnaujinti puslapį. jeigu tai kartojasi, prašome susisiekti el. paštu: support@ordero.lt',
    },
  },
  ru: {
    login: {
      title: 'Войти',
      username: 'Эл. почта',
      password: 'Пароль',
    },
    logout: 'Выйти',
    order: {
      name: 'Информация заказа',
      title: 'Система заказа питания',
      tab1: 'Заказ',
      tab2: 'Настройки',
      status: {
        paid: 'Оплаченна',
        pending: 'Laukiama',
        inCart: 'В корзинке',
      },
      error: {
        selectionEdit: {
          title: 'Нет возможности разместить заказ',
          description: 'Только до 9 часов утром можно выбрать пищу на текущий день',
        },
        breakfastEdit: {
          title: ' Нет возможности разместить заказ',
          description: 'Только до 9 часов утром можно выбрать завтрак/перекус на следующий день',
        },
        todayBreakfastEdit: {
          title: 'Нет возможности разместить заказ',
          description: 'Только до 4 часов утром можно выбрать завтрак/перекус на сегодняшний день',
        },
      },
      postSuccess: {
        title: 'Заказ зарегистрирован',
        description: 'В отделе"Календарь", можно посмотреть список заказов',
      },
      previousDayError: 'Нельзя выбрать дни раньше, чем сегодня',
      logout: 'Отключить',
      description: {
        start: 'Чтобы изменить или отменить заказ ',
        breakfast: 'на завтрак',
        snack: 'перекус',
        lunch: 'обед',
        afternoon_meal: 'ужин',
        middle: ' Если вы хотите изменить или отменить ',
        endEarlier: ' заказ, вы должны сделать это на текущий день до 4:00 утра',
        endLater: ' заказ, вы должны сделать это на текущий день до 9:00 утра',
      },
      selectMeal: {
        title: 'Изменить или отменить заказ:',
        description1: {
          start: '1. Выбрать дату; 2. Выбрать питание (',
          breakfast: 'завтрак',
          snack: 'перекус',
          lunch: 'обед',
          afternoon_meal: 'ужин',
          end: ');',
        },
        description2:
          '3. Выбрать тип питания (pvz. "Без питания"); 4. Изменения видны в календаре;',
      },
      meal: {
        breakfast: 'Завтрак',
        snack: 'Перекус',
        lunch: 'Обед',
        afternoon_meal: 'Полдник',
      },
      date: {
        nextDate: 'Следующий день',
        previousDate: 'Прошлый день',
      },
      settings: {
        title: 'Настройки',
        header: 'Numatytasis užsakymas',
        save: 'Сохранить',
        language: 'Язык',
        defaultOrder:
          'Пища заказывается на неограниченное время. Чтобы отменить или изменить дневной заказ, нажмите на кнопку - Заказ. ',
        noMealsSelected:
          'После того, как выберите или сохраните настройки питания ребенка, сможете нажать на появившуюся кнопку - Заказ. ',
      },
      mealType: {
        default: {
          title: 'Обычный',
        },
        vegetarian: {
          title: 'Вегетарианский',
          description:
            'Вегетарианские блюда состоят из тех же ингредиентов, что и обычные блюда, но вместо мяса добавляются вегетарианские продукты.',
        },
        allergy: {
          title: 'Аллергический',
          description:
            'Заказать питание для ребенка-аллергика можно только после предъявления врача-диетолога ЗАО «Desertas» справки от врача и согласования детского меню.',
        },
        none: {
          title: 'Без питания',
          description:
            'Если вы знаете, что ваш ребенок будет отсутствовать в образовательном учреждение в течение нескольких дней или недель, предлагаем заранее выбрать «Без питания».',
        },
        doublePortion: 'Двойная порция',
      },
      setAsDefault: {
        title: 'Быстрый заказ',
        tooltip:
          'Выбранный вами тип питания будет сохранен на неограниченный срок, пока вы не выберете другое',
        button: 'Быстрый заказ',
        description: {
          start: 'Пожалуйста, выберите',
          breakfast: 'завтрак',
          snack: 'перекус',
          lunch: 'обед',
          afternoon_meal: 'полдник',
          end: 'тип питания*.',
        },
        asterix: '*Тип питания заказывается на неограниченный срок.',
        success: {
          title: 'Питание заказано!',
          subtitle: 'Питание заказано на неограниченный срок',
          ordersInCalendar: 'Заказы можно посмотреть в разделе "Календарь"',
          goToOrderScreen: {
            title:
              'Для изменения заказа или отмены питания в определенные дни, нажмите кнопку ниже',
            button: 'Изменения заказа',
          },
        },
      },
      alert: {
        languageSuccess: {
          message: 'заменены!',
          description: 'язык успешно изменен',
        },
        settingsSuccess: {
          message: 'Питание заказано по вашему выбору на неограниченный срок!',
          description: 'Все заказы можно посмотреть в разделе "Календарь".',
        },
        closeButton: 'Close',
      },
    },
    faq: {
      title: 'D.U.K.',
      paymentsEnabled: {
        question1: {
          title: 'Как сделать заказ (завтрак, обед, ужин) и оплатить его?',
        },
        question2: {
          title: 'Как отменить забронированный и оплаченный заказ',
        },
        question3: {
          title: 'Что происходит с деньгами при отмене заказа?',
          answer:
            'Деньги возвращаются вам кредитами. Вы можете увидеть количество доступных кредитов в разделе «Кредиты». Доступные кредиты покрывают ваш следующий заказ.',
        },
        question4: {
          title: 'Как посмотреть, что заказано и на какие дни?',
          answer:
            'Вы можете увидеть это, нажав на кнопку «Календарь», которую вы видите на верхней панели навигации, или открыв раздел настроек вашего телефона.',
        },
        question5: {
          title: 'Как проверить историю платежей?',
          answer:
            'Вы можете проверить свою историю платежей, нажав кнопку «История платежей». Вы увидите список платежей с датами их совершения.',
        },
        question6: {
          title: 'Почему календарь показывает только 2 месяца вперед?',
          answer:
            'Календарь показывает 2 месяца вперед по техническим причинам. Однако ваш выбор создан и сохранён на неограниченное срок.',
        },
      },
      paymentsDisabled: {
        question1: {
          title: 'Как изменить заказ питания на определенный день?',
        },
        question2: {
          title: 'Как изменить заказы питания на определенный период?',
        },
        question3: {
          title: 'Как посмотреть, что заказано и на какие дни?',
        },
        question4: {
          title: 'Почему календарь показывает только 2 месяца вперед?',
          answer:
            'Календарь показывает 2 месяца вперед по техническим причинам. Однако ваш выбор создан и сохранён на неограниченное срок.',
        },
        question5: {
          title: 'Как отменить питание во время отпуска (например, на неделю)?',
        },
        question6: {
          title: 'Как сделать заказ на неограниченное время?',
        },
        question7: {
          title: 'Как заказать двойную порцию?',
        },
      },
    },
    calendar: {
      title: 'Календарь',
      description: 'Заказы в календаре показываются 2 месяца вперед',
      messages: {
        next: 'Следующий',
        previous: 'Предыдущий',
        today: 'Сегодня',
        month: 'Месяц',
        work_week: 'Неделя',
      },
    },
    datePicker: {
      from: 'От',
      until: 'До',
    },
    cart: {
      title: 'Оплатить корзинку',
      credit: 'Кредиты',
      payNow: 'Заплатить сейчас',
      confirm: 'Подтвердить',
      payLater: {
        title: 'Оплатить позже',
        confirmOrder: 'Подтвердить заказ',
        cancel: 'Отмена',
        creditsAfterOrder: 'Сумма вашего кредита для этого заказа:',
        description:
          'Этот функционал предназначен для пользователей, которые хотят быстро заказать еду и оплатить заказ позже.',
        howItWorks:
          'Вы можете заказать еду одним нажатием кнопки, и сумма к оплате будет вычтена из количества ваших кредитов.',
        important:
          'Важно: Для того, чтобы оплатить позже, сумма ваших кредитов после оформления заказа не может быть меньше -10 евро.',
      },
      getInvoice: 'Хотите получить счет-фактуру?',
      pricingError: 'Не удалось получить цен',
      locked: {
        title: 'Корзинка закрыта из-за начатого платежа по интернету',
        unlock: 'Открыть',
      },
      unlocked: {
        title: 'Корзинка открыта',
        description: 'Можете менять выбор',
      },
      addedToCart: {
        title: 'Выбор обновлен в корзинке!',
        description: 'В отделе"Календарь" можно посмотреть список выборов',
      },
      error: {
        locked: {
          title: 'Корзинка закрыта',
          description:
            'Изменения не могут быть внесены в корзинку, пока она закрыта из-за начатого платежа по интернету',
        },
        notEnoughCredits: {
          title: 'Недостаточно кредитов',
          description: 'Вы не можете заплатить позже, если остаток на кредите меньше -10 EUR',
        },
      },
    },
    payment: {
      success: {
        title: 'Операция успешная',
        descriptionAfterPayment: 'Благодарим за использование услуг Ордэро. Ваш платеж принят.',
        descriptionAfterCredits: 'Благодарим за использование услуг Ордэро. Ваши кредиты приняты.',
      },
      error: {
        title: 'Операция не удалась',
        description:
          'Платеж не удался. Попробуйте еще раз. Если снова не получится, напишите на support@ordero.lt',
      },
      closeButton: 'Закрыть',
    },
    checkout: {
      title: 'Отчет заказа',
      emptyCart: 'Ваша корзинка пустая',
      discount: 'Скидки:',
      operationFee: 'Платеж за операцию:',
      partialTotal: 'Общий:',
      grandTotal: 'Всего:',
      credits: 'Кредиты:',
      govermentCompensation: 'Компенсация для дошкольников и первоклассников – второклассников',
      price: 'Цена:',
      amount: 'Количество:',
      invoice: {
        buttonTitle: 'Счет-фактура',
        title: 'Хотите выписать счет-фактуру?',
        description:
          'Тогда отправьте письмо по адресу: support@ordero.lt. В письме укажите имя и фамилию, если вы покупаете как физическое лицо, или название компании и ее код, если вы покупаете как юридическое лицо. Также необходимо указать, когда была произведена оплата.',
        important: 'Важно! Счета выставляются только в тот же день.',
      },
    },
    holiday: {
      title: 'Отпуск',
      description: ' Образовательное учреждение закрыто.',
    },
    invoices: {
      title: 'История платежей',
      empty: 'У вас нет завершенных платежей',
    },
    error: {
      title: 'Ошибка!',
      description:
        'Ошибка системы! Пожалуйста, обновите страницу. Если это повторяется, пожалуйста, свяжитесь с нами по электронной почте: support@ordero.lt',
    },
  },
};
