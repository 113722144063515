import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { useHistory } from 'react-router';
import { AppState } from '../redux/reducers/index.reducer';
import LoginPage from '../views/Login/LoginPage';

export interface LogInRouteProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const LoggedInRoute = (props: LogInRouteProps): ReactElement => {
  const user = useSelector((state: AppState) => state.authReducer.user);
  const history = useHistory();

  if (!user) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // <Redirect to="/login" />;
    history.push('/login');
    return <Route component={() => <LoginPage />} />;
  }

  return (
    <Route
      component={() => {
        const componentProps = props as any;
        return <componentProps.component />;
      }}
    />
  );
};

export default LoggedInRoute;
