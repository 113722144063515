import React, { useEffect, useState } from 'react';
import { Box, Flex, Image } from 'rebass';
import colors from '../../../Theme/Colors';
import { User } from '../../../abstractions/User';
import Text from '../../../common/Text/Text';
import { Dropdown, MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../redux/actions/settings.actions';
import { CloseCircleOutlined } from '@ant-design/icons';

import logo from '../../../images/svg/logo.svg';
import logout from '../../../images/svg/logout.svg';
import dropdown from '../../../images/svg/dropdown.svg';
import { AppState } from '../../../redux/reducers/index.reducer';
import { useIntl } from 'react-intl';
import FaqModal from './FaqModal/FaqModal';
import CalendarModal from './CalendarModal/CalendarModal';
import DefaultSelectionsModal from './DefaultSelectionsModal/DefaultSelectionsModal';
import { useHistory } from 'react-router-dom';
import { logOut } from '../../../redux/actions/auth.actions';
import { resetCart } from '../../../redux/actions/cart.actions';
import { resetOrders } from '../../../redux/actions/order.actions';
import { resetPricing } from '../../../redux/actions/pricing.actions';
// import PaymentsHistoryModal from '../../PaymentsHistoryModal/PaymentsHistoryModal';

interface Props {
  child: User;
}

const NavBar = (props: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const settings = useSelector((state: AppState) => state.authReducer.child)?.user_settings;
  const isPaymentEnabled = useSelector((state: AppState) => state.authReducer.child)?.group?.school
    ?.isPaymentEnabled;
  const [defaultSelectionsModalVisible, setDefaultSelectionsModalVisible] = useState(false);

  const onLogoutClick = () => {
    dispatch(resetOrders());
    dispatch(resetPricing());
    dispatch(resetCart());
    dispatch(logOut());
  };

  useEffect(() => {
    const { is_breakfast_enabled, is_snack_enabled, is_lunch_enabled, is_afternoon_meal_enabled } =
      group;

    if (
      settings &&
      (is_breakfast_enabled === null || is_breakfast_enabled
        ? settings.breakfast === 'none'
        : true) &&
      (is_snack_enabled === null || is_snack_enabled ? settings.snack === 'none' : true) &&
      (is_lunch_enabled === null || is_lunch_enabled ? settings.lunch === 'none' : true) &&
      (is_afternoon_meal_enabled === null || is_afternoon_meal_enabled
        ? settings.afternoon_meal === 'none'
        : true)
    ) {
      setDefaultSelectionsModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    child: {
      name,
      surname,
      group,
      user_settings,
      user_settings: { language },
    },
  } = props;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuClick = ({ key }: any) => {
    dispatch(changeLanguage({ ...user_settings, language: `${key}` }));
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'en',
      label: <div>EN</div>,
    },
    {
      key: 'lt',
      label: <div>LT</div>,
    },
    {
      key: 'ru',
      label: <div>RU</div>,
    },
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const desktopMenu = (
    <Box marginLeft="auto" display={['none', 'block', 'block']}>
      <Flex alignItems="center">
        {!isPaymentEnabled ? (
          <DefaultSelectionsModal
            isVisible={defaultSelectionsModalVisible}
            onShowModal={(isVisible) => {
              setDefaultSelectionsModalVisible(isVisible);
            }}
          />
        ) : (
          // <PaymentsHistoryModal />
          <></>
        )}
        <CalendarModal />
        <FaqModal />
        <Text>{group.school.name}</Text>
        <Box marginLeft="18px" sx={{ cursor: 'pointer' }}>
          <Dropdown menu={menuProps} trigger={['click']}>
            <Flex>
              <Text textTransform="uppercase" weight="700" size="14px">
                {language}
              </Text>
              <Image src={dropdown} marginLeft="22px" />
            </Flex>
          </Dropdown>
        </Box>
        <Image src={logout} onClick={onLogoutClick} marginLeft="30px" sx={{ cursor: 'pointer' }} />
      </Flex>
    </Box>
  );

  const mobileMenu = (
    <Box
      marginLeft="auto"
      display={['block', 'none', 'none']}
      onClick={() => {
        setShowMobileMenu((prev) => !prev);
      }}
    >
      <Image src={dropdown} marginLeft="22px" />
    </Box>
  );

  const mobileAnimatedMenu = (
    <Box
      backgroundColor={colors.white}
      height={390}
      padding={'20px 38px'}
      sx={{
        overflow: 'hidden',
        backgroundColor: '#333',
        position: 'fixed;',
        bottom: '0',
        width: '100%',
        zIndex: '100',
        borderRadius: '16px',
        borderTop: '1px solid',
        borderColor: colors.grey,
      }}
    >
      <Flex
        sx={{
          borderBottom: '1px solid',
          borderColor: colors.grey,
          paddingBottom: '14px',
          img: { alignSelf: 'center' },
        }}
      >
        <Box marginLeft={12}>
          <Text>{group.school.name}</Text>
          <Text color={colors.brightGreen} weight="400" size="16px">{`${name} ${surname}`}</Text>
        </Box>
        <Box
          margin="0 0 0 auto"
          sx={{
            span: { display: 'flex', alignItems: 'center', svg: { width: '30px', height: '30px' } },
          }}
          onClick={() => {
            setShowMobileMenu(false);
          }}
        >
          <CloseCircleOutlined
            width={30}
            height={50}
            style={{
              width: '30px',
              height: '50px',
            }}
          />
        </Box>
      </Flex>
      <Box
        backgroundColor={colors.lightGrey}
        width="100%"
        height="38px"
        sx={{
          borderRadius: '10px',
          marginTop: '34px',
          div: {
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '9px 5px 10px 5px',
          },
        }}
      >
        <Box sx={{ cursor: 'pointer' }}>
          <Dropdown menu={menuProps} trigger={['click']}>
            <Flex>
              <Text textTransform="uppercase" weight="700" size="14px">
                {language}
              </Text>
              <Image src={dropdown} marginLeft="22px" />
            </Flex>
          </Dropdown>
        </Box>
      </Box>
      <CalendarModal />
      <FaqModal />
      {!isPaymentEnabled && (
        <DefaultSelectionsModal
          isVisible={defaultSelectionsModalVisible}
          onShowModal={(isVisible) => {
            setDefaultSelectionsModalVisible(isVisible);
          }}
        />
      )}
      <Box
        backgroundColor={colors.lightGrey}
        width="100%"
        sx={{
          borderRadius: '10px',
          marginTop: '10px',
          div: {
            padding: '9px 0 9px 15px',
          },
        }}
        onClick={onLogoutClick}
      >
        <Text color={colors.red}>{intl.formatMessage({ id: 'logout' })}</Text>
      </Box>
    </Box>
  );

  return (
    <>
      <Flex width={'100%'} sx={{ borderBottom: '1px solid', borderColor: colors.grey92 }}>
        <Flex width={'80%'} margin="0 auto">
          <Flex width="100%" height="80px" alignItems="center">
            <Image
              src={logo}
              width={'120px'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push('/');
              }}
            />
            <Box marginLeft="40px" display={['none', 'block', 'block']}>
              <Text
                color={colors.brightGreen}
                weight="400"
                size="16px"
              >{`${name} ${surname}`}</Text>
            </Box>
            {desktopMenu}
            {mobileMenu}
          </Flex>
        </Flex>
      </Flex>
      {showMobileMenu && mobileAnimatedMenu}
    </>
  );
};

export default NavBar;
