import React from 'react';
import { Card } from 'antd';
import { Box, Heading } from 'rebass';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { ReactComponent as Logo } from '../../images/svg/logo.svg';
import { messages } from '../../translations/locales/default';
import colors from '../../Theme/Colors';

interface Props {
  title: string | React.ReactNode;
  children: JSX.Element;
}

const CardWithLogo = (props: Props) => {
  const { title, children } = props;
  const locale = 'lt';

  return (
    <IntlProvider locale={locale} messages={flatten(messages[locale])}>
      <Box
        height="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '100vh',
          '.ant-btn-primary': {
            backgroundColor: colors.brightGreen,
            borderColor: colors.alertGreen,
          },
        }}
      >
        <Heading width="100%" fontWeight="bold" color="white" fontSize={['20px', '20px', '30px']}>
          <Logo width={'200px'} />
        </Heading>
        <Card
          title={<h3>{title}</h3>}
          bordered={true}
          style={{
            maxWidth: '400px',
            width: '90%',
            margin: '30px',
            borderRadius: '10px',
            backgroundColor: colors.lightGrey,
            padding: '16px',
          }}
        >
          <Box>{children}</Box>
        </Card>
      </Box>
    </IntlProvider>
  );
};

export default CardWithLogo;
